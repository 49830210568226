import { FunctionsHttpError } from "@supabase/supabase-js";
import { plainToInstance } from "class-transformer";
import { supabase } from "configs/Supabase";
import { Community } from "data/models/community";
import { Member } from "data/models/member";

export const fetch = async (community: Community): Promise<Member[]> => {
  try {
    const { data, error } = await supabase
      .from("member")
      .select()
      .eq("community_id", community.id);

    if (error) {
      throw error;
    }

    return plainToInstance(Member, data as any[]);
  } catch (error) {
    return [];
  }
};

export const addMember = async (
  community: Community,
  name: string,
  image?: File,
  passcode?: string,
  likes?: string[]
) => {
  try {
    if (!name) return;

    const formData = new FormData();
    formData.append("community_id", community.id.toString());
    formData.append("profile_name", name);
    if (image) formData.append("profile_image", image);
    if (passcode) formData.append("passcode", passcode);
    if (likes) formData.append("likes", likes.join(","));

    const { error } = await supabase.functions.invoke("create-membership", {
      body: formData,
    });

    if (error instanceof FunctionsHttpError && error.context.status === 409) {
      return 409;
    }
  } catch (error) {
    throw error;
  }
};

export const removeMember = async (memberId: number) => {
  try {
    const { error } = await supabase.from("member").delete().eq("id", memberId);

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const updateMember = async (
  community: Community,
  name?: string,
  image?: File
) => {
  try {
    if (!community.memberId) return;
    if (!name && !image) return;

    const formData = new FormData();
    formData.append("community_id", community.id.toString());
    if (name) formData.append("profile_name", name);
    if (image) formData.append("profile_image", image);

    const { error } = await supabase.functions.invoke("update-membership", {
      body: formData,
    });

    if (error instanceof FunctionsHttpError) {
      return error.context.status;
    }
  } catch (error) {
    throw error;
  }
};
