import React from "react";
import { useNavigate } from "react-router-dom";

import { Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/virtual";

import { Card, CardMedia, Stack, Typography } from "@mui/material";

import CommunityCardActions from "./CommunityCardActions";
import { getCommunityBusinessUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const CommunityMapCard = ({
  community,
  business,
  index,
}: {
  community: Community;
  business: Business;
  index: number;
}) => {
  const navigate = useNavigate();
  const communityId = community.urlName;

  const handleClick = (evt: React.MouseEvent) => {
    customEvent("com_dp_map_list_item", { communityId, index });
    navigate(getCommunityBusinessUrl(communityId, business.id), {
      state: { from: window.location.pathname },
    });
  };

  return (
    <Card elevation={6} onClick={handleClick} className="my-6">
      <CardMedia
        component="img"
        image={business.image}
        alt={business.name}
        className="object-cover aspect-16/9"
      />
      <Stack className="p-4">
        <Typography variant="h6" className="line-clamp-1">
          {business.name}
        </Typography>
        <Typography variant="body3" color="lightGreen.main" className="mb-4">
          {business.city}
          {business.price && <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</>}
          {business.price && business.price}
        </Typography>
        <CommunityCardActions
          community={community}
          business={business}
          index={index}
        />
      </Stack>
    </Card>
  );
};

const CommunityMapCards = ({
  community,
  businesses,
  activeIndex,
  setActiveIndex,
}: {
  community: Community;
  businesses: Business[];
  activeIndex?: number;
  setActiveIndex: (index: number) => void;
}) => {
  const [swiper, setSwiper] = React.useState<any>();
  const container = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!container.current) return;
    const containerRef = container.current;
    const handleTouchMove = (e: TouchEvent) => e.preventDefault();
    containerRef.addEventListener("touchmove", handleTouchMove, {
      passive: false,
    });
    return () => containerRef.removeEventListener("touchmove", handleTouchMove);
  });

  React.useEffect(() => {
    if (swiper) swiper.slideTo(activeIndex, 0);
  }, [activeIndex, swiper]);

  if (activeIndex === undefined) return null;

  return (
    <div ref={container} className="pointer-events-auto max-w-[650px]">
      <Swiper
        modules={[Virtual]}
        slidesPerView={1.2}
        centeredSlides={true}
        spaceBetween={20}
        onSlideChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        onSwiper={(swiper) => setSwiper(swiper)}
        virtual={{ addSlidesAfter: 5, addSlidesBefore: 5 }}
      >
        {businesses.map((b, i) => (
          <SwiperSlide key={b.id} virtualIndex={i}>
            <CommunityMapCard community={community} business={b} index={i} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CommunityMapCards;
