import { Community } from "data/models/community";
import CommunityFiltersMultiSelect from "./CommunityFiltersMultiSelect";
import CommunityFiltersSingleSelect from "./CommunityFiltersSingleSelect";
import CommunityFiltersToggle from "./CommunityFiltersToggle";
import { useBusinesses } from "data/hooks/businesses";

const CommunityFiltersCustom = ({
  community,
  tag,
  index,
}: {
  community: Community;
  tag: string;
  index: number;
}) => {
  const [type, filter] = tag.split("::");

  const businesses = useBusinesses(community);

  if (!filter || !type) return <></>;

  let options: [string, number][] = [];
  if (type === "single" || type === "multi") {
    options = Object.entries(
      businesses.reduce((acc, business) => {
        if (!business.tags) return acc;

        const targets = business.tags.filter((t) => t.startsWith(`${tag}::`));
        targets.forEach((target) => {
          const value = target.split("::")[2];
          if (!value) return;
          else if (value in acc) acc[value] += 1;
          else acc[value] = 1;
        });

        return acc;
      }, {} as { [option: string]: number })
    ).sort((a, b) => b[1] - a[1]);
  }

  const tagName = tag.toLowerCase().replace(" ", "");
  if (type === "toggle") {
    return (
      <CommunityFiltersToggle
        community={community}
        filter={filter}
        label={filter}
        eventName={`com_dp_filter_com_toggle`}
        eventParams={{ tag: tagName }}
      />
    );
  } else if (type === "single") {
    return (
      <CommunityFiltersSingleSelect
        community={community}
        filter={filter}
        label={filter}
        menuItems={options.map(([option, count]) => ({
          label: option,
          sublabel: count.toString(),
        }))}
        eventName={`com_dp_filter_com_single_tagname`}
        eventParams={{ tag: tagName }}
      />
    );
  } else if (type === "multi") {
    return (
      <CommunityFiltersMultiSelect
        community={community}
        filter={filter}
        label={filter}
        menuItems={options.map(([option, count]) => ({
          label: option,
          sublabel: count.toString(),
        }))}
        eventName={`com_dp_filter_com_multi_tagname`}
        eventParams={{ tag: tagName }}
      />
    );
  }

  return <></>;
};

export default CommunityFiltersCustom;
