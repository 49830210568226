import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { emailRecovery } from "services/SupabaseAuth";

const EmailRecoverySchema = Yup.object().shape({
  email: Yup.string().email().required(),
});

const EmailRecovery = ({
  handleRecoveryConfirm,
  setEmail,
}: {
  handleRecoveryConfirm: () => void;
  setEmail: (email: string) => void;
}) => {
  const initialValues = { email: "" };

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values: EmailRecoveryForm) => {
    setLoading(true);
    try {
      await emailRecovery(values.email);
    } catch (e) {
      console.log(e);
    }
    setEmail(values.email);
    handleRecoveryConfirm();
    setLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={EmailRecoverySchema}
      validateOnMount
      validateOnChange
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, isValid }) => (
        <Form>
          <Stack spacing={4}>
            <Typography variant="h4">Forgot password</Typography>
            <Typography variant="body1">
              Please enter the email address associated with your account.
              Should the email be registered within our records, you’ll receive
              an email containing instructions to reset your password.
            </Typography>
            <div>
              <TextField
                fullWidth
                type="email"
                id="email"
                value={values.email}
                onChange={handleChange}
                variant="outlined"
                color="darkGrey"
                placeholder="Enter your email"
                className="mt-1"
                InputProps={{ className: "rounded-xl" }}
              />
            </div>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
              loadingPosition="start"
              startIcon={<></>}
              disabled={!isValid}
              className="rounded-[20px] mb-2"
            >
              <Typography variant="body1ExtraBold">Submit</Typography>
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default EmailRecovery;
