import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Modal, Stack, Typography } from "@mui/material";

import LoginBottomSheet from "components/LoginBottomSheet";
import { getCommunityJoinUrl } from "configs/Paths";
import { useData } from "providers/AuthProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityJoinModal = ({
  community,
  modalOpen,
  setModalOpen,
}: {
  community: Community;
  modalOpen: boolean;
  setModalOpen: (open: boolean) => void;
}) => {
  const navigate = useNavigate();
  const { user } = useData();

  const [bottomSheetOpen, setBottomSheetOpen] = React.useState(false);

  const communityId = community.id;

  React.useEffect(() => {
    if (modalOpen) customEvent("join_com_m_page_view", { communityId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen]);

  const handleJoin = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    customEvent("join_com_m_join", { communityId });
    if (user != null) {
      navigate(getCommunityJoinUrl(community.urlName));
    } else {
      setModalOpen(false);
      setBottomSheetOpen(true);
    }
  };

  const handleClose = (evt: React.MouseEvent) => {
    evt.stopPropagation();
    customEvent("join_com_m_close", { communityId });
    setModalOpen(false);
  };

  return (
    <>
      <Modal
        open={modalOpen}
        onClose={handleClose}
        className="flex items-center justify-center"
      >
        <Stack spacing={1} className=" w-10/12 p-6 bg-white rounded-xl">
          <Typography variant="h5" className="pb-4">
            You need to join this community in order to recommend places or
            leave reviews.
          </Typography>
          <Button
            fullWidth
            disableFocusRipple
            variant="contained"
            onClick={handleJoin}
          >
            <Typography variant="body1ExtraBold">Join Community</Typography>
          </Button>
          <Button variant="text" color="black" onClick={handleClose}>
            <Typography variant="body1ExtraBold">Cancel</Typography>
          </Button>
        </Stack>
      </Modal>
      <LoginBottomSheet
        open={bottomSheetOpen}
        setOpen={setBottomSheetOpen}
        label="To join this community, sign up or log In."
      />
    </>
  );
};

export default CommunityJoinModal;
