import React from "react";
import { useNavigate } from "react-router-dom";

import { Backdrop, IconButton, Stack, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";

import { getCommunityBusinessUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";
import { useCommunity } from "data/hooks/communities";
import { useBusiness } from "data/hooks/business";

const BusinessReviewConfirmation = ({
  communityId,
  businessId,
}: {
  communityId: string;
  businessId: string;
}) => {
  const navigate = useNavigate();

  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  const business = useBusiness(community, businessId);
  if (!business) throw new Error("Business not found");

  React.useEffect(() => {
    customEvent("review_success_page_view", {
      communityId: communityId,
      businessId: businessId,
    });
    const timer = setTimeout(handleClose, 3000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    navigate(getCommunityBusinessUrl(communityId, businessId));
  };

  return (
    <Backdrop
      open={true}
      onClick={handleClose}
      transitionDuration={0}
      sx={{ backgroundColor: "rgba(255, 255, 255, 0.9)", zIndex: 10000, p: 4 }}
    >
      <Stack spacing={2} className="items-center text-center w-full">
        <div className="pb-8">
          <img
            src={business.image}
            alt={business.name}
            className="w-48 h-48 rounded-xl object-cover"
          />
        </div>
        <Typography variant="h5">Thanks for your review!</Typography>
        <Typography variant="body1">Your review has been posted :)</Typography>
      </Stack>
      <IconButton sx={{ position: "absolute", top: "15px", left: "16px" }}>
        <Clear sx={{ color: "black.main" }} />
      </IconButton>
    </Backdrop>
  );
};

export default BusinessReviewConfirmation;
