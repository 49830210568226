import React from "react";

import { Drawer } from "@mui/material";

import CommunityHeaderOverflowInfo from "./CommunityHeaderOverflowInfo";
import CommunityHeaderOverflowLeave from "./CommunityHeaderOverflowLeave";
import CommunityHeaderOverflowMain from "./CommunityHeaderOverflowMain";
import CommunityHeaderOverflowMember from "./CommunityHeaderOverflowMember";
import { CommunityOverflowMemberLoading } from "./loading";
import { Community } from "data/models/community";

const CommunityHeaderOverflow = ({
  community,
  step,
  setStep,
}: {
  community: Community;
  step: DetailOverflowMenu;
  setStep: (step?: DetailOverflowMenu) => void;
}) => {
  const handleClose = () => {
    setStep();
  };

  const content = {
    main: (
      <CommunityHeaderOverflowMain
        communityId={community.urlName}
        setStep={setStep}
      />
    ),
    members: (
      <React.Suspense fallback={<CommunityOverflowMemberLoading />}>
        <CommunityHeaderOverflowMember
          community={community}
          close={handleClose}
        />
      </React.Suspense>
    ),
    info: <CommunityHeaderOverflowInfo community={community} />,
    leave: <></>,
  };

  if (step === "leave") {
    return (
      <CommunityHeaderOverflowLeave community={community} setStep={setStep} />
    );
  }

  return (
    <Drawer
      anchor="bottom"
      open={!!step}
      onClose={handleClose}
      PaperProps={{
        className: "rounded-t",
      }}
      className="z-[2000]"
    >
      <div className="max-w-[650px] w-full mx-auto">
        {step && content[step]}
      </div>
    </Drawer>
  );
};

export default CommunityHeaderOverflow;
