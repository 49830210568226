import React from "react";
import { useLocation } from "react-router-dom";

import Typography from "@mui/material/Typography";

import OlivAppBar from "components/OlivAppBar";
import CommunityCards from "./CommunityCards";
import CommunityRequest from "./CommunityRequest";
import { DiscoverLoading } from "./loading";
import { customEvent } from "utils/AnalyticsEvent";

const CommunitySearch = () => {
  const location = useLocation();

  React.useEffect(() => {
    customEvent("join_com_page_view", {});
  }, []);

  return (
    <>
      <OlivAppBar showLogin={location.state?.needsLogin} />
      <div className="px-4 py-6">
        <Typography variant="h5" className="pb-4">
          Discover communities
        </Typography>
        <React.Suspense fallback={<DiscoverLoading />}>
          <CommunityCards />
        </React.Suspense>
        <CommunityRequest />
      </div>
    </>
  );
};

export default CommunitySearch;
