import React from "react";

import { Snackbar, Stack, Typography } from "@mui/material";
import { EmailRounded } from "@mui/icons-material";

import { emailRecovery } from "services/SupabaseAuth";
import { customEvent } from "utils/AnalyticsEvent";

const EmailRecoveryConfirm = ({ email }: { email: string }) => {
  const [open, setOpen] = React.useState(false);

  const handleResend = async () => {
    customEvent("oliv_auth_email_login_link_forgot_resend", {});
    try {
      setOpen(true);
      await emailRecovery(email);
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Stack spacing={1} className="text-center">
        <Typography color="lightGreen.main">
          <EmailRounded color="inherit" sx={{ fontSize: 70 }} />
        </Typography>
        <Typography variant="h4">
          A password reset link has been sent to your email.
        </Typography>
        <Typography variant="body1" className="pt-4 pb-20">
          Account activation link has been sent to the email address you
          provided
        </Typography>
        <Typography
          variant="body3"
          color="darkGreen.main"
          onClick={handleResend}
        >
          Didn’t get the mail? <b className="text-primary">Send it again</b> or
          contact support@oliv.org for help
        </Typography>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        open={open}
        onClose={() => setOpen(false)}
        message="Verification email sent again"
        className="w-fit mx-auto"
      />
    </>
  );
};

export default EmailRecoveryConfirm;
