import React from "react";
import { Formik, Form } from "formik";

import { Stack, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import BusinessHeader from "./BusinessHeader";
import CommentTextbox from "./CommentTextbox";
import MediaUploader from "./MediaUploader";
import { useAddReview } from "data/hooks/businessReviews";
import { customEvent } from "utils/AnalyticsEvent";
import { CommentEditorState } from ".";
import { useBusiness } from "data/hooks/business";
import { useCommunity } from "data/hooks/communities";

const BusinessReviewForm = ({
  communityId,
  businessId,
  state,
  setState,
}: {
  communityId: string;
  businessId: string;
  state: CommentEditorState;
  setState: (state: CommentEditorState) => void;
}) => {
  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  const business = useBusiness(community, businessId);
  if (!business) throw new Error("Business not found");

  const addReview = useAddReview(community, business);

  const handleSubmit = async (values: BusinessCommentForm) => {
    customEvent("review_button_cta", { communityId, businessId });
    setState("submitting");

    addReview.mutate({
      comment: values.comment,
      images: values.images,
    });

    setState("complete");
  };

  const checkFormValid = (values: BusinessCommentForm) => {
    if (values.comment) return true;
    if (values.images?.length) return true;
    return false;
  };

  if (!communityId || !businessId) return <></>;

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      {({ values }) => (
        <Form>
          <Stack spacing={3} sx={{ p: 2 }}>
            <BusinessHeader communityId={communityId} businessId={businessId} />
            <CommentTextbox />
            <MediaUploader communityId={communityId} businessId={businessId} />
            <LoadingButton
              fullWidth
              loading={state === "submitting"}
              loadingPosition="start"
              startIcon={<></>}
              variant="contained"
              type="submit"
              disabled={!checkFormValid(values)}
            >
              <Typography variant="body1ExtraBold">Post your review</Typography>
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default BusinessReviewForm;
