import * as ReviewReactionsApi from "data/apis/reviewReactions";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Review } from "data/models/review";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

export const useReviewReactions = (review: Review) => {
  const { data } = useQuery({
    queryKey: ["reviewReactions", review.id],
    queryFn: async () => await ReviewReactionsApi.fetch(review),
  });

  return data || [];
};

export const useHelpfulReview = (
  community: Community,
  business: Business,
  review: Review
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await ReviewReactionsApi.addHelpful(community, review),
    onMutate: async () => {
      await updateHelpful(queryClient, community, business, review, true);
    },
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: ["reviewReactions", review.id],
      });
    },
  });
};

export const useUnhelpfulReview = (
  community: Community,
  business: Business,
  review: Review
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await ReviewReactionsApi.removeHelpful(community, review),
    onMutate: async () => {
      await updateHelpful(queryClient, community, business, review, false);
    },
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: ["reviewReactions", review.id],
      });
    },
  });
};

const updateHelpful = async (
  queryClient: QueryClient,
  community: Community,
  business: Business,
  review: Review,
  isHelpful: boolean
) => {
  const reviewsQueryKey = ["reviews", community.id, business.id];
  await queryClient.cancelQueries(reviewsQueryKey);
  queryClient.setQueryData(reviewsQueryKey, (old?: Review[]) => {
    if (!old) return [];
    return old.map((r) => {
      if (r.id === review.id) {
        r.helpful = isHelpful;
        r.helpfulCount += isHelpful ? 1 : -1;
      }
      return r;
    });
  });
};
