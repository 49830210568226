import React from "react";
import { motion } from "framer-motion";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import PageWrapper from "./PageWrapper";
import { customEvent } from "utils/AnalyticsEvent";
import { User } from "@supabase/supabase-js";
import { supabase } from "configs/Supabase";

const duration = 0.5;

const OnboardingPage = ({
  user,
  handleComplete,
}: {
  user: User;
  handleComplete: () => void;
}) => {
  React.useEffect(() => {
    customEvent("onboarding_page_view", {});
  }, []);

  const handleClick = async () => {
    handleComplete();
    customEvent("onboarding_button_cta", {});
    await supabase.auth.updateUser({
      data: { onboarding_complete: true },
    });
  };

  const onboardingSteps = [
    {
      title: "Join community",
      description: "Connect with people who share your interests and tastes.",
    },
    {
      title: "Share",
      description:
        "Share your favorite restaurant, business, or service with community members.",
    },
    {
      title: "Discover",
      description:
        "See what other places your community members love and discover your new favorite spots!",
    },
  ];

  return (
    <PageWrapper>
      <Stack spacing={3} className="w-full px-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ ease: "easeIn" }}
        >
          <Typography
            variant="h2"
            color="white.main"
            className="text-shadow-sm mb-8"
          >
            Welcome to Oliv!
            <br />
            Let’s get started:
          </Typography>
        </motion.div>
        {onboardingSteps.map((step, index) => (
          <OnboardingStep
            key={index}
            title={step.title}
            description={step.description}
            index={index + 1}
          />
        ))}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 2.2, duration, ease: "easeIn" }}
        >
          <Button
            fullWidth
            variant="contained"
            color="white"
            onClick={handleClick}
            className="mt-8 h-12"
          >
            <Typography variant="body1ExtraBold" color="lightGreen.main">
              Let's get started
            </Typography>
          </Button>
        </motion.div>
      </Stack>
    </PageWrapper>
  );
};

interface IOnboardingStepProps {
  title: string;
  description: string;
  index: number;
}

const OnboardingStep = (props: IOnboardingStepProps) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.4 * (props.index + 1), duration, ease: "easeIn" }}
  >
    <Stack direction="row" spacing={2}>
      <Box className="flex items-center justify-center bg-white rounded-full h-9 aspect-square shadow-[0_4px_4px_rgba(0,0,0,0.2)]">
        <Typography variant="body1ExtraBold" color="lightGreen.main">
          {props.index}
        </Typography>
      </Box>
      <Box>
        <Typography
          variant="h5"
          color="white.main"
          className="text-shadow-sm mb-1"
        >
          {props.title}
        </Typography>
        <Typography
          variant="body2Bold"
          color="white.main"
          className="text-shadow-sm"
        >
          {props.description}
        </Typography>
      </Box>
    </Stack>
  </motion.div>
);

export default OnboardingPage;
