import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import RestartAltIcon from "@mui/icons-material/RestartAlt";

import CommunityListCard from "./CommunityListCard";
import Constants from "configs/Constants";
import { useCommunityState } from "providers/CommunityStateProvider";
import { useBusinesses } from "data/hooks/businesses";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const CommunityEmptyListView = () => {
  return (
    <div className="flex items-center justify-center p-4 text-center">
      <Typography variant="body2Medium" color="darkGrey.main">
        No places recommended for this community yet
      </Typography>
    </div>
  );
};

const CommunityEmptyResultView = () => {
  const { setFilters } = useCommunityState();

  const resetFilter = () => setFilters({ toggle: {}, single: {}, multi: {} });

  return (
    <Stack spacing={3} className="flex items-center pt-10 px-4">
      <Typography variant="body2Bold">
        No places match the filters you selected
      </Typography>
      <Button
        variant="contained"
        color="buttonGrey"
        size="small"
        startIcon={<RestartAltIcon />}
        onClick={resetFilter}
      >
        <Typography variant="body2Bold">Clear filters</Typography>
      </Button>
    </Stack>
  );
};

const CommunityListView = ({
  community,
  businesses,
}: {
  community: Community;
  businesses: Business[];
}) => {
  const sotRecommendations = useBusinesses(community);

  const [page, setPage] = React.useState(1);

  if (sotRecommendations.length === 0) return <CommunityEmptyListView />;
  if (businesses.length === 0) return <CommunityEmptyResultView />;

  const pagingatedItems = businesses.slice(0, page * Constants.PAGE_SIZE);

  return (
    <InfiniteScroll
      dataLength={pagingatedItems.length}
      next={() => setPage(page + 1)}
      hasMore={true}
      loader={<></>}
      className="p-4"
    >
      {pagingatedItems.map((business, i: number) => (
        <div key={business.id} className="mb-12">
          <CommunityListCard
            community={community}
            business={business}
            index={i}
          />
        </div>
      ))}
    </InfiniteScroll>
  );
};

export default CommunityListView;
