import { plainToInstance } from "class-transformer";
import { supabase } from "configs/Supabase";
import { Business } from "data/models/business";
import { Community } from "data/models/community";
import { Review } from "data/models/review";

export const fetch = async (
  community: Community,
  business: Business
): Promise<Review[]> => {
  try {
    const params = { community_id: community.id, business_id: business.id };
    const { data, error } = await supabase.rpc("list_reviews", params);

    if (error) {
      throw error;
    }

    return plainToInstance(Review, data as any[]);
  } catch (error) {
    return [];
  }
};

export const addReview = async (
  community: Community,
  business: Business,
  images: File[],
  comment?: string
) => {
  try {
    if (!community.memberId) return;

    const formData = new FormData();
    formData.append("community_id", community.id.toString());
    formData.append("business_id", business.id);
    formData.append("comment", comment || "");
    (images || []).forEach((image) => formData.append("images", image));

    const { error } = await supabase.functions.invoke("create-review", {
      body: formData,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const removeReview = async (review: Review) => {
  try {
    const { error } = await supabase.from("review").delete().match({
      id: review.id,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};
