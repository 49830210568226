import { plainToInstance } from "class-transformer";
import { supabase } from "configs/Supabase";
import { Community } from "data/models/community";
import { Feed } from "data/models/feed";

export const fetch = async (community: Community): Promise<Feed[]> => {
  try {
    const params = { community_id: community.id, start_index: 0 };
    const { data, error } = await supabase.rpc("list_community_feed", params);

    if (error) {
      throw error;
    }

    return plainToInstance(Feed, data as any[]);
  } catch (error) {
    return [];
  }
};
