import React from "react";
import { useParams } from "react-router-dom";

import { Dialog } from "@mui/material";

import ProfileEditor from "./ProfileEditor";
import OlivAppBar from "components/OlivAppBar";
import YourActivities from "./YourActivities";
import { ProfileEditorLoading } from "./loading";
import { customEvent } from "utils/AnalyticsEvent";

const CommunityProfile = () => {
  const { communityId } = useParams();

  React.useEffect(() => {
    customEvent("profile_page_view", { communityId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!communityId) throw new Error("Community not found");

  return (
    <Dialog open={true} fullScreen>
      <OlivAppBar
        showClose="profile_close"
        hideProfile
        communityId={communityId}
      />
      <React.Suspense fallback={<ProfileEditorLoading />}>
        <ProfileEditor communityId={communityId} />
      </React.Suspense>
      <YourActivities communityId={communityId} />
    </Dialog>
  );
};

export default CommunityProfile;
