import { Expose, Type } from "class-transformer";
import { Member } from "./member";

export class ReviewReaction {
  id!: number;
  @Expose({ name: "created_at" }) @Type(() => Date) createdAt!: Date;
  @Expose({ name: "profile_name" }) profileName!: string;
  @Expose({ name: "profile_color" }) profileColor!: string;
  @Expose({ name: "is_moderator" }) isModerator!: boolean;
  badges!: string[];
  @Expose({ name: "profile_img_key" }) profileImgKey?: string;

  get member(): Member {
    return new Member(
      this.profileName!,
      this.profileColor!,
      false,
      [],
      this.profileImgKey
    );
  }
}
