import React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ReportGmailerrorredRoundedIcon from "@mui/icons-material/ReportGmailerrorredRounded";

import OlivAppBar from "components/OlivAppBar";
import { Paths } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";

const NotFound = () => {
  const navigate = useNavigate();

  React.useEffect(() => {
    customEvent("error_404_page_view", {});
  }, []);

  const handleClick = () => {
    customEvent("error_404_button_home", {});
    navigate(Paths.main);
  };

  return (
    <React.Fragment>
      <OlivAppBar />
      <Stack
        spacing={3}
        sx={{
          height: "80vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          px: "30px",
        }}
      >
        <ReportGmailerrorredRoundedIcon
          sx={{ fontSize: "72px", color: "lightGreen.main" }}
        />
        <Stack spacing={2} sx={{ textAlign: "center" }}>
          <Typography variant="h3">Page not found</Typography>
          <Typography variant="body2">
            Sorry, the page you are looking for doesn't exist of has been moved.
          </Typography>
        </Stack>
        <Button
          variant="contained"
          size="large"
          onClick={handleClick}
          sx={{ px: "60px" }}
        >
          <Typography variant="body1ExtraBold">Home</Typography>
        </Button>
      </Stack>
    </React.Fragment>
  );
};

export default NotFound;
