import DefaultRestaurant from "assets/defaultRestaurant.png";
import { Exclude, Expose, Type } from "class-transformer";
import { ImageType, OlivImage } from "./olivImage";

export class Business {
  id!: string;
  name!: string;
  address!: string;
  city!: string;
  category!: string;
  @Expose({ name: "closed_temporarily" }) closedTemporarily!: boolean;
  @Expose({ name: "closed_permanently" }) closedPermanently!: boolean;
  @Type(() => OlivImage) images: OlivImage[] = [];
  @Expose({ name: "created_at" }) createdAt!: Date;
  @Expose({ name: "recommendations_count" }) recommendationsCount!: number;
  @Expose({ name: "reviews_count" }) reviewsCount!: number;
  tags: string[] = [];
  recommended!: boolean;
  reviewed!: boolean;
  bookmarked!: boolean;
  @Expose({ name: "is_open" }) isOpen!: boolean;
  latitude?: number;
  longitude?: number;
  phone?: string;
  @Expose({ name: "google_id" }) googleId?: string;
  cuisine?: string;
  price?: string;
  website?: string;
  @Type(() => Date) verified?: Date;
  @Exclude() distance?: number;

  get image() {
    if (this.images.length > 0) {
      return this.images[0].url!;
    }
    return DefaultRestaurant;
  }

  get sliderImages(): OlivImage[] {
    if (!this.images || this.images.length === 0) {
      return [new OlivImage(ImageType.fallback, DefaultRestaurant, undefined)];
    }
    return this.images;
  }

  get formattedWebsite() {
    if (this.website && this.website.startsWith("http")) return this.website;
    return `https://${this.website}`;
  }

  get addressWithoutZipcode() {
    return this.address.replaceAll(RegExp(/\d{5}$/), "");
  }

  get latlng() {
    return this.latitude && this.longitude
      ? { lat: this.latitude, lng: this.longitude }
      : undefined;
  }
}
