import React from "react";

import {
  Button,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";

const BottomSheetMultiSelect = (props: IBottomSheetProps) => {
  const { label, sublabel, menuItems, close, handleApply } = props;

  const [checked, setChecked] = React.useState(
    menuItems?.filter((item) => item.active).map((item) => item.label) || []
  );

  if (!label || !menuItems) return null;

  return (
    <Stack spacing={0} sx={{ p: 2 }} className="w-full">
      {label && (
        <div className="flex justify-between items-center">
          <Typography variant="h5" sx={{ py: 1.5 }}>
            Filter by {label}
          </Typography>
          <IconButton onClick={close}>
            <CloseOutlined />
          </IconButton>
        </div>
      )}
      <FormGroup
        sx={{ maxHeight: "70dvh", flexWrap: "inherit", overflowY: "scroll" }}
      >
        <FormControlLabel
          key="all"
          control={<Checkbox sx={{ width: 45 }} />}
          checked={checked.length === 0}
          label={
            <React.Fragment>
              <Typography
                variant="body1"
                component="span"
                sx={{ pr: "12px" }}
              >{`Any ${label.toLowerCase()}`}</Typography>
              {sublabel && (
                <Typography
                  variant="body1"
                  component="span"
                  color="lightGreen.main"
                  fontWeight={200}
                >
                  {sublabel}
                </Typography>
              )}
            </React.Fragment>
          }
          labelPlacement="start"
          onChange={(evt: React.SyntheticEvent) => {
            const target = evt.target as HTMLInputElement;
            if (target.checked) {
              setChecked([]);
            }
          }}
          sx={{ justifyContent: "space-between", mx: 0, py: 1 }}
        />
        {menuItems.map((item, i) => (
          <FormControlLabel
            key={i}
            control={<Checkbox sx={{ width: 45 }} />}
            label={
              <React.Fragment>
                <Typography variant="body1" component="span" sx={{ pr: 2 }}>
                  {item.label}
                </Typography>
                {item.sublabel && (
                  <Typography
                    variant="body1"
                    component="span"
                    color="lightGreen.main"
                  >
                    {item.sublabel}
                  </Typography>
                )}
              </React.Fragment>
            }
            labelPlacement="start"
            checked={checked.includes(item.label)}
            onChange={(evt: React.SyntheticEvent) => {
              const target = evt.target as HTMLInputElement;
              if (target.checked) {
                setChecked([...checked, item.label]);
              } else {
                setChecked(checked.filter((label) => label !== item.label));
              }
            }}
            sx={{ justifyContent: "space-between", mx: 0, py: 1 }}
          />
        ))}
      </FormGroup>
      <Button
        variant="contained"
        onClick={() => {
          handleApply && handleApply(checked);
          close();
        }}
        sx={{ mt: 3, mb: 2 }}
      >
        <Typography variant="body1ExtraBold">Apply</Typography>
      </Button>
    </Stack>
  );
};

export default BottomSheetMultiSelect;
