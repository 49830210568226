import React from "react";

import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";

import { customEvent } from "utils/AnalyticsEvent";
import { OlivImage } from "data/models/olivImage";
import { Community } from "data/models/community";

const CommentFeedReviewImages = ({
  community,
  images,
}: {
  community: Community;
  images: OlivImage[];
}) => {
  const communityId = community.urlName;

  const handleImageChange = (swipeIndex: number) => {
    customEvent("com_dp_feed_item_image_swipe", { communityId, swipeIndex });
  };

  return (
    <Swiper
      pagination={{ dynamicBullets: true }}
      modules={[Pagination, Virtual]}
      className="relative w-full aspect-16/9 rounded-lg"
      onSlideChange={(swiper) => handleImageChange(swiper.activeIndex)}
      virtual={{ addSlidesAfter: 1, addSlidesBefore: 1 }}
    >
      {images.map((i: OlivImage, index: number) => (
        <SwiperSlide key={i.imageUrl} virtualIndex={index}>
          <img
            src={i.imageUrl}
            alt={i.imageUrl}
            className="object-cover w-full aspect-16/9"
          />
          <div className="absolute bottom-0 w-full h-14 bg-gradient-to-t from-black/40" />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CommentFeedReviewImages;
