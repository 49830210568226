const Constants = {
  ABOUT_DECK:
    "https://docs.google.com/presentation/d/e/2PACX-1vQNfz2lnkB14tY3G2JIQePB1jpsBlA6qIhpqDNEO8-1dVk-OWCnK1IIGDr-FazjWg-QVPIQF1PREt2w/pub?start=false&loop=false&delayms=3000&slide=id.p",
  BUSINESS_EDIT_FORM:
    "https://docs.google.com/forms/d/e/1FAIpQLScAGXd0Fkxa5Px6GnovHGG7bBGJIK8jFJfnQfuS2--AD5PLsQ/viewform?usp=sf_link",
  FEEDBACK_FORM:
    "https://docs.google.com/forms/d/e/1FAIpQLScWfDpCiZTMgKzKQdDpG6xkegJR7SNsnPhyUpl13a2YW9IwcQ/viewform?usp=sf_link",
  GOOGLE_LOGO: "https://img.icons8.com/color/24/000000/google-logo.png",
  GOOGLE_INAPP_POLICY:
    "https://developers.googleblog.com/2016/08/modernizing-oauth-interactions-in-native-apps.html?sjid=7115376732154810501-NAME",
  LOCATION_SERVICE_URL: "https://ipapi.co/json/",
  PAGE_SIZE: 20,
  REQUEST_COMMUNITY_FORM:
    "https://docs.google.com/forms/d/e/1FAIpQLSdbwiJBTsxGFZ_o5k01vZceJVV-dU07BKYksth0aRzulEiyaQ/viewform?usp=sf_link",
  MISSING_BUSINESS_FORM:
    "https://docs.google.com/forms/d/e/1FAIpQLSd72ChPF0NET9UPRPeuc1imYbICK_tIUw56_U72o3eFp0xHFg/viewform?usp=sf_link",
  PROFILE_BLACKLISTED_CHARS: /[()[{\]}]/,
};

export default Constants;
