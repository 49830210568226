import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import OlivAppBar from "components/OlivAppBar";

const PrivacyPolicy = ({ hideCta = false }) => {
  return (
    <>
      <OlivAppBar showClose="pp_close" hideCta={hideCta} />
      <Stack spacing={2} className="p-4">
        <Typography variant="h3">Oliv Privacy Policy</Typography>
        <Typography variant="body2Bold">
          Last Updated: February 13, 2023
        </Typography>
        <Typography variant="body3">
          Oliv (the "Service") takes the privacy of its users very seriously.
          This Privacy Policy (the "Policy") sets forth the information
          practices for the Service, including what type of information is
          collected, how the information is used, and with whom the information
          may be shared.
        </Typography>
        <Typography variant="h6">1. Information Collection and Use</Typography>
        <Typography variant="body3">
          The Service collects personal information from users in several ways,
          including through the registration process and through the submission
          of User Content. The personal information collected may include, but
          is not limited to, your name, email address, and any other information
          you choose to provide.
          <br />
          <br />
          We may use the personal information we collect to operate and improve
          the Service, to send you promotional materials, and to respond to your
          questions and concerns. We may also use the personal information we
          collect to send you service-related announcements, such as updates to
          the Service and security alerts.
        </Typography>
        <Typography variant="h6">2. Information Sharing</Typography>
        <Typography variant="body3">
          We may share your personal information with third parties in the
          following circumstances: (a) with your consent; (b) to provide you
          with the Service or to respond to your requests; (c) to comply with
          any court order or other legal obligation; (d) to enforce or apply our
          Terms of Use; (e) to protect the rights, property, or safety of Oliv,
          its users, or others; or (f) in connection with a corporate
          transaction, such as a merger or sale of assets.
        </Typography>
        <Typography variant="h6">3. Data Security</Typography>
        <Typography variant="body3">
          We take reasonable steps to protect the personal information we
          collect from loss, misuse, and unauthorized access, disclosure,
          alteration, or destruction. However, no electronic data transmission
          or storage of information can be guaranteed to be 100% secure. Please
          note that we cannot ensure the security of any information you
          transmit to us, and you use the Service at your own risk.
        </Typography>
        <Typography variant="h6">4. Data Retention</Typography>
        <Typography variant="body3">
          We will retain your personal information for as long as necessary to
          provide you with the Service or as necessary to comply with our legal
          obligations, resolve disputes, and enforce our agreements.
        </Typography>
        <Typography variant="h6">5. Children's Privacy</Typography>
        <Typography variant="body3">
          The Service is not intended for children under the age of 18, and we
          do not knowingly collect personal information from children under the
          age of 18. If we become aware that we have collected personal
          information from a child under the age of 18, we will delete that
          information as soon as possible.
        </Typography>
        <Typography variant="h6">6. Changes to the Policy</Typography>
        <Typography variant="body3">
          We may modify this Policy at any time, and such modifications will be
          effective immediately upon posting of the modified Policy. You agree
          to review this Policy periodically to be aware of any modifications
          and your continued use of the Service signifies your acceptance of the
          modified Policy.
        </Typography>
        <Typography variant="h6">7. Contact Us</Typography>
        <Typography variant="body3">
          If you have any questions about this Policy, please contact us at
          paik.brothers@gmail.com.
        </Typography>
      </Stack>
    </>
  );
};

export default PrivacyPolicy;
