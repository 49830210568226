import React from 'react';

import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

const ModalBoxConfirmation = React.forwardRef((props: IModalBoxProps, ref) => {
  const { label, confirmLabel='Confirm' } = props;

  return (
    <Stack spacing={1} sx={{ width: '80%', p: '24px', backgroundColor: 'white.main', borderRadius: '12px' }}>
      <Typography variant='h5' sx={{ mb: '16px' }}>{label}</Typography>
      <Button variant='contained' sx={{ py: '13px '}} onClick={props.handleConfirm}>
        <Typography variant='body1ExtraBold'>{confirmLabel}</Typography>
      </Button>
      <Button variant='text' color='black' onClick={props.handleCancel}>
        <Typography variant='body1ExtraBold'>Cancel</Typography>
      </Button>
    </Stack>
  );
});

export default ModalBoxConfirmation;