import React from "react";
import { Form, Formik } from "formik";

import { Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Disclaimer from "./Disclaimer";
import { emailSignIn } from "services/SupabaseAuth";
import { customEvent } from "utils/AnalyticsEvent";

const EmailSignIn = ({
  handleClose,
  handleSignUp,
  handleConfirm,
  handleRecover,
  setEmail,
}: {
  handleClose: () => void;
  handleSignUp: () => void;
  handleConfirm: () => void;
  handleRecover: () => void;
  setEmail: (email: string) => void;
}) => {
  const initialValues = { email: "", password: "" };

  const [loading, setLoading] = React.useState(false);
  const [unauthorized, setUnauthorized] = React.useState(false);

  React.useEffect(() => {
    customEvent("oliv_auth_email_login_page_view", {});
  }, []);

  const handleSubmit = async (values: EmailSignInForm) => {
    customEvent("oliv_auth_email_login_button_cta", {});
    setLoading(true);
    try {
      await emailSignIn(values.email, values.password);
      handleClose();
    } catch (e: any) {
      setEmail(values.email);
      if (e.message === "Email not confirmed") {
        handleConfirm();
      } else if (e.message === "Invalid login credentials") {
        customEvent("oliv_auth_email_login_error_wrong", {});
        setUnauthorized(true);
      }
    }
    setLoading(false);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {({ values, handleChange }) => (
        <Form>
          <Stack spacing={2}>
            <Typography variant="h4" className="pb-3">
              Welcome back to Oliv!
            </Typography>
            <div>
              <Typography
                variant="body1ExtraBold"
                color={unauthorized ? "error" : "darkGreen.main"}
              >
                Email
              </Typography>
              <TextField
                fullWidth
                type="email"
                id="email"
                error={unauthorized}
                value={values.email}
                onChange={handleChange}
                variant="outlined"
                color="darkGrey"
                placeholder="Enter your email"
                className="mt-1"
                InputProps={{ className: "rounded-xl" }}
              />
            </div>
            <div>
              <Typography
                variant="body1ExtraBold"
                color={unauthorized ? "error" : "darkGreen.main"}
              >
                Password
              </Typography>
              <TextField
                fullWidth
                type="password"
                id="password"
                error={unauthorized}
                value={values.password}
                onChange={handleChange}
                variant="outlined"
                color="darkGrey"
                placeholder="Enter your password"
                className="my-1"
                InputProps={{ className: "rounded-xl" }}
              />
              {unauthorized && (
                <Typography variant="body2" color="error" className="mt-1 mb-5">
                  Wrong email or password
                </Typography>
              )}
              <div className="my-2">
                <Typography
                  variant="body2ExtraBold"
                  color="primary"
                  onClick={handleRecover}
                >
                  Forgot password?
                </Typography>
              </div>
            </div>
            <div className="pb-6">
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
                loadingPosition="start"
                startIcon={<></>}
                disabled={values.email === "" || values.password === ""}
                className="rounded-[20px] mb-2"
              >
                <Typography variant="body1ExtraBold">Log In</Typography>
              </LoadingButton>
              <div className="text-center" onClick={handleSignUp}>
                <Typography variant="body2Bold" color="lightGreen.main">
                  Don't have an account?&nbsp;
                </Typography>
                <Typography variant="body2ExtraBold" color="primary">
                  Sign Up
                </Typography>
              </div>
            </div>
            <Disclaimer />
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default EmailSignIn;
