import React from "react";

import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

import LoginBottomSheet from "components/LoginBottomSheet";
import { customEvent } from "utils/AnalyticsEvent";

const AppbarRightActionMain = ({
  communityId,
  showLogin,
}: {
  communityId?: string;
  showLogin?: boolean;
}) => {
  const [loginOpen, setLoginOpen] = React.useState(showLogin === true);

  const handleSignUp = () => {
    customEvent("top_nav_icon_signup", { communityId });
    setLoginOpen(true);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleSignUp}
        className="px-2 py-0"
      >
        <Typography variant="body4" className="font-bold">
          Sign In / Up
        </Typography>
      </Button>
      <LoginBottomSheet
        label="Sign up or log in to start enjoying Oliv"
        open={loginOpen}
        setOpen={setLoginOpen}
        initialStep={showLogin ? "emailSignIn" : undefined}
      />
    </>
  );
};

export default AppbarRightActionMain;
