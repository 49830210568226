import React from "react";

import { Snackbar, Stack, Typography } from "@mui/material";
import {
  AccessTimeFilledRounded,
  EditRounded,
  LaunchRounded,
  LocationOn,
  PhoneRounded,
  PublicRounded,
} from "@mui/icons-material";

import BusinessDetailInfoBusinessHours from "./BusinessDetailInfoBusinessHours";
import Constants from "configs/Constants";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";
import { Business } from "data/models/business";
import { useCommunity } from "data/hooks/communities";
import { useBusiness } from "data/hooks/business";

const BusinessDetailInfo = ({
  communityId,
  businessId,
}: {
  communityId: string;
  businessId: string;
}) => {
  const community = useCommunity(communityId);
  if (!community) throw Error("Community not found");

  const business = useBusiness(community, businessId);
  if (!business) throw Error("Business not found");

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const logEvent = (eventName: string) => {
    customEvent(eventName, {
      communityId: community.id,
      businessId: business.id,
    });
  };

  const infoEntities = [
    {
      metadata: business.phone,
      icon: <PhoneRounded />,
      handleClick: () => {
        logEvent("bus_dp_data_phone");
        window.open(`tel:${business.phone}`, "_self");
      },
    },
    {
      metadata: business.address,
      icon: <LocationOn />,
      handleClick: () => {
        logEvent("bus_dp_data_address");
        setSnackbarOpen(true);
        navigator.clipboard.writeText(business.address);
      },
    },
    {
      icon: <AccessTimeFilledRounded />,
      handleClick: () => {},
      type: "businessHours",
    },
    {
      metadata: business.website,
      icon: <PublicRounded />,
      handleClick: () => {
        logEvent("bus_dp_data_site");
        window.open(`http://${business.website}`, "_blank");
      },
    },
    ...(business.googleId
      ? [
          {
            metadata: "View more info on Google Map",
            icon: <LaunchRounded />,
            handleClick: () => {
              logEvent("bus_dp_data_gm");
              window.open(
                `https://www.google.com/maps/place/?q=place_id:${business.googleId}`,
                "_blank"
              );
            },
          },
        ]
      : []),
    {
      metadata: "Suggest an edit",
      icon: <EditRounded />,
      handleClick: () => {
        logEvent("bus_dp_suggest_edit");
        window.open(Constants.BUSINESS_EDIT_FORM, "_blank");
      },
    },
  ];

  return (
    <>
      <Stack spacing={4} className="px-4 pb-8">
        <Typography variant="h6">Business Info</Typography>
        {infoEntities.map((entity, i) => (
          <BusinessDetailInfoEntity
            key={i}
            community={community}
            business={business}
            metadata={entity.metadata}
            icon={entity.icon}
            handleClick={entity.handleClick}
            type={entity.type}
          />
        ))}
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="Address copied to clipboard"
        className="w-fit mx-auto"
      />
    </>
  );
};

const BusinessDetailInfoEntity = ({
  community,
  business,
  metadata,
  icon,
  type,
  handleClick,
}: {
  community: Community;
  business: Business;
  metadata?: string;
  icon: React.ReactNode;
  type?: string;
  handleClick: () => void;
}) => {
  if (type === "businessHours") {
    return (
      <BusinessDetailInfoBusinessHours
        community={community}
        business={business}
        icon={icon}
      />
    );
  }

  if (!metadata) return <></>;

  return (
    <Stack direction="row" spacing={1} onClick={handleClick}>
      <Typography
        variant="body1"
        color="lightGreen.main"
        className="flex items-center"
      >
        {icon}
      </Typography>
      <Typography
        variant="body1"
        color="lightGreen.main"
        className="flex items-center"
        dangerouslySetInnerHTML={{ __html: metadata }}
      />
    </Stack>
  );
};

export default BusinessDetailInfo;
