import React from "react";

import { AppBar, IconButton, InputBase, Toolbar } from "@mui/material";
import { ArrowBackRounded, CancelRounded } from "@mui/icons-material";
import { Business } from "data/models/business";

const LikeChallengeSearchBox = ({
  query,
  setQuery,
  handleLike,
}: {
  query: string;
  setQuery: (query: string) => void;
  handleLike: (business?: Business) => void;
}) => {
  const searchInputRef = React.useRef<HTMLInputElement>();

  const handleClear = () => {
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
      searchInputRef.current.focus();
    }
    setQuery("");
  };

  const handleBack = () => handleLike();

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(evt.target.value);
  };

  return (
    <>
      <AppBar
        color="white"
        elevation={0}
        className="border-0 border-b border-solid border-light-grey rounded-none"
      >
        <Toolbar className="w-full m-auto">
          <IconButton color="black" onClick={handleBack} className="-ml-[12px]">
            <ArrowBackRounded />
          </IconButton>
          <InputBase
            autoFocus
            inputRef={searchInputRef}
            placeholder="Search places you'd like to recommend"
            value={query}
            onChange={handleChange}
            className="flex-1 text-[15px] font-bold"
          />
          <IconButton onClick={handleClear} className="-mr-[12px]">
            {!!searchInputRef.current?.value && <CancelRounded />}
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default LikeChallengeSearchBox;
