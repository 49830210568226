import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Button, Divider, Stack, Typography } from "@mui/material";
import { RateReviewOutlined } from "@mui/icons-material";

import CommentCards from "./CommentCards";
import CommunityJoinAction from "components/CommunityJoinAction";
import { getReviewUrl } from "configs/Paths";
import { useData } from "providers/AuthProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { useCommunity } from "data/hooks/communities";
import { useBusiness } from "data/hooks/business";

const BusinessComments = ({
  communityId,
  businessId,
}: {
  communityId: string;
  businessId: string;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useData();

  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  const business = useBusiness(community, businessId);
  if (!business) throw new Error("Business not found");

  const reviewRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (location.state?.scrollTo === "Reviews") {
      scrollToReviewRef();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const scrollToReviewRef = () => {
    reviewRef.current?.scrollIntoView({ block: "center" });
  };

  if (user == null || !community.joined) {
    return (
      <Stack ref={reviewRef} spacing={2} className="py-6">
        <Typography variant="body1ExtraBold" className="text-center px-8 pt-4">
          Explore community member activities by joining this community
        </Typography>
        <div className="px-4">
          <CommunityJoinAction
            community={community}
            eventName="bus_dp_join_community_cta"
          />
        </div>
        <Divider className="pt-4" />
      </Stack>
    );
  }

  const handleClick = () => {
    customEvent("bus_dp_button_review", { communityId, businessId });
    navigate(getReviewUrl(communityId, businessId));
  };

  return (
    <Stack spacing={2} className="py-6">
      <div className="flex justify-between items-center px-4">
        <Typography ref={reviewRef} variant="h6">
          Community Reviews
        </Typography>
        <Button
          disableFocusRipple
          variant="contained"
          color="buttonGrey"
          size="small"
          startIcon={<RateReviewOutlined />}
          onClick={handleClick}
          className="py-0 px-4"
        >
          <Typography variant="body3ExtraBold">Write a review</Typography>
        </Button>
      </div>
      <CommentCards community={community} business={business} />
    </Stack>
  );
};

export default BusinessComments;
