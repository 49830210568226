import React from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";

import { Button, Stack, Typography } from "@mui/material";
import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  ChatBubbleOutline,
  FavoriteBorderOutlined,
  FavoriteOutlined,
} from "@mui/icons-material";

import CommunityJoinModal from "components/CommunityJoinModal";
import { getCommunityBusinessUrl } from "configs/Paths";
import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { useData } from "providers/AuthProvider";
import { Business } from "data/models/business";
import { Community } from "data/models/community";
import {
  useBookmark,
  useRecommend,
  useUnbookmark,
  useUnrecommend,
} from "data/hooks/business";

const CommunityCardAction = ({
  icon,
  activeIcon,
  label,
  isActive,
  handleClick,
}: {
  icon: React.ReactNode;
  activeIcon?: React.ReactNode;
  label?: string;
  isActive: boolean;
  handleClick: (evt: React.MouseEvent) => void;
}) => {
  return (
    <Button
      disableFocusRipple
      variant="contained"
      color="buttonGrey"
      size="small"
      startIcon={isActive ? activeIcon : icon}
      className={clsx("min-w-0 py-[21px]", { "px-[21px]": label })}
      classes={{
        startIcon: clsx({
          "m-0": !label,
          "text-primary": isActive,
        }),
      }}
      onClick={handleClick}
    >
      <Typography variant="body2Bold" color={isActive ? "primary" : "inherit"}>
        {label}
      </Typography>
    </Button>
  );
};

const CommunityCardActions = ({
  community,
  business,
  index,
}: {
  community: Community;
  business: Business;
  index: number;
}) => {
  const navigate = useNavigate();

  const [modalOpen, setModalOpen] = React.useState(false);

  const recommend = useRecommend(community, business);
  const unrecommend = useUnrecommend(community, business);
  const bookmark = useBookmark(community, business);
  const unbookmark = useUnbookmark(community, business);

  const { user } = useData();

  const communityId = community.urlName;

  if (!business) throw new Error("Business not found");

  const { mode } = useCommunityState();
  const isList = mode === "List";

  const userType =
    user != null ? (community.joined ? "member" : "non-member") : "unauth";

  const handleLike = () => {
    if (!community.joined) {
      setModalOpen(true);
    } else {
      if (business.recommended) {
        customEvent("icon_unrecommend", {
          communityId,
          index,
          userType,
          location: isList ? "list" : "map",
        });
        unrecommend.mutate();
      } else {
        customEvent("icon_recommend", {
          communityId,
          index,
          userType,
          location: isList ? "list" : "map",
        });
        recommend.mutate();
      }
    }
  };

  const handleComment = () => {
    const eventName = isList
      ? "com_dp_icon_comment"
      : "com_dp_map_icon_comment";
    customEvent(eventName, { communityId, index, userType });
    navigate(getCommunityBusinessUrl(communityId, business.id), {
      state: { scrollTo: "Reviews" },
    });
  };

  const handleBookmark = () => {
    const eventName = isList ? "com_dp_icon" : "com_dp_map_icon";
    if (!community.joined) {
      customEvent(`${eventName}_save`, { communityId, index, userType });
      setModalOpen(true);
    } else {
      if (business.bookmarked) {
        customEvent(eventName, { communityId, index });
        customEvent(`${eventName}_unsave`, { communityId, index, userType });
        unbookmark.mutate();
      } else {
        customEvent(`${eventName}_save`, { communityId, index, userType });
        customEvent(eventName, { communityId, index, userType });
        bookmark.mutate();
      }
    }
  };

  const stopPropagation = (evt: React.MouseEvent) => {
    evt.stopPropagation();
  };

  return (
    <div onClick={stopPropagation}>
      <Stack direction="row" spacing={1}>
        <CommunityCardAction
          icon={<FavoriteBorderOutlined />}
          activeIcon={<FavoriteOutlined />}
          label={business.recommendationsCount.toString()}
          isActive={business.recommended}
          handleClick={handleLike}
        />
        {business.reviewsCount > 0 && (
          <CommunityCardAction
            icon={<ChatBubbleOutline />}
            label={business.reviewsCount.toString()}
            isActive={false}
            handleClick={handleComment}
          />
        )}
        <CommunityCardAction
          icon={<BookmarkBorderOutlined />}
          activeIcon={<BookmarkOutlined />}
          isActive={business.bookmarked}
          handleClick={handleBookmark}
        />
      </Stack>
      <CommunityJoinModal
        community={community}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
    </div>
  );
};

export default CommunityCardActions;
