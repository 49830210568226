import React from "react";

import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";

import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import { Review } from "data/models/review";
import { ImageType, OlivImage } from "data/models/olivImage";

const CommentCardImages = ({ review }: { review: Review }) => {
  const [swiper, setSwiper] = React.useState<SwiperClass>();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [imageUrls, setImageUrls] = React.useState<string[]>([]);

  React.useEffect(() => {
    if (!review) return;
    const fetchImageUrls = async () => {
      const urls = await Promise.all(
        review.sliderImages
          .filter((image) => image.type === ImageType.member)
          .map(async (image: OlivImage) => image.imageUrl)
      );
      setImageUrls(urls);
    };

    fetchImageUrls();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (imageUrls.length === 0) return <></>;

  const handleImageChange = (swipeIndex: number) => {
    setCurrentIndex(swipeIndex);
  };

  const handleLightboxSwipe = (index: number) => {
    swiper?.slideTo(index);
  };

  return (
    <>
      <Swiper
        pagination={{ dynamicBullets: true }}
        modules={[Pagination, Virtual]}
        className="relative w-full aspect-16/9 rounded-lg"
        virtual={{ addSlidesAfter: 1, addSlidesBefore: 1 }}
        onSlideChange={(swiper) => handleImageChange(swiper.activeIndex)}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        {imageUrls.map((url: string, index: number) => (
          <SwiperSlide key={url} virtualIndex={index}>
            <img
              src={url}
              alt={url}
              onClick={() => setIsOpen(true)}
              className="object-cover w-full aspect-16/9"
            />
            <div className="absolute bottom-0 w-full h-14 bg-gradient-to-t from-black/40" />
          </SwiperSlide>
        ))}
      </Swiper>
      <Lightbox
        open={isOpen}
        index={currentIndex}
        close={() => setIsOpen(false)}
        on={{
          view: ({ index: i }) => handleLightboxSwipe(i),
        }}
        carousel={{
          padding: 0,
        }}
        slides={imageUrls.map((url: string) => ({
          src: url,
          imageFit: "cover",
        }))}
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, .9)" } }}
      />
    </>
  );
};

export default CommentCardImages;
