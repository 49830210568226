import { Grid, Skeleton, Typography } from "@mui/material";

export const MainNavLoading = () => {
  const emptyArray = Array(8).fill(undefined);

  return (
    <div className="flex-1 overflow-scroll">
      {emptyArray.map((_, i) => (
        <Grid container key={i} spacing={2} className="items-center py-2 px-4">
          <Grid item xs={3}>
            <Skeleton
              variant="rounded"
              width="100%"
              height="100%"
              className="aspect-square"
            />
          </Grid>
          <Grid item xs={9}>
            <Typography variant="body1Bold">
              <Skeleton variant="text" />
            </Typography>
          </Grid>
        </Grid>
      ))}
    </div>
  );
};
