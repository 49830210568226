import { Skeleton, Stack } from "@mui/material";

export const DiscoverLoading = () => {
  const emptyArray = Array(8).fill(undefined);
  return (
    <Stack spacing={{ xs: 2 }}>
      <Skeleton height="40px" variant="rectangular" className="rounded-lg" />
      {emptyArray.map((_, i) => (
        <Skeleton
          key={i}
          width="100%"
          height="100%"
          variant="rounded"
          className="rounded-xl aspect-[16/9]"
        />
      ))}
    </Stack>
  );
};
