import React from "react";

import { Collapse, Stack, Typography } from "@mui/material";
import {
  ArrowDropDownOutlined,
  ArrowDropUpOutlined,
} from "@mui/icons-material";

import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";
import { Business } from "data/models/business";
import { useBusinessHours } from "data/hooks/businessHours";
import dayjs from "dayjs";

const BusinessDetailInfoBusinessHours = ({
  community,
  business,
  icon,
}: {
  community: Community;
  business: Business;
  icon: React.ReactNode;
}) => {
  const [expanded, setExpanded] = React.useState(false);

  const businessHours = useBusinessHours(business);
  if (!businessHours?.hours) return <></>;

  const today = (dayjs().day() + 6) % 7;

  const handleClick = () => {
    const communityId = community.urlName;
    const businessId = business.id;

    if (expanded)
      customEvent("bus_dp_data_hours_collapse", { communityId, businessId });
    else customEvent("bus_dp_data_hours", { communityId, businessId });
    setExpanded(!expanded);
  };

  return (
    <>
      <Stack direction="row" spacing={1} onClick={handleClick}>
        <Typography
          variant="body1"
          color="lightGreen.main"
          className="flex items-center"
        >
          {icon}
        </Typography>
        {businessHours.isOpen ? (
          <Typography
            variant="body1"
            className="flex items-center text-[#4CAF50]"
          >
            {businessHours.hours[today].range1 === "Open 24 hours"
              ? "Open 24 hours"
              : "Open"}
          </Typography>
        ) : (
          <Typography
            variant="body1"
            className="flex items-center text-[#F44336]"
          >
            Closed
          </Typography>
        )}
        {expanded ? (
          <ArrowDropUpOutlined sx={{ color: "#0000008A" }} />
        ) : (
          <ArrowDropDownOutlined sx={{ color: "#0000008A" }} />
        )}
      </Stack>
      <Collapse in={expanded} easing="ease-in" className="!mt-0">
        <Stack spacing={2} className="p-8 pb-0">
          {businessHours.hours.map((businessHour, i) => (
            <div key={businessHour.day} className="flex justify-between">
              <Typography
                variant="body1"
                color="lightGreen.main"
                className={today === i ? "font-bold" : ""}
              >
                {businessHour.day}
              </Typography>
              <div className="text-end">
                <Typography
                  variant="body1"
                  color="lightGreen.main"
                  className={today === i ? "font-bold" : ""}
                >
                  {businessHour.range1}
                </Typography>

                {businessHour.range2 && (
                  <Typography
                    key={`${i}-2`}
                    variant="body1"
                    color="lightGreen.main"
                    className={today === i ? "font-bold" : ""}
                  >
                    {businessHour.range2}
                  </Typography>
                )}
              </div>
            </div>
          ))}
        </Stack>
      </Collapse>
    </>
  );
};

export default BusinessDetailInfoBusinessHours;
