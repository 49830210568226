import React from "react";
import { useNavigate } from "react-router-dom";

import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { DashboardCustomizeRounded } from "@mui/icons-material";

import NavCommunitiesSection from "./NavCommunitiesSection";
import NavMenuSection from "./NavMenuSection";
import { MainNavLoading } from "./loading";
import { Paths } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";

const NavDrawer = ({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const navigate = useNavigate();

  const handleDashboardClick = () => {
    customEvent("drawer_icon_dash", {});
    handleMenuCLick(Paths.main);
  };

  const handleMenuCLick = (path?: string) => {
    setOpen(false);
    if (path) navigate(path, { state: { from: window.location.pathname } });
  };

  return (
    <>
      <Drawer
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          className: "rounded-r w-9/12 max-w-lg",
        }}
        className="z-[20000]"
      >
        <Box className="flex justify-between pt-6 px-4 pb-3">
          <Typography variant="h6">Your Communities</Typography>
          <IconButton
            color="lightGreen"
            onClick={handleDashboardClick}
            sx={{ mt: "-12px", mr: "-12px" }}
          >
            <DashboardCustomizeRounded />
          </IconButton>
        </Box>
        <React.Suspense fallback={<MainNavLoading />}>
          <NavCommunitiesSection handleMenuClick={handleMenuCLick} />
        </React.Suspense>
        <Divider />
        <NavMenuSection handleMenuClick={handleMenuCLick} />
      </Drawer>
    </>
  );
};

export default NavDrawer;
