import { Skeleton, Stack, Typography } from "@mui/material";

export const HeaderLoading = () => {
  return (
    <>
      <Skeleton
        variant="rounded"
        width="100%"
        height="100%"
        className="aspect-4"
      />
      <div className="px-4">
        <Skeleton
          variant="circular"
          width="105px"
          height="105px"
          className="mt-[-55px]"
        />
        <Typography variant="h4Small">
          <Skeleton className="mt-4" />
        </Typography>
        <Typography variant="body2">
          <Skeleton className="pt-1" />
        </Typography>
      </div>
    </>
  );
};

export const CommunityMainLoading = () => {
  return (
    <Stack spacing={2} className="p-4">
      <Skeleton variant="rectangular" width="100%" height="100px" />
      <Skeleton variant="rectangular" width="100%" height="50px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
    </Stack>
  );
};

export const CommunityOverflowMemberLoading = () => {
  return (
    <div className="px-4 py-6">
      <Typography variant="h5">
        <Skeleton width="100%" />
      </Typography>
      <Skeleton width="100%" height="35px" className="my-2" />
      <Stack spacing={2} className="overflow-y-scroll h-[50vh]">
        <Skeleton variant="rectangular" width="100%" height="100px" />
        <Skeleton variant="rectangular" width="100%" height="100px" />
        <Skeleton variant="rectangular" width="100%" height="100px" />
        <Skeleton variant="rectangular" width="100%" height="100px" />
        <Skeleton variant="rectangular" width="100%" height="100px" />
      </Stack>
    </div>
  );
};
