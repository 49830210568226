import React from "react";
import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import OlivAppBar from "components/OlivAppBar";
import { Paths } from "configs/Paths";
import { useData } from "providers/AuthProvider";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { user } = useData();

  React.useEffect(() => {
    if (user?.id) {
      navigate(Paths.discover);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (user === null) {
    return (
      <>
        <OlivAppBar />
        <Stack spacing={2} className="py-12 px-10">
          <Typography variant="h4Small">
            Sorry. Your activation link has expired.
          </Typography>
          <Typography variant="body1">
            Account activation links are valid for a limited time for security
            reasons.
          </Typography>
          <Typography variant="body1" className="py-6">
            To activate your account, please request a new activation link by
            logging into your account.
          </Typography>
        </Stack>
      </>
    );
  } else {
    return <></>;
  }
};

export default VerifyEmail;
