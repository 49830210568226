import React from "react";
import { useFormikContext } from "formik";

import { Button, Stack, Typography } from "@mui/material";

import LikeChallengeCards from "./LikeChallengeCards";
import LikeChallengeSearch from "./LikeChallengeSearch";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const LikeChallenge = ({
  community,
  handleComplete,
}: {
  community: Community;
  handleComplete: () => void;
}) => {
  const [searching, setSearching] = React.useState(false);
  const [likedBusinesses, setLikedBusinesses] = React.useState<
    (Business | undefined)[]
  >(Array(3).fill(undefined));

  const { setFieldValue } = useFormikContext<ICommunityJoinForm>();

  const handleClick = (business?: Business) => {
    if (!business) setSearching(true);
  };

  const handleLike = (business?: Business) => {
    setSearching(false);

    if (!business) return;
    const activeIndex = likedBusinesses.findIndex((l) => l === undefined);
    likedBusinesses[activeIndex] = business;
    setLikedBusinesses([...likedBusinesses]);
  };

  const handleRemove = (index: number) => {
    likedBusinesses[index] = undefined;
    setLikedBusinesses([...likedBusinesses]);
  };

  const handleFinish = () => {
    const likedBusinessIds = likedBusinesses.map((b) => b?.id);
    setFieldValue("likes", likedBusinessIds);
    handleComplete();
  };

  return searching ? (
    <LikeChallengeSearch
      community={community}
      likedBusinesses={
        likedBusinesses.filter((b) => b !== undefined) as Business[]
      }
      handleLike={handleLike}
    />
  ) : (
    <Stack spacing={4} className="flex justify-center items-center h-full p-4">
      <Typography variant="h4" className="text-center">
        Please recommend your top 3 Restaurants at SF Bay, CA for this
        community.
      </Typography>
      <LikeChallengeCards
        businesses={likedBusinesses}
        handleClick={handleClick}
        handleRemove={handleRemove}
      />
      {likedBusinesses.every((b) => b !== undefined) && (
        <Button fullWidth variant="contained" onClick={handleFinish}>
          <Typography variant="body1ExtraBold">
            Finish recommendation
          </Typography>
        </Button>
      )}
    </Stack>
  );
};

export default LikeChallenge;
