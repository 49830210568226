import React from "react";
import debounce from "lodash.debounce";

import { Grid, Stack, Typography } from "@mui/material";

import { search } from "data/apis/businesses";
import { Business } from "data/models/business";
import { Community } from "data/models/community";

const BusinessImage = ({ business }: { business: Business }) => {
  return (
    <img
      src={business.image}
      alt={business.name}
      className="aspect-square w-full object-cover rounded-lg border border-solid border-light-grey"
    />
  );
};

const BusinessMetadata = ({ business }: { business: Business }) => {
  return (
    <Stack sx={{ pr: 1 }}>
      <Typography variant="body1Bold">{business.name}</Typography>
      <Typography variant="body3" color="lightGreen.main">
        {business.city}
      </Typography>
    </Stack>
  );
};

const LikeChallegeSearchResults = ({
  community,
  likedBusinesses,
  query,
  handleLike,
}: {
  community: Community;
  likedBusinesses: Business[];
  query: string;
  handleLike: (business: Business) => void;
}) => {
  const [results, setResults] = React.useState<Business[]>();
  const likedBusinessIds = likedBusinesses.map((business) => business.id);

  const getResults = React.useRef(
    debounce(async (value: string) => {
      if (!value) {
        setResults(undefined);
      } else {
        const businesses = await search(community, value);
        setResults(businesses.filter((b) => !likedBusinessIds.includes(b.id)));
      }
    }, 0)
  );

  React.useEffect(() => {
    getResults.current(query);
  }, [query]);

  if (!results) return <></>;

  return (
    <div>
      {results.length > 0 ? (
        <Stack spacing={2} className="bg-white p-4 rounded-xl">
          {results.map((business) => (
            <div>
              <Grid container spacing={2} onClick={() => handleLike(business)}>
                <Grid item xs={2}>
                  <BusinessImage business={business} />
                </Grid>
                <Grid item xs={10} className="flex items-center">
                  <BusinessMetadata business={business} />
                </Grid>
              </Grid>
            </div>
          ))}
        </Stack>
      ) : (
        <Typography variant="body2" className="text-center pt-4">
          Your search, <b>{query}</b>, did not match any business.
        </Typography>
      )}
    </div>
  );
};

export default LikeChallegeSearchResults;
