import React from "react";

import { Divider, Stack, Typography } from "@mui/material";
import { ExpandCircleDownRounded } from "@mui/icons-material";

import CommunityFeedRecommendation from "./CommunityFeedRecommendation";
import { Feed } from "data/models/feed";
import { Community } from "data/models/community";

const CommunityFeedCardDivider = () => (
  <div className="py-8">
    <Divider />
  </div>
);

const CommunityFeedRecommendations = ({
  community,
  feed,
  index,
}: {
  community: Community;
  feed: Feed;
  index: number;
}) => {
  const businesses = feed.businesses!;

  const [expanded, setExpanded] = React.useState(false);

  if (expanded) {
    const expand = (f: Feed, i: number) => (
      <>
        {i > 0 && <CommunityFeedCardDivider />}
        <CommunityFeedRecommendation
          community={community}
          feed={f}
          index={index + i}
        />
      </>
    );
    return <>{businesses!.map(expand)}</>;
  }

  return (
    <>
      <CommunityFeedRecommendation
        community={community}
        feed={businesses[0]}
        index={index}
      />
      <Stack
        spacing={1}
        direction="row"
        className="px-4 pt-4 flex items-center"
        onClick={() => setExpanded(true)}
      >
        {businesses.slice(1, 5).map((r, i) => (
          <img
            key={r.businessId}
            src={r.businessImage}
            alt={r.businessName}
            className="aspect-square rounded-lg object-cover border border-solid border-light-grey h-[40px]"
          />
        ))}
        <Typography variant="body2" color="darkGreen.main" className="pl-2">
          +{businesses.length - 1} other businesses
        </Typography>
        <ExpandCircleDownRounded fontSize="small" className="text-dark-grey" />
      </Stack>
    </>
  );
};

export default CommunityFeedRecommendations;
