import React from "react";
import { useNavigate } from "react-router-dom";

import ProfileAvatar from "components/ProfileAvatar";
import { getCommunityProfileUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";
import { useCommunity } from "data/hooks/communities";

const AppbarRightActionCommunity = ({
  communityId,
  hideProfile,
  showLogin,
}: {
  communityId: string;
  hideProfile?: boolean;
  showLogin?: boolean;
}) => {
  const navigate = useNavigate();

  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  const handleClick = () => {
    if (!communityId) return;
    customEvent("top_nav_icon_profile", { communityId });
    navigate(getCommunityProfileUrl(communityId));
  };

  if (hideProfile || !community.joined) return null;

  return (
    <ProfileAvatar
      profile={community.member!}
      size={28}
      handleClick={handleClick}
    />
  );
};

export default AppbarRightActionCommunity;
