import * as RecommendationsApi from "data/apis/recommendations";
import { useQuery } from "@tanstack/react-query";
import { Business } from "data/models/business";
import { Community } from "data/models/community";

export const useBusinessRecommendations = (
  community: Community,
  business: Business
) => {
  const { data } = useQuery({
    queryKey: ["recommendations", community.id, business.id],
    queryFn: async () => await RecommendationsApi.fetch(community, business),
  });

  return data || [];
};
