import React from "react";
import { useNavigate } from "react-router-dom";

import { AppBar, IconButton, InputBase, Toolbar } from "@mui/material";
import { ArrowBackRounded, CancelRounded } from "@mui/icons-material";

import { customEvent } from "utils/AnalyticsEvent";

const BusinessSearchBox = ({
  communityId,
  query,
  setQuery,
}: {
  communityId: string;
  query: string;
  setQuery: (query: string) => void;
}) => {
  const navigate = useNavigate();

  const searchInputRef = React.useRef<HTMLInputElement>();

  React.useEffect(() => {
    if (searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, []);

  const handleClear = () => {
    customEvent("search_icon_text_remove", { communityId });
    if (searchInputRef.current) {
      searchInputRef.current.value = "";
      searchInputRef.current.focus();
    }
    setQuery("");
  };

  const handleBack = () => {
    customEvent("search_icon_back", { communityId });
    navigate(-1);
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(evt.target.value);
  };

  return (
    <>
      <AppBar
        color="white"
        elevation={0}
        className="border-0 border-b border-solid border-light-grey rounded-none"
      >
        <Toolbar className="w-full m-auto">
          <IconButton color="black" onClick={handleBack} className="-ml-[12px]">
            <ArrowBackRounded />
          </IconButton>
          <InputBase
            autoFocus
            inputRef={searchInputRef}
            placeholder="Search places you'd like to recommend"
            value={query}
            onChange={handleChange}
            className="flex-1 text-[15px] font-bold"
          />
          {!!query && (
            <IconButton onClick={handleClear} className="-mr-[12px]">
              <CancelRounded />
            </IconButton>
          )}
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default BusinessSearchBox;
