import { Grid, Skeleton, Stack, Typography } from "@mui/material";

export const BusinessHeaderLoading = () => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Skeleton
            variant="rectangular"
            width="100%"
            height="100%"
            className="aspect-square"
          />
        </Grid>
        <Grid item xs={10} className="flex items-center">
          <Stack className="w-full">
            <Typography variant="body1Bold">
              <Skeleton width="80%" />
            </Typography>
            <Typography variant="body3">
              <Skeleton width="80%" />
            </Typography>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};
