import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";
import { getCommunityUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityCard = ({
  community,
  index,
}: {
  community: Community;
  index: number;
}) => {
  const navigate = useNavigate();

  const communityId = community.urlName;

  const handleClick = () => {
    customEvent("dash_box_com", { communityId, index });
    navigate(getCommunityUrl(community.urlName));
  };

  return (
    <div
      onClick={handleClick}
      className="aspect-square overflow-hidden border border-solid border-light-grey rounded-xl"
    >
      <img
        src={community.bgImgUrl}
        alt={community.name}
        className="relative object-cover opacity-80 w-full h-[40%] z-0"
      />
      <img
        src={community.coverImgUrl}
        alt={community.name}
        className="relative aspect-square object-cover rounded-full border border-solid border-light-grey mt-[-20%] left-3 h-[40%]"
      />
      <Typography variant="body2ExtraBold" className="px-3 pt-1 line-clamp-2">
        {community.name}
      </Typography>
    </div>
  );
};

export default CommunityCard;
