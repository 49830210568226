import React from "react";
import { Button } from "@mui/material";

import { ArrowDropDownOutlined } from "@mui/icons-material";
import { LocalFireDepartment, NearMeOutlined } from "@mui/icons-material";

import BottomSheet from "components/BottomSheet";
import useGeolocation from "data/hooks/location";
import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityFiltersSort = ({ community }: { community: Community }) => {
  const { listSorting, setListSorting } = useCommunityState();
  const { status, getUserLocation } = useGeolocation({ community });

  const [open, setOpen] = React.useState(false);

  const communityId = community.urlName;

  const menuItems = [
    { label: "Popular", icon: <LocalFireDepartment /> },
    { label: "Distance", icon: <NearMeOutlined /> },
  ];

  const isActive = (label: string) => {
    if (listSorting === "Popular" && label === listSorting) return true;
    if (typeof listSorting === "object" && label === "Distance") return true;
    return false;
  };

  const getButtonLabel = () => {
    if (listSorting === "Popular") return "Popular";
    if (typeof listSorting === "object") return "Distance";
    return "Sort by";
  };

  const getButtonIcon = () => {
    if (listSorting === "Popular") return <LocalFireDepartment />;
    if (typeof listSorting === "object") return <NearMeOutlined />;
    return null;
  };

  const handleClick = () => {
    customEvent("com_dp_filter_sort", { communityId });
    setOpen(true);
  };

  const handleSelect = async (label: string) => {
    if (label === "Popular") {
      customEvent("com_dp_filter_sort_m_popular", { communityId });
      setListSorting("Popular");
    } else if (label === "Distance") {
      customEvent("com_dp_filter_sort_m_distance", { communityId });
      const location = await getUserLocation();
      setListSorting(location);
    }
  };

  const handleClose = () => {
    customEvent("com_dp_filter_sort_m_close", { communityId });
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color="primary"
        startIcon={getButtonIcon()}
        endIcon={<ArrowDropDownOutlined />}
        className="rounded-xl h-10 px-2 py-1.5 btn-selected [&>.MuiButton-startIcon]:mr-1 [&>.MuiButton-endIcon]:ml-0"
        onClick={handleClick}
      >
        {getButtonLabel()}
      </Button>
      <BottomSheet
        variant="menu"
        open={open}
        close={handleClose}
        label="Sort by"
        menuItems={menuItems.map((item) => ({
          ...item,
          loading: item.label === "Distance" && status === "loading",
          active: isActive(item.label),
          handleClick: () => handleSelect(item.label),
        }))}
      />
    </>
  );
};

export default CommunityFiltersSort;
