import React from "react";
import { useFormikContext } from "formik";

import {
  Avatar,
  Badge,
  Box,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import { PhotoCameraRounded } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";

import { customEvent } from "utils/AnalyticsEvent";

const ProfileImageSelector = ({
  communityId,
  profileName,
}: {
  communityId: string;
  profileName: string;
}) => {
  const { setFieldValue } = useFormikContext<ICommunityJoinForm>();

  const [imageUrl, setImageUrl] = React.useState<string>();
  const imageUploaderRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    customEvent("challenge_profile_button_account", { communityId });
    imageUploaderRef.current?.click();
  };

  const handleImageUpload = async (files: FileList | null) => {
    if (!files) return;

    setFieldValue("profileImage", files);

    const image = Array.from(files)[0];
    if (!image) return;

    var reader = new FileReader();
    reader.onload = (e) => setImageUrl(e.target?.result as string | undefined);
    reader.readAsDataURL(image);
  };

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <div className="rounded-full bg-white p-1">
            <IconButton disableRipple className="bg-light-grey">
              <PhotoCameraRounded />
            </IconButton>
          </div>
        }
        onClick={handleClick}
      >
        <Avatar
          src={imageUrl || "na"}
          alt={profileName.toUpperCase()}
          className="w-28 h-28 text-5xl"
        />
      </Badge>
      <input
        hidden
        ref={imageUploaderRef}
        type="file"
        accept="image/*"
        id="images"
        onChange={(e) => handleImageUpload(e.target.files)}
      />
    </>
  );
};

const ProfileSelector = ({ communityId }: { communityId: string }) => {
  const { errors, isSubmitting, handleChange, values } =
    useFormikContext<ICommunityJoinForm>();

  React.useEffect(() => {
    customEvent("challenge_profile_page_view", { communityId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={4} className="flex justify-center items-center h-full p-9">
      <Typography variant="h4" sx={{ textAlign: "center" }}>
        Great! What name would you like to use for this community?
      </Typography>
      <ProfileImageSelector
        communityId={communityId}
        profileName={values.profileName}
      />
      <Box sx={{ pb: 2 }}>
        <InputBase
          id="profileName"
          value={values.profileName}
          placeholder="yourname"
          onChange={handleChange}
          inputProps={{
            autoCapitalize: "none",
            className:
              "text-center placeholder-gray-500 placeholder-opacity-25",
          }}
          className="font-extrabold text-[40px] leading-[48px] border-0 border-b-2 border-solid border-light-grey"
        />
        <div className="p-2">
          {errors.profileName && (
            <Typography variant="body1" color="error">
              {errors.profileName}
            </Typography>
          )}
        </div>
      </Box>
      <LoadingButton
        fullWidth
        type="submit"
        loading={isSubmitting}
        variant="contained"
        loadingPosition="start"
        disabled={values.profileName.length < 3}
      >
        <Typography variant="body1ExtraBold">Join community</Typography>
      </LoadingButton>
    </Stack>
  );
};

export default ProfileSelector;
