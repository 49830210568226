import React from "react";

import { IconButton, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { CloseOutlined } from "@mui/icons-material";

const BottomSheetMenu = (props: IBottomSheetProps) => {
  const { label, menuItems, close } = props;

  if (!menuItems) return null;

  return (
    <div className="p-4">
      {label && (
        <div className="flex justify-between items-center">
          <Typography variant="h5">{label}</Typography>
          <IconButton onClick={close}>
            <CloseOutlined />
          </IconButton>
        </div>
      )}
      {menuItems.map((item, i) => (
        <LoadingButton
          key={i}
          fullWidth
          variant="text"
          color={item.active ? "primary" : "black"}
          loadingPosition="start"
          loading={item.loading}
          startIcon={item.icon}
          onClick={async () => {
            item.handleClick && (await item.handleClick());
            close();
          }}
          className="justify-start rounded-none h-[50px] !p-0 [&>.MuiButton-startIcon]:ml-0"
        >
          <Typography variant="body1Bold" component="span" sx={{ pr: 2 }}>
            {item.label}
          </Typography>
          {item.sublabel && (
            <Typography
              variant="body1"
              component="span"
              color="lightGreen.main"
            >
              {item.sublabel}
            </Typography>
          )}
        </LoadingButton>
      ))}
    </div>
  );
};

export default BottomSheetMenu;
