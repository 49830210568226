import { Box, IconButton, Stack, Typography } from "@mui/material";
import { CancelRounded } from "@mui/icons-material";

import { Business } from "data/models/business";

const LikeChallengeCards = ({
  businesses,
  handleClick,
  handleRemove,
}: {
  businesses: (Business | undefined)[];
  handleClick: (business?: Business) => void;
  handleRemove: (index: number) => void;
}) => {
  return (
    <Stack spacing={3} direction="row">
      {businesses.map((business, i) => (
        <Box
          key={business?.id || i}
          onClick={() => handleClick(business)}
          sx={{ background: `url(${business?.image})` }}
          className="relative flex items-center justify-center w-24 h-24 rounded-full border-4 border-solid border-light-grey bg-cover"
        >
          {!business ? (
            <Typography variant="h3" color="lightGrey.main">
              {i + 1}
            </Typography>
          ) : (
            <IconButton
              color="lightGreen"
              onClick={() => handleRemove(i)}
              className="absolute -bottom-[10px] -right-[10px] p-0 bg-white"
            >
              <CancelRounded style={{ fontSize: "32px" }} />
            </IconButton>
          )}
        </Box>
      ))}
    </Stack>
  );
};

export default LikeChallengeCards;
