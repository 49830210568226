import React from "react";
import { Button } from "@mui/material";

import { ArrowDropDownOutlined } from "@mui/icons-material";

import BottomSheet from "components/BottomSheet";
import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityFiltersSingleSelect = ({
  community,
  filter,
  label,
  menuItems,
  eventName,
  eventParams,
}: {
  community: Community;
  filter: string;
  label: string;
  menuItems: IBottomSheetMenuItem[];
  eventName: string;
  eventParams?: { [key: string]: string };
}) => {
  const { mode, filters, setFilters } = useCommunityState();

  const [open, setOpen] = React.useState(false);

  const filterValue = filters.single[filter];
  const communityId = community.urlName;

  const handleClick = () => {
    customEvent(eventName, { communityId, mode, ...eventParams });
    setOpen(true);
  };

  const handleMenuClick = (item: IBottomSheetMenuItem) => {
    customEvent(eventName + "_submenu", { communityId, mode, ...eventParams });
    if (filters.single[filter] === item.label) {
      delete filters.single[filter];
      setFilters({ ...filters });
    } else {
      const singleFilters = { ...filters.single, [filter]: item.label };
      setFilters({ ...filters, single: singleFilters });
    }
  };

  const handleClose = () => {
    customEvent(eventName + "_m_close", { communityId, mode, ...eventParams });
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color={filterValue ? "primary" : "lightGreen"}
        className="rounded-xl h-10 px-2 py-1.5 btn-selected [&>.MuiButton-endIcon]:ml-0"
        endIcon={<ArrowDropDownOutlined />}
        onClick={handleClick}
      >
        {filterValue || label}
      </Button>
      <BottomSheet
        variant="menu"
        open={open}
        close={handleClose}
        label={label}
        menuItems={menuItems.map((item) => ({
          ...item,
          active: (filterValue as string) === item.label,
          handleClick: () => handleMenuClick(item),
        }))}
      />
    </>
  );
};

export default CommunityFiltersSingleSelect;
