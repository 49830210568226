import { plainToInstance } from "class-transformer";
import { supabase } from "configs/Supabase";
import { Community } from "data/models/community";

export const fetch = async (): Promise<Community[]> => {
  try {
    const { data, error } = await supabase.rpc("list_communities");

    if (error) {
      throw error;
    }

    return plainToInstance(Community, data as any[]);
  } catch (error) {
    return [];
  }
};

export const checkPasscode = async (
  community: Community,
  passcode: string
): Promise<boolean> => {
  try {
    const { data, error } = await supabase.functions.invoke("check-passcode", {
      body: { community_id: community.id, attempt: passcode },
    });

    if (error) {
      throw error;
    }

    return JSON.parse(data);
  } catch (error) {
    return false;
  }
};
