import React from "react";
import { useParams } from "react-router-dom";
import { Dialog } from "@mui/material";

import BusinessReviewConfirmation from "./BusinessReviewConfirmation";
import OlivAppBar from "components/OlivAppBar";
import { BusinessHeaderLoading } from "./loading";
import { customEvent } from "utils/AnalyticsEvent";
import BusinessReviewForm from "./BusinessReviewForm";

export type CommentEditorState = "ready" | "submitting" | "complete";

const CommentEditor = () => {
  const { communityId, businessId } = useParams();

  const [state, setState] = React.useState<CommentEditorState>("ready");

  React.useEffect(() => {
    customEvent("review_page_view", { communityId, businessId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!communityId || !businessId) return <></>;

  return state === "complete" ? (
    <BusinessReviewConfirmation
      communityId={communityId}
      businessId={businessId}
    />
  ) : (
    <Dialog fullScreen open={true}>
      <OlivAppBar
        showClose="review_close"
        communityId={communityId}
        businessId={businessId}
      />
      <React.Suspense fallback={<BusinessHeaderLoading />}>
        <BusinessReviewForm
          communityId={communityId}
          businessId={businessId}
          state={state}
          setState={setState}
        />
      </React.Suspense>
    </Dialog>
  );
};

export default CommentEditor;
