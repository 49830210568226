const LOCATION_SERVICE_URL = "https://ipapi.co/json/";

export const fetch = async () => {
  try {
    const response = await window.fetch(LOCATION_SERVICE_URL);
    const location = await response.json();
    return { lat: location.latitude, lng: location.longitude };
  } catch (error) {
    console.error(error);
    return { lat: 37.3861, lng: -122.0839 };
  }
};
