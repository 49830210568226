import { Drawer, Stack, Typography } from "@mui/material";

import ProfileAvatar from "components/ProfileAvatar";
import { useBusinessRecommendations } from "data/hooks/businessRecommendations";
import { Business } from "data/models/business";
import { Community } from "data/models/community";
import { Member } from "data/models/member";

const MemberCard = ({ profile }: { profile: Member }) => {
  return (
    <Stack spacing={2} direction="row" className="items-center">
      <ProfileAvatar profile={profile} size={50} />
      <Typography variant="body1">{profile.profileName}</Typography>
    </Stack>
  );
};

const BusinessHeaderLikesMembers = ({
  community,
  business,
  open,
  setOpen,
}: {
  community: Community;
  business: Business;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  const recommendations = useBusinessRecommendations(community, business);

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        className: "rounded-t",
      }}
      className="z-[2000]"
    >
      <div className="max-w-[650px] w-full mx-auto p-6 pb-0 overflow-hidden">
        <Typography variant="h5" className="pb-6">
          {recommendations.length} members recommended
        </Typography>
        <Stack spacing={2} className="pb-6 overflow-y-scroll h-[50vh]">
          {recommendations.map((r) => (
            <MemberCard key={r.id} profile={r.member} />
          ))}
        </Stack>
      </div>
    </Drawer>
  );
};

export default BusinessHeaderLikesMembers;
