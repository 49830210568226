import React from "react";
import { useFormikContext } from "formik";

import { CircularProgress, InputBase, Stack, Typography } from "@mui/material";

import * as CommunitiesApi from "data/apis/communities";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const PasscodeChallenge = ({
  community,
  handleComplete,
}: {
  community: Community;
  handleComplete: () => void;
}) => {
  const inputRefs = [
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
    React.useRef<HTMLInputElement>(null),
  ];

  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const [attempt, setAttempt] = React.useState<string>("");
  const [state, setState] = React.useState<string>("");

  const communityId = community.urlName;

  const { setFieldValue } = useFormikContext<ICommunityJoinForm>();

  React.useEffect(() => {
    customEvent("challenge_passcode_page_view", { communityId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    inputRefs[activeIndex].current?.getElementsByTagName("input")[0].focus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex]);

  React.useEffect(() => {
    const checkPasscode = async () => {
      setState("checking");
      inputRefs[activeIndex].current?.getElementsByTagName("input")[0].blur();
      const result = await CommunitiesApi.checkPasscode(community, attempt);

      if (result) {
        setFieldValue("passcode", attempt);
        handleComplete();
      } else {
        customEvent("challenge_passcode_error_wrong_code", { communityId });
        setState("error");
        inputRefs[activeIndex].current
          ?.getElementsByTagName("input")[0]
          .focus();
      }
    };

    if (attempt.length === 4) checkPasscode();
    else setState("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attempt]);

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.value) {
      setAttempt(attempt + evt.target.value);
      setActiveIndex(activeIndex < 3 ? activeIndex + 1 : 3);
    }
  };

  const handleKeyDown = (evt: React.KeyboardEvent<HTMLInputElement>) => {
    if (evt.key === "Backspace") {
      if (attempt.length === 4) {
        setAttempt(attempt.slice(0, 3));
        setActiveIndex(3);
      } else {
        setAttempt(
          attempt.slice(0, activeIndex - 1) + attempt.slice(activeIndex)
        );
        setActiveIndex(activeIndex > 0 ? activeIndex - 1 : 0);
      }
    }
  };

  return (
    <Stack spacing={4} className="flex justify-center items-center h-full p-9">
      <Typography variant="h4" className="text-center">
        Community passcode?
      </Typography>
      <Stack direction="row" spacing={2}>
        {inputRefs.map((_, index) => (
          <InputBase
            key={index}
            ref={inputRefs[index]}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            inputProps={{
              maxLength: 1,
              inputMode: "numeric",
              className: "text-center",
            }}
            className="font-extrabold text-[40px] leading-[48px] border-0 border-b-2 border-solid border-light-grey"
          />
        ))}
      </Stack>
      <div className="h-5">
        {state === "checking" && (
          <CircularProgress color="lightGrey" className="h-7 w-7" />
        )}
        {state === "error" && (
          <Typography variant="body1" color="#D32F2F">
            Wrong passcode
          </Typography>
        )}
      </div>
    </Stack>
  );
};

export default PasscodeChallenge;
