import React from "react";
import { Wrapper, Status } from "@googlemaps/react-wrapper";

import MapMarker from "assets/map_marker.svg";
import MapMarkerActive from "assets/map_marker_active.svg";
import UserMarker from "assets/user_marker.svg";
import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Business } from "data/models/business";
import { Community } from "data/models/community";

interface IGoogleMapProps {
  community: Community;
  businesses: Business[];
  activeIndex?: number;
  setActiveIndex: (index?: number) => void;
  mapCenter: LatLngLocation;
  setMapCenter: (location: LatLngLocation) => void;
}

const GoogleMap = (props: IGoogleMapProps) => {
  const { businesses, activeIndex, mapCenter } = props;

  const { mapSorting, setMapSorting } = useCommunityState();

  const [map, setMap] = React.useState<google.maps.Map>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [markers, setMarkers] = React.useState<{
    [businessId: string]: google.maps.marker.AdvancedMarkerElement;
  }>({});

  const ref = React.useRef<HTMLDivElement>(null);

  const communityId = props.community.urlName;

  const createMarker = (map: google.maps.Map, business: Business) => {
    const mapMarkerImg = document.createElement("img");
    mapMarkerImg.src = MapMarker;

    const businessLatLng = business.latlng;
    const marker = new google.maps.marker.AdvancedMarkerElement({
      map,
      position: businessLatLng,
      title: business.name,
      content: mapMarkerImg,
    });

    marker.addListener("click", () => {
      if (!businessLatLng) return;
      customEvent("com_dp_map_pin_item", { communityId });
      setMapSorting({ ...businessLatLng, updateMap: true });
    });
    return marker;
  };

  React.useEffect(() => {
    if (map || !ref.current) return;

    const mapOptions = {
      disableDefaultUI: true,
      zoom: 9,
      center: mapCenter,
      gestureHandling: "greedy",
      clickableIcons: false,
      mapId: process.env.REACT_APP_GOOGLE_MAPS_ID || "",
    };
    const googleMap = new window.google.maps.Map(ref.current, mapOptions);

    googleMap.addListener("dragstart", () => props.setActiveIndex(undefined));
    setMap(googleMap);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!!mapSorting) props.setActiveIndex(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapSorting]);

  React.useEffect(() => {
    if (!map) return;

    Object.entries(markers).forEach(([businessId, marker]) => {
      if (businesses.find((b) => b.id === businessId)) return;
      marker.map = null;
    });
    businesses.forEach((b) => {
      if (b.id in markers) markers[b.id].map = map;
      else markers[b.id] = createMarker(map, b);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, businesses]);

  React.useEffect(() => {
    if (mapCenter.isUserLocation) {
      const userMarkerImg = document.createElement("img");
      userMarkerImg.src = UserMarker;

      const marker = { map, position: mapCenter, content: userMarkerImg };
      new google.maps.marker.AdvancedMarkerElement(marker);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCenter]);

  React.useEffect(() => {
    if (!map || businesses.length === 0 || activeIndex === undefined) return;

    const business = businesses[activeIndex];

    if (!!business.latlng) props.setMapCenter(business.latlng);

    const updateMarker = (businessId: string, isActive: boolean) => {
      const mapMarkerImg = document.createElement("img");
      mapMarkerImg.src = isActive ? MapMarkerActive : MapMarker;
      markers[businessId].content = mapMarkerImg;
      markers[businessId].zIndex = isActive ? 10 : 1;
    };

    updateMarker(business.id, true);
    return () => updateMarker(business.id, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIndex, businesses]);

  React.useEffect(() => {
    if (!map) return;

    const zoom = map.getZoom() || 9;
    if (zoom < 12) map.setZoom(12);
    map.panTo(mapCenter);
    map.panBy(0, 50);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapCenter]);

  return <div ref={ref} id="google-map" className="w-full h-full" />;
};

const CommunityMap = (props: IGoogleMapProps) => {
  const render = (status: Status) => {
    if (status === Status.LOADING) return <div>Loading...</div>;
    if (status === Status.FAILURE) return <div>Something went wrong!</div>;
    return <GoogleMap {...props} />;
  };

  return (
    <Wrapper
      libraries={["marker"]}
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY || ""}
      render={render}
    />
  );
};

export default CommunityMap;
