import React from "react";
import { useLocation } from "react-router-dom";

import { Button, Stack, Typography } from "@mui/material";
import { EmailOutlined } from "@mui/icons-material";

import Constants from "configs/Constants";
import { socialSignIn } from "services/SupabaseAuth";

import Disclaimer from "./Disclaimer";
import { useData } from "providers/AuthProvider";
import { isWebView } from "utils/UserAgent";
import { customEvent } from "utils/AnalyticsEvent";

const SocialSignIn = ({
  label,
  handleClose,
  handleEmail,
}: {
  label: string;
  handleClose: () => void;
  handleEmail: () => void;
}) => {
  const location = useLocation();
  const { setScreen } = useData();

  React.useEffect(() => {
    customEvent("oliv_auth_bs_page_view", {});
  }, []);

  const handleClick = (label: string) => {
    if (label === "Google") {
      customEvent("oliv_auth_bs_google", {});
      if (isWebView()) setScreen("webview");
      else socialSignIn("google", location.pathname);
      handleClose();
    } else if (label === "Facebook") {
      handleClose();
    } else {
      customEvent("oliv_auth_bs_email", {});
      handleEmail();
    }
  };

  const loginOptions = [
    {
      label: "Google",
      icon: (
        <img src={Constants.GOOGLE_LOGO} alt="Google" className="w-[20px]" />
      ),
    },
    //{ label: "Facebook", logo: Constants.GOOGLE_LOGO },
    { label: "Email", icon: <EmailOutlined /> },
  ];

  return (
    <Stack spacing={2} className="text-center">
      <Typography variant="body1ExtraBold" className="pb-2">
        {label}
      </Typography>
      {loginOptions.map((option) => (
        <Button
          key={option.label}
          variant="outlined"
          color="filterGrey"
          startIcon={option.icon}
          onClick={() => handleClick(option.label)}
          className="justify-start shadow-md [&>.MuiButton-startIcon]:text-light-green"
        >
          <Typography
            variant="body1ExtraBold"
            color="lightGreen.main"
            className="ml-2"
          >
            Continue with {option.label}
          </Typography>
        </Button>
      ))}
      <Disclaimer />
    </Stack>
  );
};

export default SocialSignIn;
