import { Button, Typography } from "@mui/material";
import { GroupAddOutlined } from "@mui/icons-material";

import Constants from "configs/Constants";
import { customEvent } from "utils/AnalyticsEvent";

const CommunityRequest = () => {
  const handleClick = () => {
    customEvent("join_com_search_button_request", {});
    window.open(Constants.REQUEST_COMMUNITY_FORM, "_blank");
  };

  return (
    <div className="flex flex-col items-center p-4">
      <Typography variant="body2" className="text-center pb-4">
        Have suggestions regarding which communities we should add on Oliv?
      </Typography>
      <Button
        variant="contained"
        color="buttonGrey"
        size="small"
        startIcon={<GroupAddOutlined />}
        onClick={handleClick}
        className="px-5 py-2"
      >
        <Typography variant="body3Bold">Request a community</Typography>
      </Button>
    </div>
  );
};

export default CommunityRequest;
