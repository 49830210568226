import React from "react";

import Drawer from "@mui/material/Drawer";

import BottomSheetMenu from "./variants/Menu";
import BottomSheetText from "./variants/Text";
import BottomSheetMultiSelect from "./variants/MultiSelect";

const BottomSheet = (props: IBottomSheetProps) => {
  const { variant, open, close } = props;

  const content = {
    text: <BottomSheetText {...props} />,
    menu: <BottomSheetMenu {...props} />,
    multiSelect: <BottomSheetMultiSelect {...props} />,
  }[variant];

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={close}
      PaperProps={{
        className: "rounded-t",
      }}
      sx={{ zIndex: 2000 }}
    >
      <div className="max-w-[650px] w-full mx-auto">{content}</div>
    </Drawer>
  );
};

export default BottomSheet;
