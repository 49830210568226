import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";

import BusinessDetail from "pages/BusinessDetail";
import BusinessSearch from "pages/BusinessSearch";
import BusinessReview from "pages/BusinessReview";
import CommuntiyJoin from "pages/CommunityJoin";
import CommunityDetail from "pages/CommunityDetail";
import CommunityDiscover from "pages/CommunityDiscover";
import CommunityProfile from "pages/CommunityProfile";
import DataProvider from "./providers/DataProvider";
import Home from "pages/Home";
import PrivacyPolicy from "pages/PrivacyPolicy";
import TermsOfUse from "pages/TermsOfUse";
import NotFound from "pages/NotFound";
import InternalError from "pages/InternalError";
import MuiProvider from "./providers/MuiProvider";
import VerifyEmail from "pages/VerifyEmail";
import { AuthProvider } from "./providers/AuthProvider";
import { Paths } from "configs/Paths";

import ScrollToTop from "components/ScrollToTop";
import PageviewTracker from "components/PageviewTracker";
import ResetPassword from "pages/ResetPassword";

const App = (props: AppProps) => {
  return (
    <MuiProvider>
      <Router>
        <DataProvider>
          <AuthProvider>
            <PageviewTracker />
            <ScrollToTop />
            <Routes>
              <Route index element={<Home />} />
              <Route path={Paths.discover} element={<CommunityDiscover />} />

              <Route path={Paths.community} element={<CommunityDetail />}>
                <Route path="b" element={<BusinessSearch />} />
                <Route path="b/:businessId" element={<BusinessDetail />}>
                  <Route path="review" element={<BusinessReview />} />
                </Route>
                <Route path="join" element={<CommuntiyJoin />} />
                <Route path="profile" element={<CommunityProfile />} />
              </Route>

              <Route path={Paths.terms_of_use} element={<TermsOfUse />} />
              <Route path={Paths.privacy_policy} element={<PrivacyPolicy />} />
              <Route path={Paths.error500} element={<InternalError />} />
              <Route path={Paths.error404} element={<NotFound />} />

              <Route path={Paths.verify_email} element={<VerifyEmail />} />
              <Route path={Paths.reset_password} element={<ResetPassword />} />

              <Route
                path={Paths.app_terms_of_use}
                element={<TermsOfUse hideCta={true} />}
              />
              <Route
                path={Paths.app_privacy_policy}
                element={<PrivacyPolicy hideCta={true} />}
              />

              <Route path="*" element={<NotFound />} />
            </Routes>
          </AuthProvider>
        </DataProvider>
      </Router>
    </MuiProvider>
  );
};

interface AppProps {}

export default App;
