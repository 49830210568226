import React from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";

import { Box, Stack, Typography } from "@mui/material";

import CommunityCardActions from "./CommunityCardActions";
import CommunityCardStarComment from "./CommunityCardStarComment";
import DefaultRestaurant from "assets/defaultRestaurant.png";
import { getCommunityBusinessUrl } from "configs/Paths";
import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";
import { useStarBusinessReviews } from "data/hooks/businessReviews";
import { Business } from "data/models/business";
import { OlivImage } from "data/models/olivImage";
import { Review } from "data/models/review";

const CommunityListCard = ({
  community,
  business,
  index,
}: {
  community: Community;
  business: Business;
  index: number;
}) => {
  const navigate = useNavigate();

  const { listSorting } = useCommunityState();

  const communityId = community.urlName;

  const handleClick = (evt: React.MouseEvent) => {
    customEvent("com_dp_box_list_item", { communityId, index });
    navigate(getCommunityBusinessUrl(communityId, business.id), {
      state: { from: window.location.pathname },
    });
  };

  const handleImageChange = (swipeIndex: number) => {
    customEvent("com_dp_image_swipe_business", { communityId, swipeIndex });
  };

  return (
    <Stack spacing={1}>
      <Box onClick={handleClick} className="relative overflow-hidden">
        <Swiper
          pagination={{ dynamicBullets: true }}
          modules={[Pagination, Virtual]}
          className="relative w-full aspect-16/9 rounded-lg"
          onSlideChange={(swiper) => handleImageChange(swiper.activeIndex)}
          virtual={{ addSlidesAfter: 1, addSlidesBefore: 1 }}
        >
          {business.sliderImages.map((i: OlivImage, index: number) => (
            <SwiperSlide key={i.url} virtualIndex={index}>
              <img
                src={i.imageUrl}
                alt={business?.name}
                onError={(e: any) => {
                  e.target.onerror = null;
                  e.target.src = DefaultRestaurant;
                }}
                className="object-cover w-full aspect-16/9"
              />
              <div className="absolute bottom-0 w-full h-14 bg-gradient-to-t from-black/40" />
            </SwiperSlide>
          ))}
        </Swiper>
        {listSorting === "Popular" && index < 10 && !community?.starName && (
          <Typography
            variant="h1"
            color="white.main"
            className={clsx("absolute bottom-0 left-3 z-30 text-shadow-sm", {
              "text-[120px] leading-[80px]": index < 9,
              "text-[80px] leading-[53px]": index >= 9,
            })}
          >
            {index + 1}
          </Typography>
        )}
      </Box>
      <div className="flex items-center justify-between pt-1">
        <Stack spacing={0.5} onClick={handleClick} sx={{ flex: 1 }}>
          <Typography variant="h6">{business.name}</Typography>
          <Typography variant="body2" color="lightGreen.main">
            {business.city}
            {business.price && (
              <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;{business.price}</>
            )}
          </Typography>
        </Stack>
      </div>
      {community.joined && !!community.starName && (
        <React.Suspense>
          <CommunityCardStarComment
            community={community}
            business={business}
            handleClick={handleClick}
          />
        </React.Suspense>
      )}
      <CommunityCardActions
        community={community}
        business={business}
        index={index}
      />
    </Stack>
  );
};

export default CommunityListCard;
