import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import logo from "assets/logo.svg";
import { Paths, getCommunityUrl } from "configs/Paths";
import { useCommunity } from "data/hooks/communities";
import { customEvent } from "utils/AnalyticsEvent";

const CommunityInfo = ({
  communityId,
  showLogo,
  hideCta,
}: {
  communityId?: string;
  showLogo?: boolean;
  hideCta?: boolean;
}) => {
  const navigate = useNavigate();
  const community = useCommunity(communityId);

  const handleClick = () => {
    if (hideCta) return;

    if (!communityId || showLogo) {
      customEvent("top_nav_logo", {});
      navigate(Paths.main);
    } else {
      customEvent("top_nav_com_name", { communityId });
      window.scroll({ top: 0, behavior: "auto" });
      navigate(getCommunityUrl(communityId));
    }
  };

  if (!community || showLogo) {
    return (
      <img src={logo} alt="oliv" onClick={handleClick} className="h-[24px]" />
    );
  }

  return (
    <div className="flex items-center w-full" onClick={handleClick}>
      <img
        src={community.coverImgUrl}
        alt={community.name}
        className="rounded-full h-[28px] mr-2 aspect-square border border-solid border-light-grey"
      />
      <Typography variant="body2ExtraBold" className="truncate">
        {community.name}
      </Typography>
    </div>
  );
};

export default CommunityInfo;
