import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { Stack, Typography } from "@mui/material";

import CommentCardDeleteAction from "./CommentCardDeleteAction";
import CommentCardHelpfulAction from "./CommentCardHelpfulAction";
import CommentCardImages from "./CommentCardImages";
import ProfileAvatar from "components/ProfileAvatar";
import { Review } from "data/models/review";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const getDate = (createdAt?: string) => {
  if (!createdAt) return "";
  const date = new Date(createdAt);
  return date
    .toLocaleDateString("en-US", {
      month: "numeric",
      day: "numeric",
      year: "numeric",
    })
    .replace(/\//g, ".");
};

const CommentCard = ({
  community,
  business,
  review,
}: {
  community: Community;
  business: Business;
  review: Review;
}) => {
  const profile = review.member;

  return (
    <Stack spacing={1}>
      <div className="flex justify-between items-center">
        <div className="flex items-center">
          <>
            <ProfileAvatar profile={profile} size={28} />
            <Typography variant="body2Bold" className="ml-3">
              {profile.profileName}
            </Typography>
          </>
          <Typography variant="body4" color="">
            &nbsp;&nbsp;&bull;&nbsp;&nbsp;
            {getDate(review.createdAt.toISOString())}
          </Typography>
        </div>
        {review.isMine && (
          <CommentCardDeleteAction
            community={community}
            business={business}
            review={review}
          />
        )}
      </div>
      <Typography component="div" variant="body2">
        <ReactMarkdown>{review.comment || ""}</ReactMarkdown>
      </Typography>
      <CommentCardImages review={review} />
      <CommentCardHelpfulAction
        community={community}
        business={business}
        review={review}
      />
    </Stack>
  );
};

export default CommentCard;
