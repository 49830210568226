import { Divider, Typography } from "@mui/material";

import CommentCard from "./CommentCard";
import { Business } from "data/models/business";
import { Community } from "data/models/community";
import { Review } from "data/models/review";
import { useBusinessReviews } from "data/hooks/businessReviews";

const CommentCardsEmpty = () => {
  return (
    <>
      <Typography variant="body2" color="lightGreen.main" className="px-4 py-2">
        No reviews yet. Be the first to write review for this community!
      </Typography>
      <Divider />
    </>
  );
};

const CommentCards = ({
  community,
  business,
}: {
  community: Community;
  business: Business;
}) => {
  const reviews = useBusinessReviews(community, business);

  const nonStarReviews = reviews.filter((review) => !review.isModerator);

  if (nonStarReviews.length === 0) return <CommentCardsEmpty />;

  nonStarReviews.sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

  return (
    <>
      {nonStarReviews.map((review: Review, i: number) => (
        <div key={review.id}>
          <div className="px-4 py-2">
            <CommentCard
              community={community}
              business={business}
              review={review}
            />
          </div>
          <Divider />
        </div>
      ))}
    </>
  );
};

export default CommentCards;
