import { Drawer, Stack, Typography } from "@mui/material";

import ProfileAvatar from "components/ProfileAvatar";
import { useReviewReactions } from "data/hooks/reviewReactions";
import { Member } from "data/models/member";
import { Review } from "data/models/review";
import React from "react";

const CommentCardHelpfulMembers = ({
  review,
  open,
  setOpen,
}: {
  review: Review;
  open: boolean;
  setOpen: (open: boolean) => void;
}) => {
  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={() => setOpen(false)}
      PaperProps={{
        className: "rounded-t",
      }}
      className="z-[2000]"
    >
      <div className="max-w-[650px] w-full mx-auto">
        <React.Suspense fallback={<></>}>
          <MemberCards review={review} />
        </React.Suspense>
      </div>
    </Drawer>
  );
};

const MemberCards = ({ review }: { review: Review }) => {
  const reactions = useReviewReactions(review);

  const getLabel = () => {
    if (reactions.length === 1) return "1 member finds this review helpful";
    return `${reactions.length} members find this review helpful`;
  };

  return (
    <div className="p-6 pb-0 overflow-hidden w-full">
      <Typography variant="h5" className="pb-6">
        {getLabel()}
      </Typography>
      <Stack spacing={2} className="pb-6 overflow-y-scroll h-[50vh]">
        {reactions.map((r) => (
          <MemberCard key={r.id} profile={r.member} />
        ))}
      </Stack>
    </div>
  );
};

const MemberCard = ({ profile }: { profile: Member }) => {
  return (
    <Stack spacing={2} direction="row" className="items-center">
      <ProfileAvatar profile={profile} size={50} />
      <Typography variant="body1">{profile.profileName}</Typography>
    </Stack>
  );
};

export default CommentCardHelpfulMembers;
