import { Button, Stack, Typography } from "@mui/material";
import {
  ExploreOutlined,
  FeedbackOutlined,
  InfoOutlined,
  LogoutOutlined,
  PlaylistAddCheckRounded,
} from "@mui/icons-material";

import Constants from "configs/Constants";
import { Paths } from "configs/Paths";
import { signOutUser } from "services/SupabaseAuth";
import { useData } from "providers/AuthProvider";
import { customEvent } from "utils/AnalyticsEvent";

const NavMenuSection = ({
  handleMenuClick,
}: {
  handleMenuClick: (path?: string) => void;
}) => {
  const { user } = useData();

  const bottomMenu = [
    {
      label: "Discover communities",
      handleClick: () => {
        customEvent("drawer_button_join_com", {});
        handleMenuClick(Paths.discover);
      },
      icon: <ExploreOutlined />,
    },
    {
      label: "About Oliv",
      handleClick: () => {
        customEvent("drawer_button_about_oliv", {});
        window.open(Constants.ABOUT_DECK, "_blank");
      },
      icon: <InfoOutlined />,
    },
    {
      label: "Send us feedback",
      handleClick: () => {
        customEvent("drawer_button_feedback", {});
        window.open(Constants.FEEDBACK_FORM, "_blank");
      },
      icon: <FeedbackOutlined />,
    },
    {
      label: "Terms of Use",
      handleClick: () => {
        customEvent("drawer_button_tou", {});
        handleMenuClick(Paths.terms_of_use);
      },
      icon: <PlaylistAddCheckRounded />,
    },
  ];

  if (user != null) {
    bottomMenu.push({
      label: "Sign out",
      handleClick: () => {
        customEvent("drawer_button_logout", {});
        handleMenuClick();
        signOutUser();
      },
      icon: <LogoutOutlined />,
    });
  }

  return (
    <Stack spacing={0} sx={{ py: "8px" }}>
      {bottomMenu.map((item, i) => (
        <Button
          key={i}
          variant="text"
          color="black"
          onClick={item.handleClick}
          sx={{
            justifyContent: "start",
            borderRadius: "0px",
            height: "54px",
            px: "16px",
          }}
        >
          <Typography
            variant="body1Medium"
            sx={{ display: "flex", alignItems: "center" }}
          >
            {item.icon}
          </Typography>
          <Typography variant="body1Medium" sx={{ ml: "12px" }}>
            {item.label}
          </Typography>
        </Button>
      ))}
    </Stack>
  );
};

export default NavMenuSection;
