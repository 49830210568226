import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import { Button, Snackbar, Stack, Typography } from "@mui/material";
import {
  BookmarkBorderOutlined,
  BookmarkOutlined,
  FavoriteBorderOutlined,
  FavoriteOutlined,
  InfoOutlined,
  ShareOutlined,
} from "@mui/icons-material";

import CommunityJoinModal from "components/CommunityJoinModal";
import { customEvent } from "utils/AnalyticsEvent";
import { Business } from "data/models/business";
import { Community } from "data/models/community";
import {
  useBookmark,
  useRecommend,
  useUnbookmark,
  useUnrecommend,
} from "data/hooks/business";

const BusinessHeaderAction = ({
  icon,
  activeIcon,
  label,
  isActive,
  handleClick,
}: {
  icon: React.ReactNode;
  activeIcon?: React.ReactNode;
  label?: string;
  isActive: boolean;
  handleClick: () => void;
}) => {
  const iconClasses = clsx({
    "m-0": !label,
    "text-primary": isActive,
  });

  return (
    <Button
      variant="contained"
      size="large"
      color="buttonGrey"
      startIcon={isActive ? activeIcon : icon}
      className={clsx("min-w-0 rounded-full", {
        "px-[14px]": !label,
        "px-[21px]": label,
      })}
      classes={{ startIcon: iconClasses }}
      onClick={handleClick}
    >
      <Typography variant="body2Bold" color={isActive ? "primary" : "inherit"}>
        {label}
      </Typography>
    </Button>
  );
};

const BusinessHeaderActions = ({
  community,
  business,
  scrollToInfoRef,
}: {
  community: Community;
  business: Business;
  scrollToInfoRef: () => void;
}) => {
  const location = useLocation();

  const recommend = useRecommend(community, business);
  const unrecommend = useUnrecommend(community, business);
  const bookmark = useBookmark(community, business);
  const unbookmark = useUnbookmark(community, business);

  const [modalOpen, setModalOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const communityId = community.id;
  const businessId = business.id;

  const handleLike = () => {
    if (!community.joined) {
      setModalOpen(true);
    } else {
      if (business.recommended) {
        customEvent("icon_unrecommend", {
          communityId,
          businessId,
          location: "business",
        });
        unrecommend.mutate();
      } else {
        customEvent("icon_recommend", {
          communityId,
          businessId,
          location: "business",
        });
        recommend.mutate();
      }
    }
  };

  const handleBookmark = () => {
    if (!community.joined) {
      setModalOpen(true);
    } else {
      if (business.bookmarked) {
        customEvent("bus_dp_icon_unsave", { communityId, businessId });
        unbookmark.mutate();
      } else {
        customEvent("bus_dp_icon_save", { communityId, businessId });
        bookmark.mutate();
      }
    }
  };

  const handleInfo = () => {
    customEvent("bus_dp_icon_info", { communityId, businessId });
    scrollToInfoRef();
  };

  const handleShare = () => {
    customEvent("bus_dp_icon_share", { communityId, businessId });
    if (!navigator.share) {
      navigator.clipboard.writeText(window.location.href);
      setSnackbarOpen(true);
    } else {
      navigator.share({ url: location.pathname });
    }
  };

  return (
    <>
      <Stack direction="row" spacing={1}>
        <BusinessHeaderAction
          icon={<FavoriteBorderOutlined />}
          activeIcon={<FavoriteOutlined />}
          label={business.recommendationsCount.toString()}
          isActive={business.recommended}
          handleClick={handleLike}
        />
        <BusinessHeaderAction
          icon={<BookmarkBorderOutlined />}
          activeIcon={<BookmarkOutlined />}
          isActive={business.bookmarked}
          handleClick={handleBookmark}
        />
        <BusinessHeaderAction
          icon={<InfoOutlined />}
          isActive={false}
          handleClick={handleInfo}
        />
        <BusinessHeaderAction
          icon={<ShareOutlined />}
          isActive={false}
          handleClick={handleShare}
        />
      </Stack>
      <CommunityJoinModal
        community={community}
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
      />
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="URL copied to clipboard"
      />
    </>
  );
};

export default BusinessHeaderActions;
