import { useNavigate } from "react-router-dom";

import { Button, Stack, Typography } from "@mui/material";

import { getCommunityUrl } from "configs/Paths";
import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const BusinessHeaderFilters = ({
  community,
  business,
}: {
  community: Community;
  business: Business;
}) => {
  const navigate = useNavigate();

  const { setFilters, setMode } = useCommunityState();

  const handleClick = (tag: string) => {
    const [type, key, value] = tag.split("::");

    customEvent("bus_dp_filter_com_tag", {
      communityId: community.id,
      businessId: business.id,
      tag,
    });

    if (type === "toggle") {
      setFilters({ toggle: { [key]: true }, multi: {}, single: {} });
    } else if (type === "single") {
      setFilters({ toggle: {}, single: { [key]: value }, multi: {} });
    } else if (type === "multi") {
      setFilters({ toggle: {}, single: {}, multi: { [key]: [value] } });
    }

    window.scrollTo(0, 0);
    setMode("List");
    navigate(getCommunityUrl(community.urlName));
  };

  const getTagDisplayValue = (tag: string) => {
    if (tag.startsWith("toggle::")) return tag.split("::").pop();
    return tag.split("::").slice(2).join(": ");
  };

  return (
    <Stack
      direction="row"
      spacing={1}
      useFlexGap
      flexWrap="wrap"
      className="px-4"
    >
      {(business.tags || []).map((tag) => (
        <Button
          key={tag}
          variant="outlined"
          color="filterGrey"
          size="small"
          className="rounded-xl min-w-0 px-2 py-0"
          onClick={() => handleClick(tag)}
        >
          <Typography variant="body3" color="black.main">
            {getTagDisplayValue(tag)}
          </Typography>
        </Button>
      ))}
    </Stack>
  );
};

export default BusinessHeaderFilters;
