import React from "react";
import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperClass, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/virtual";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

import DefaultRestaurant from "assets/defaultRestaurant.png";
import { customEvent } from "utils/AnalyticsEvent";
import { Business } from "data/models/business";
import { OlivImage } from "data/models/olivImage";
import { Community } from "data/models/community";

const BusinessHeaderImages = ({
  community,
  business,
}: {
  community: Community;
  business: Business;
}) => {
  const businessId = business.id;

  const [swiper, setSwiper] = React.useState<SwiperClass>();
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const handleImageChange = (swipeIndex: number) => {
    setCurrentIndex(swipeIndex);
    customEvent("bus_dp_image_swipe_business", {
      communityId: community.id,
      businessId,
      swipeIndex,
    });
  };

  const handleLightboxSwipe = (index: number) => {
    swiper?.slideTo(index);
  };

  return (
    <>
      <Swiper
        pagination={{ dynamicBullets: true }}
        modules={[Pagination, Virtual]}
        className="relative w-full aspect-5/4"
        virtual={{ addSlidesAfter: 1, addSlidesBefore: 1 }}
        onSlideChange={(swiper) => handleImageChange(swiper.activeIndex)}
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        {business.sliderImages.map((i: OlivImage, index: number) => (
          <SwiperSlide key={i.url} virtualIndex={index}>
            <img
              src={i.url}
              alt={business?.name}
              onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = DefaultRestaurant;
              }}
              onClick={() => setIsOpen(true)}
              className="object-cover w-full aspect-5/4"
            />
            <div className="absolute bottom-0 w-full h-14 bg-gradient-to-t from-black/40" />
          </SwiperSlide>
        ))}
      </Swiper>
      <Lightbox
        open={isOpen}
        index={currentIndex}
        close={() => setIsOpen(false)}
        on={{
          view: ({ index: i }) => handleLightboxSwipe(i),
        }}
        carousel={{
          padding: 0,
        }}
        slides={business.sliderImages.map((i: OlivImage) => ({
          src: i.url!,
          imageFit: "cover",
        }))}
        styles={{ container: { backgroundColor: "rgba(0, 0, 0, .9)" } }}
      />
    </>
  );
};

export default BusinessHeaderImages;
