import React from "react";

import { AppBar, Toolbar } from "@mui/material";

import CommunityInfo from "components/OlivAppBar/CommunityInfo";

import AppbarLeftAction from "./AppbarLeftAction";
import { useData } from "providers/AuthProvider";
import AppbarRightActionMain from "./AppbarRightActionMain";
import AppbarRightActionCommunity from "components/OlivAppBar/AppbarRightActionCommunity";

const OlivAppBar = ({
  communityId,
  businessId,
  showClose,
  showLogo,
  showLogin,
  hideProfile,
  hideCta,
}: {
  communityId?: string;
  businessId?: string;
  showClose?: string;
  showLogo?: boolean;
  showLogin?: boolean;
  hideProfile?: boolean;
  hideCta?: boolean;
}) => {
  const { user } = useData();

  return (
    <>
      <AppBar
        color="white"
        elevation={0}
        className="border-0 border-b border-solid border-light-grey rounded-none"
      >
        <Toolbar className="w-full m-auto">
          <div className="flex-1">
            <AppbarLeftAction
              showClose={showClose}
              communityId={communityId}
              businessId={businessId}
              hideCta={hideCta}
            />
          </div>
          <div className="flex overflow-hidden mx-2">
            <React.Suspense fallback={<div />}>
              <CommunityInfo
                communityId={communityId}
                showLogo={showLogo}
                hideCta={hideCta}
              />
            </React.Suspense>
          </div>
          <div className="flex flex-1 justify-end">
            {user === undefined || hideCta ? (
              <></>
            ) : user === null ? (
              <AppbarRightActionMain
                communityId={communityId}
                showLogin={showLogin}
              />
            ) : communityId ? (
              <React.Suspense fallback={<div />}>
                <AppbarRightActionCommunity
                  communityId={communityId}
                  hideProfile={hideProfile}
                  showLogin={showLogin}
                />
              </React.Suspense>
            ) : (
              <></>
            )}
          </div>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </>
  );
};

export default OlivAppBar;
