import React from "react";

import { Avatar } from "@mui/material";

import { Member } from "data/models/member";

const ProfileAvatar = ({
  profile,
  size,
  handleClick,
}: {
  profile: Member;
  size: number;
  handleClick?: () => void;
}) => {
  return (
    <Avatar
      src={profile.profileImgUrl || "na"}
      alt={profile.profileName.toUpperCase()}
      onClick={handleClick}
      style={{
        backgroundColor: profile.profileColor,
        width: size,
        height: size,
        fontSize: size / 2,
      }}
    />
  );
};

export default ProfileAvatar;
