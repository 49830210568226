import DefaultRestaurant from "assets/defaultRestaurant.png";
import { Business } from "data/models/business";

export const getImages = (business: IBusiness) => {
  if (!business.images || business.images.length === 0) {
    return [{ url: DefaultRestaurant, type: "FALLBACK" }];
  }
  return business.images;
};

export const getCoverImg = (business?: IBusiness) => {
  if (!business) return;

  const images = getImages(business);
  const coverImg = images[0] || { url: DefaultRestaurant, type: "FALLBACK" };
  return coverImg.url;
};

export const getDistance = (
  business: Business,
  targetLocation: LatLngLocation
) => {
  if (!business.latitude || !business.longitude) return undefined;

  const { latitude: lat, longitude: lng } = business;
  if (targetLocation.lat === lat && targetLocation.lng === lng) return 0;

  const R = 3958.8;
  const dLat = (lat - targetLocation.lat) * (Math.PI / 180);
  const dLon = (lng - targetLocation.lng) * (Math.PI / 180);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(targetLocation.lat * (Math.PI / 180)) *
      Math.cos(lat * (Math.PI / 180)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  return Math.round(R * c * 10) / 10;
};

const checkFilter = (filter: string[], value?: string) => {
  if (!filter || filter.length === 0) return true;
  if (!value) return false;
  return filter.includes(value);
};

export const filterBusinesses = (
  businesses: Business[],
  filters: CommunityFilters,
  sorting: CommunitySorting
) => {
  const sortByMostRecommended = (a: Business, b: Business) =>
    b.recommendationsCount - a.recommendationsCount;

  const sortByDistance = (a: Business, b: Business) => {
    if (b.distance === undefined) return -1;
    if (a.distance === undefined) return 1;
    return a.distance <= b.distance ? -1 : 1;
  };

  const isLatLngLocation = (
    sorting: CommunitySorting
  ): sorting is LatLngLocation => {
    return typeof sorting === "object";
  };

  if (isLatLngLocation(sorting)) {
    businesses.forEach((b) => {
      b.distance = getDistance(b, sorting);
    });
  }

  return businesses
    .filter((business) => {
      const toggle = Object.entries(filters.toggle).every(([key, value]) => {
        if (key === "openNow" && value) {
          if (!business.isOpen) return false;
        } else {
          const target = `toggle::${key}`;
          if (value && !business.tags?.includes(target)) return false;
        }
        return true;
      });
      if (!toggle) return false;

      const single = Object.entries(filters.single).every(([key, value]) => {
        if (key === "activities") {
          if (value === "Your Recommendations") {
            if (!business.recommended) return false;
          }
          if (value === "Your Reviews") {
            if (!business.reviewed) return false;
          }
          if (value === "Your Saved Places") {
            if (!business.bookmarked) return false;
          }
        } else {
          const target = `single::${key}::${value}`;
          if (value && !business.tags?.some((tag) => tag.startsWith(target)))
            return false;
        }
        return true;
      });
      if (!single) return false;

      const multi = Object.entries(filters.multi).every(([key, values]) => {
        if (key === "cuisines") {
          const cuisine = business.cuisine;
          if (!checkFilter(filters.multi.cuisines, cuisine)) return false;
        } else if (key === "prices") {
          const price = business.price;
          if (!checkFilter(filters.multi.prices, price)) return false;
        } else {
          if (!values || values.length === 0) return true;
          const checkFilter = values.some((value) => {
            const target = `multi::${key}::${value}`;
            if (value && !business.tags?.some((tag) => tag.startsWith(target)))
              return false;
            return true;
          });
          if (!checkFilter) return false;
        }
        return true;
      });
      if (!multi) return false;

      return true;
    })
    .sort((a, b) => {
      if (isLatLngLocation(sorting)) return sortByDistance(a, b);
      if (sorting === "Popular") return sortByMostRecommended(a, b);
      return 0;
    });
};

export const getAllCuisines = (businesses: Business[]) => {
  return Object.entries(
    businesses.reduce((acc, business) => {
      if (!business.cuisine) return acc;
      if (business.cuisine in acc) {
        acc[business.cuisine] += 1;
      } else {
        acc[business.cuisine] = 1;
      }
      return acc;
    }, {} as { [cuisine: string]: number })
  ).sort((a, b) => b[1] - a[1]);
};
