import { Grid, Skeleton } from "@mui/material";

export const HomeLoading = () => {
  const emptyArray = Array(8).fill(undefined);
  return (
    <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
      {emptyArray.map((_, i) => (
        <Grid item key={i} xs={6}>
          <Skeleton
            width="100%"
            height="100%"
            variant="rounded"
            className="rounded-xl aspect-square"
          />
        </Grid>
      ))}
    </Grid>
  );
};
