import clsx from "clsx";
import { useLocation } from "react-router-dom";

import { Box, Grid, Typography } from "@mui/material";

import { getCommunityUrl } from "configs/Paths";
import { useCommunities } from "data/hooks/communities";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const NavCommunitiesSection = ({
  handleMenuClick,
}: {
  handleMenuClick: (path: string) => void;
}) => {
  const communities = useCommunities();

  const memberCommunities = communities.filter((c) => c.joined);

  return (
    <div className="flex-1 overflow-scroll">
      {memberCommunities.map((community: Community, i: number) => (
        <CommunitySelector
          key={community.urlName || i}
          community={community}
          index={i}
          handleMenuClick={handleMenuClick}
        />
      ))}
      {memberCommunities.length === 0 && (
        <Typography variant="body2" color="lightGreen.main" sx={{ p: "16px" }}>
          Join your first community and get recommendations from people you
          trust!
        </Typography>
      )}
    </div>
  );
};

const CommunitySelector = ({
  community,
  index,
  handleMenuClick,
}: {
  community: Community;
  index: number;
  handleMenuClick: (path: string) => void;
}) => {
  const location = useLocation();

  const communityId = community.urlName;
  const isActive = location.pathname.includes(community.urlName);

  const handleClick = () => {
    customEvent("drawer_box_com", { communityId, index });
    handleMenuClick(getCommunityUrl(community.urlName));
  };

  const activeClassNames = clsx("p-2", {
    "border border-solid border-light-grey border-l-8 border-l-primary":
      isActive,
    "border border-solid border-transparent border-l-8": !isActive,
  });

  return (
    <Box className={activeClassNames}>
      <Grid
        container
        spacing={2}
        onClick={handleClick}
        className="items-center"
      >
        <Grid item xs={3}>
          <img
            src={community.coverImgUrl}
            alt={community.name}
            className="aspect-square w-full rounded-lg border border-solid border-light-grey"
          />
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1Bold">{community.name}</Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NavCommunitiesSection;
