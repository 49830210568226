import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import OlivAppBar from "components/OlivAppBar";

const TermsOfUse = ({ hideCta = false }) => {
  return (
    <>
      <OlivAppBar showClose="tou_close" hideCta={hideCta} />
      <Stack spacing={2} className="p-4">
        <Typography variant="h3">Oliv Terms of Use</Typography>
        <Typography variant="body2Bold">
          Last Updated: February 13, 2023
        </Typography>
        <Typography variant="body3">
          Welcome to Oliv. These Terms of Use ("Terms") govern your use of Oliv
          and the services and features offered through Oliv (collectively, the
          "Service"). Please read these Terms carefully. By accessing or using
          the Service, you signify that you have read, understood, and agree to
          be bound by these Terms. If you do not agree to these Terms, you may
          not use the Service.
        </Typography>
        <Typography variant="h6">1. Eligibility</Typography>
        <Typography variant="body3">
          The Service is intended for use by individuals who are at least 18
          years old. If you are under the age of 18, you may not use the
          Service. By using the Service, you represent and warrant that you are
          at least 18 years old and that you have the right, authority, and
          capacity to enter into and abide by these Terms.
        </Typography>
        <Typography variant="h6">2. Changes to Terms</Typography>
        <Typography variant="body3">
          We may modify these Terms at any time, and such modifications will be
          effective immediately upon posting of the modified Terms. You agree to
          review these Terms periodically to be aware of any modifications and
          your continued use of the Service signifies your acceptance of the
          modified Terms.
        </Typography>
        <Typography variant="h6">3. User Content</Typography>
        <Typography variant="body3">
          The Service allows you to submit, upload, publish, or otherwise make
          available content, including but not limited to text, photographs,
          videos, and audio (collectively, "User Content"). You retain all
          rights in and are solely responsible for your User Content. By making
          any User Content available through the Service, you grant to Oliv a
          non-exclusive, transferable, sub-licensable, royalty-free, worldwide
          license to use, copy, modify, create derivative works based on,
          distribute, publicly display, publicly perform, and otherwise exploit
          your User Content in connection with the Service and Oliv's (and its
          successors' and affiliates') business, including without limitation
          for promoting and redistributing part or all of the Service (and
          derivative works thereof) in any media formats and through any media
          channels. You also grant each user of the Service a non-exclusive
          license to access your User Content through the Service, and to use,
          copy, modify, create derivative works based on, distribute, publicly
          display, publicly perform, and otherwise exploit such User Content in
          connection with their use of the Service. The foregoing license
          granted by you terminates once you remove or delete your User Content
          from the Service.
        </Typography>
        <Typography variant="h6">4. Proprietary Rights</Typography>
        <Typography variant="body3">
          The Service and its entire contents, features, and functionality
          (including but not limited to all information, software, text,
          displays, images, video, and audio, and the design, selection, and
          arrangement thereof), are owned by Oliv, its licensors, or other
          providers of such material and are protected by United States and
          international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws. These Terms grant
          you a limited, non-exclusive, non-transferable license to access and
          use the Service. These rights are subject to the following
          restrictions: (a) you must not license, sell, rent, lease, transfer,
          assign, distribute, host, or otherwise commercially exploit the
          Service, whether in whole or in part, or any content displayed on the
          Service; (b) you must not modify, make derivative works of,
          disassemble, reverse compile or reverse engineer any part of the
          Service; (c) you must not access the Service in order to build a
          similar or competitive website, product, or service; and (d) except as
          expressly stated herein, no part of the Service may be copied,
          reproduced, distributed, republished, downloaded, displayed, posted,
          or transmitted in any form or by any means.
        </Typography>
        <Typography variant="h6">
          5. Restaurant Information Accuracy and Food Safety
        </Typography>
        <Typography variant="body3">
          While Oliv will make reasonable efforts to verify the accuracy of
          information related to food preparation and other content displayed on
          the Service, including adherence to certain dietary or religious
          requirements, we do not guarantee the safety of the food or its
          alignment with an individual's religious beliefs.
          <br />
          <br />
          1. <b>Accuracy Disclaimer:</b> Our platform provides information about
          restaurant food safety practices, including claims of being
          cross-contact safe, free from certain ingredients, and following
          specific food preparation protocols. We strive for accuracy but do not
          guarantee the complete accuracy or reliability of the information
          provided.
          <br />
          <br />
          2. <b>User Responsibility:</b> Users acknowledge that the information
          provided about restaurants' food safety practices is intended as a
          guideline and should not be solely relied upon. Users are encouraged
          to independently verify the accuracy of such information before making
          dining decisions.
          <br />
          <br />
          3. <b>Limitation of Liability:</b> Oliv shall not be held responsible
          for any adverse effects or violations of personal religious
          convictions resulting from the consumption of food obtained through
          the Service. Users agree that their use of this information is at
          their own risk, and our company shall not be responsible for any
          damages resulting from reliance on this information.
          <br />
          <br />
          4. <b>Third-Party Information:</b> Information about restaurant food
          safety practices may be sourced from third parties, and we do not
          guarantee the accuracy or reliability of such information. Users are
          encouraged to verify the validity of any third-party information.
          <br />
          <br />
          5. <b>Indemnification:</b> Users agree to indemnify and hold our
          company harmless from any claims, liabilities, damages, or expenses
          arising from or related to the use or reliance on restaurant
          information provided on our platform.
          <br />
          <br />
          6. <b>Modification of Information:</b> Our company reserves the right
          to modify or remove restaurant information on our platform at any time
          without notice.
          <br />
          <br />
          7. <b>Legal Compliance:</b> Users agree to comply with all applicable
          laws and regulations when using the restaurant information provided on
          our platform.
          <br />
          <br />
          By using our platform, users acknowledge and agree to abide by these
          Terms of Use concerning restaurant information accuracy and food
          safety
        </Typography>
      </Stack>
    </>
  );
};

export default TermsOfUse;
