import moment from "moment";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import ProfileAvatar from "components/ProfileAvatar";

import CommunityFeedReviewImages from "./CommunityFeedReviewImages";
import { getCommunityBusinessUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";
import { Feed } from "data/models/feed";
import { ImageType } from "data/models/olivImage";

const CommunityFeedReview = ({
  community,
  feed,
  index,
}: {
  community: Community;
  feed: Feed;
  index: number;
}) => {
  const navigate = useNavigate();
  const communityId = community.urlName;

  const handleClick = () => {
    customEvent("com_dp_feed_item_box", {
      communityId,
      index,
      type: "Review",
    });
    navigate(getCommunityBusinessUrl(communityId, feed.businessId));
  };

  return (
    <div onClick={handleClick}>
      <Stack spacing={2} direction="row" className="px-4">
        <img
          src={feed.businessImage}
          alt={feed.businessName}
          className="aspect-square object-cover rounded-lg border border-solid border-light-grey h-[80px]"
        />
        <Stack spacing={0.5}>
          <Typography variant="h6">{feed.businessName}</Typography>
          <div className="flex items-start">
            <ProfileAvatar profile={feed.member} size={24} />
            <Typography variant="body2" className="ml-[6px]">
              <b>{feed.profileName}</b> left a review
            </Typography>
          </div>
          <Typography
            variant="body3"
            color="darkGrey.main"
            className="pt-[2px]"
          >
            {moment(feed.createdAt).fromNow()}
          </Typography>
        </Stack>
      </Stack>
      <Stack spacing={1} className="pt-4 px-4">
        <Typography component="div" variant="body1" className="line-clamp-5">
          <ReactMarkdown>{feed.comment || ""}</ReactMarkdown>
        </Typography>
        {(feed.reviewImages || []).filter(
          (image) => image.type === ImageType.member
        ).length > 0 && (
          <div className="pt-4">
            <CommunityFeedReviewImages
              community={community}
              images={feed.reviewImages || []}
            />
          </div>
        )}
      </Stack>
    </div>
  );
};

export default CommunityFeedReview;
