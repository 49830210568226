import React from "react";

import { Button, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

import { isAndroid } from "utils/UserAgent";
import { customEvent } from "utils/AnalyticsEvent";
import Constants from "configs/Constants";

const StyledTypography = styled(Typography)({
  fontFamily: "Noto Sans",
});

const WebviewPage = () => {
  const url = window.location.hostname + window.location.pathname;

  React.useEffect(() => {
    if (isAndroid()) customEvent("webview_android_page_view", {});
    else customEvent("webview_ios_page_view", {});
  }, []);

  const handleOpenBrowser = () => {
    if (isAndroid()) customEvent("webview_android_button_system", {});
    window.open(`intent:${window.location.href}#Intent;end}`, "_blank");
  };

  const handleCopyUrl = () => {
    if (isAndroid()) customEvent("webview_android_button_copy", {});
    else customEvent("webview_ios_button_copy", {});
    navigator.clipboard.writeText(url);
  };

  const handleOpenGooglePolicy = () => {
    if (isAndroid()) customEvent("webview_android_link_google_restriction", {});
    else customEvent("webview_ios_link_google_restriction", {});
    window.open(Constants.GOOGLE_INAPP_POLICY, "_blank", "norefferer");
  };

  return (
    <div className="flex flex-col h-[100dvh] items-center">
      <div className="w-full border-solid border-light-grey border-0 border-b-[1px]">
        <div className="flex items-center p-4">
          <img src={Constants.GOOGLE_LOGO} alt="Google" className="mr-2" />
          <StyledTypography variant="body1" color="darkGrey.main">
            Sign in with Google
          </StyledTypography>
        </div>
      </div>
      <Stack spacing={3} sx={{ flex: 1, p: 3, mt: 5 }}>
        <StyledTypography variant="h4" className="font-medium">
          Google does not allow an in-app browser log-in
        </StyledTypography>
        <StyledTypography variant="body1" color="darkGrey.main">
          Please open in a system browser to continue.
        </StyledTypography>
        <Stack spacing={2} className="pt-4">
          {isAndroid() ? (
            <React.Fragment>
              <FallbackOptionBox
                primary
                label="Open in a system browser"
                handleClick={handleOpenBrowser}
              />
              <FallbackOptionBox
                label="Copy url to clipboard"
                handleClick={handleCopyUrl}
              />
            </React.Fragment>
          ) : (
            <FallbackOptionBox
              primary
              label="Copy url to clipboard"
              handleClick={handleCopyUrl}
            />
          )}
        </Stack>
      </Stack>
      <StyledTypography
        variant="body3"
        className="underline py-6"
        onClick={handleOpenGooglePolicy}
      >
        Learn about Google restriction
      </StyledTypography>
    </div>
  );
};

const FallbackOptionBox = ({
  primary,
  label,
  handleClick,
}: {
  primary?: boolean;
  label: string;
  handleClick: () => void;
}) => (
  <Button
    variant={primary ? "contained" : "outlined"}
    color={primary ? "googleBlue" : "darkGrey"}
    onClick={handleClick}
    className="w-4/5 rounded-[4px]"
  >
    <StyledTypography
      variant="body2"
      fontWeight={600}
      color={primary ? "white.main" : "googleBlue.main"}
    >
      {label}
    </StyledTypography>
  </Button>
);

export default WebviewPage;
