import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import { LockOutlined } from "@mui/icons-material";

import { getCommunityUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityMetadata = ({
  label,
  value,
  isLast,
}: {
  label: string;
  value: number;
  isLast?: boolean;
}) => (
  <Typography variant="body3" className="float-left">
    <b>{value}</b>&nbsp;
    {label}
    {!isLast && <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</>}
  </Typography>
);

const CommunityCard = ({
  community,
  index,
}: {
  community: Community;
  index: number;
}) => {
  const navigate = useNavigate();

  const communityId = community.urlName;

  const handleClick = () => {
    customEvent("join_com_box_com_row", { communityId, index });
    navigate(getCommunityUrl(community.urlName));
  };

  return (
    <div
      onClick={handleClick}
      className="overflow-hidden border border-solid border-light-grey rounded-xl pb-4"
    >
      <img
        src={community.bgImgUrl}
        alt={community.name}
        className="relative object-cover opacity-80 w-full aspect-5"
      />
      <img
        src={community.coverImgUrl}
        alt={community.name}
        className="relative aspect-square rounded-full border border-solid border-light-grey mt-[-12%] left-3 h-[70px]"
      />
      <Stack className="px-3 pt-1" spacing={1}>
        <div className="flex items-center">
          <Typography variant="body2ExtraBold" className="line-clamp-2">
            {community.name}
          </Typography>
          {community.isPrivate && (
            <LockOutlined className="text-dark-grey text-base ml-1" />
          )}
        </div>
        <div>
          <CommunityMetadata value={community.membersCount} label="members" />
          <CommunityMetadata value={community.businessesCount} label="places" />
          <CommunityMetadata
            value={community.recommendationsCount}
            label="recommendations"
            isLast
          />
        </div>
        <Typography variant="body3" className="line-clamp-2">
          <ReactMarkdown>{community.description}</ReactMarkdown>
        </Typography>
      </Stack>
    </div>
  );
};

export default CommunityCard;
