import { Expose, Type } from "class-transformer";
import { Member } from "./member";
import { ImageType, OlivImage } from "./olivImage";

export class Review {
  id!: number;
  @Expose({ name: "community_id" }) communityId!: number;
  @Expose({ name: "business_id" }) businessId!: string;
  @Expose({ name: "is_mine" }) isMine!: boolean;
  @Expose({ name: "helpful_count" }) helpfulCount!: number;
  @Expose({ name: "created_at" }) @Type(() => Date) createdAt!: Date;
  images!: string[];
  helpful!: boolean;
  @Expose({ name: "profile_name" }) authorName?: string;
  @Expose({ name: "profile_color" }) authorColor?: string;
  @Expose({ name: "is_moderator" }) isModerator?: boolean;
  badges?: string[];
  @Expose({ name: "profile_img_key" }) authorImgKey?: string;
  comment?: string;

  get member(): Member {
    return this.authorName
      ? new Member(
          this.authorName!,
          this.authorColor!,
          false,
          [],
          this.authorImgKey
        )
      : new Member("[deleted member]", "#979797", false, [], undefined);
  }

  get sliderImages(): OlivImage[] {
    return this.images.map(
      (image) => new OlivImage(ImageType.member, undefined, image)
    );
  }
}
