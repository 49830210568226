import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { Stack, Typography } from "@mui/material";

import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityHeaderOverflowInfo = ({
  community,
}: {
  community: Community;
}) => {
  const communityId = community.urlName;

  React.useEffect(() => {
    customEvent("com_dp_com_info_m_page_view", { communityId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Stack spacing={2} className="p-6">
      <Typography variant="h4Small">{community.name}</Typography>
      <Typography
        component="div"
        variant="body2"
        color="darkGreen.main"
        className="overflow-y-scroll max-h-[70dvh]"
      >
        <ReactMarkdown>{community.description}</ReactMarkdown>
      </Typography>
    </Stack>
  );
};

export default CommunityHeaderOverflowInfo;
