import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import qs from 'qs';

export const useQueryState = (query: string): [query: string, setQuery: (value: string) => void] => {
  const location = useLocation();
  const navigate = useNavigate();

  const setQuery = React.useCallback((value: string) => {
    const existingQueries = qs.parse(location.search, { ignoreQueryPrefix: true });
    const queryString = qs.stringify({ ...existingQueries, [query]: value }, { skipNulls: true });

    navigate(`${location.pathname}?${queryString}`, { replace: true });
  }, [navigate, location, query]);

  return [
    qs.parse(location.search, { ignoreQueryPrefix: true })[query] as string,
    setQuery
  ]
}