import React from 'react';

import Modal from '@mui/material/Modal';

import ModalBoxConfirmation from './variants/Confirmation';

const ModalBox = (props: IModalBoxProps) => {
  const { variant } = props;

  const modalRef = React.createRef();

  const modalContent = {
    confirmation: <ModalBoxConfirmation ref={modalRef} {...props} />,
  }[variant];

  return (
    <Modal 
      open={props.open} onClose={props.handleCancel} 
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      {modalContent}
    </Modal>
  );
};

export default ModalBox;