import { Expose } from "class-transformer";
import { supabase } from "configs/Supabase";

export class Member {
  @Expose({ name: "profile_name" }) profileName!: string;
  @Expose({ name: "profile_color" }) profileColor!: string;
  @Expose({ name: "is_moderator" }) isModerator: boolean = false;
  badges: string[] = [];
  @Expose({ name: "profile_img_key" }) profileImgKey?: string;
  profileImgPlaceholder?: string;

  constructor(
    profileName: string,
    profileColor: string,
    isModerator: boolean,
    badges: string[],
    profileImgKey?: string
  ) {
    this.profileName = profileName;
    this.profileColor = profileColor;
    this.isModerator = isModerator;
    this.badges = badges;
    this.profileImgKey = profileImgKey;
  }

  get profileImgUrl() {
    return this.profileImgKey != null
      ? supabase.storage.from("profiles").getPublicUrl(this.profileImgKey!).data
          .publicUrl
      : null;
  }
}
