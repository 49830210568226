import DefaultRestaurant from "assets/defaultRestaurant.png";
import { Exclude, Expose, Transform, Type } from "class-transformer";
import { OlivImage } from "./olivImage";
import { Member } from "./member";

export enum FeedType {
  review,
  recommendation,
  recommendations,
}

export class Feed {
  @Expose({ name: "community_id" }) communityId!: number;
  @Expose({ name: "business_id" }) businessId!: string;
  @Expose({ name: "business_name" }) businessName!: string;
  @Expose({ name: "business_images" })
  @Type(() => OlivImage)
  businessImages!: OlivImage[];
  @Transform(({ value }) => FeedType[value]) type!: FeedType;
  @Expose({ name: "created_at" }) @Type(() => Date) createdAt!: Date;
  @Expose({ name: "member_id" }) memberId?: number;
  @Expose({ name: "profile_name" }) profileName?: string;
  @Expose({ name: "profile_color" }) profileColor?: string;
  @Expose({ name: "profile_img_key" }) profileImgKey?: string;
  comment?: string;
  @Expose({ name: "review_images" })
  @Type(() => OlivImage)
  reviewImages?: OlivImage[];
  @Exclude() businesses?: Feed[];

  constructor(
    communityId: number,
    businessId: string,
    businessName: string,
    businessImages: OlivImage[],
    type: FeedType,
    createdAt: Date,
    memberId?: number,
    profileName?: string,
    profileColor?: string,
    profileImgKey?: string,
    comment?: string,
    reviewImages?: OlivImage[],
    businesses?: Feed[]
  ) {
    this.communityId = communityId;
    this.businessId = businessId;
    this.businessName = businessName;
    this.businessImages = businessImages;
    this.type = type;
    this.createdAt = createdAt;
    this.memberId = memberId;
    this.profileName = profileName;
    this.profileColor = profileColor;
    this.profileImgKey = profileImgKey;
    this.comment = comment;
    this.reviewImages = reviewImages;
    this.businesses = businesses;
  }

  get member(): Member {
    return this.memberId != null
      ? new Member(
          this.profileName!,
          this.profileColor!,
          false,
          [],
          this.profileImgKey
        )
      : new Member("[deleted member]", "#979797", false, [], undefined);
  }

  get businessImage() {
    if (this.businessImages.length > 0) {
      return this.businessImages[0].url!;
    }
    return DefaultRestaurant;
  }
}
