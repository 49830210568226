import React from 'react';
import TagManager from 'react-gtm-module';
import { useLocation } from 'react-router-dom';

const PageviewTracker = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: pathname
      }
    });
  }, [pathname]);

  return null;
}

export default PageviewTracker;