import moment from "moment";
import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import ProfileAvatar from "components/ProfileAvatar";

import { getCommunityBusinessUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";
import { Feed } from "data/models/feed";
import { Community } from "data/models/community";

const CommunityFeedRecommendation = ({
  community,
  feed,
  index,
}: {
  community: Community;
  feed: Feed;
  index: number;
}) => {
  const navigate = useNavigate();
  const communityId = community.urlName;

  const handleClick = () => {
    customEvent("com_dp_feed_item_box", {
      communityId,
      index,
      type: "Recommendation",
    });
    navigate(getCommunityBusinessUrl(communityId, feed.businessId));
  };

  return (
    <div onClick={handleClick}>
      <Stack spacing={2} direction="row" className="px-4">
        <img
          src={feed.businessImage}
          alt={feed.businessName}
          className="aspect-square rounded-lg object-cover border border-solid border-light-grey h-[80px]"
        />
        <Stack spacing={0.5}>
          <Typography variant="h6">{feed.businessName}</Typography>
          <div className="flex items-start">
            <ProfileAvatar profile={feed.member} size={24} />
            <Typography variant="body2" className="ml-[6px]">
              <b>{feed.profileName}</b> recommended
            </Typography>
          </div>
          <Typography
            variant="body3"
            color="darkGrey.main"
            className="pt-[2px]"
          >
            {moment(feed.createdAt).fromNow()}
          </Typography>
        </Stack>
      </Stack>
    </div>
  );
};

export default CommunityFeedRecommendation;
