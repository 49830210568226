import React from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";

import { Stack, Typography } from "@mui/material";

import CommunityFeedView from "./CommunityFeedView";
import CommunityFilters from "./CommunityFilters";
import CommunityJoinAction from "components/CommunityJoinAction";
import CommunityListView from "./CommunityListView";
import CommunityMapView from "./CommunityMapView";
import CommunityModeSelector from "./CommunityModeSelector";
import RecommendFab from "./RecommendFab";
import * as BusinessHelper from "utils/BusinessHelper";
import { CommunityMainLoading } from "./loading";
import { useCommunityState } from "providers/CommunityStateProvider";
import { useCommunity } from "data/hooks/communities";
import { useBusinesses } from "data/hooks/businesses";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const CommunityMainUnauth = ({ community }: { community: Community }) => {
  return (
    <>
      <div className="p-4">
        <CommunityJoinAction
          community={community}
          eventName="com_dp_button_join"
        />
      </div>
      <div className="py-10 px-5 text-center">
        <Typography variant="body2Medium" color="darkGrey.main" className="">
          This is a private community. Please join community to view
          recommendations.
        </Typography>
      </div>
    </>
  );
};

const CommunityMainAuth = ({
  community,
  pinned,
  setPinned,
}: {
  community: Community;
  pinned: boolean;
  setPinned: (pinned: boolean) => void;
}) => {
  const location = useLocation();

  const {
    mode: stateMode,
    filters,
    listSorting,
    mapSorting,
  } = useCommunityState();
  const mode = stateMode || (community.joined ? "Feed" : "List");

  const businesses = useBusinesses(community);

  const [filteredBusinesses, setFilteredBusinesses] = React.useState<
    Business[]
  >([]);

  const stickyElementRef = React.useRef<HTMLDivElement>(null);
  const listTopRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const handleScroll = () => {
      const rect = stickyElementRef?.current?.getBoundingClientRect();
      const isCommunityDetail = /c\/[a-zA-Z0-9_]+$/.test(
        window.location.pathname
      );
      if (isCommunityDetail && rect) setPinned(rect.top <= 57);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setFilteredBusinesses(
      BusinessHelper.filterBusinesses(
        businesses,
        filters,
        mode === "Map" ? mapSorting : listSorting
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, filters, listSorting, mapSorting]);

  React.useEffect(() => {
    if (businesses.length === filteredBusinesses.length) return;
    setFilteredBusinesses(
      BusinessHelper.filterBusinesses(
        businesses,
        filters,
        mode === "Map" ? mapSorting : listSorting
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businesses]);

  React.useEffect(() => {
    if (pinned) listTopRef?.current?.scrollIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, filters, listSorting]);

  React.useEffect(() => {
    const scrollTo = location.state?.scrollTo;
    if (!scrollTo) return;

    if (scrollTo === "Your activities") {
      stickyElementRef.current?.scrollBy(1000, 0);
    }
  }, [location]);

  if (mode === "Map") {
    return (
      <div>
        <div className="sticky top-[56px] z-50 bg-white overflow-scroll py-2 px-4 shadow-md">
          <CommunityFilters
            community={community}
            collapsed={true}
            mode={mode}
          />
        </div>
        <CommunityMapView
          community={community}
          businesses={filteredBusinesses}
        />
      </div>
    );
  }

  return (
    <>
      <Stack spacing={1} sx={{ pt: 2 }}>
        {!community.isPrivate && !community.joined && (
          <div className="p-4">
            <CommunityJoinAction
              community={community}
              eventName="com_dp_button_join"
            />
          </div>
        )}
        <CommunityModeSelector community={community} collapsed={false} />
        <div
          ref={stickyElementRef}
          className={clsx(
            "sticky top-[56px] z-50 bg-white overflow-scroll py-2 px-4",
            {
              "shadow-md": pinned,
            }
          )}
        >
          <CommunityFilters
            community={community}
            collapsed={pinned}
            mode={mode}
          />
        </div>
        <div
          ref={listTopRef}
          className="relative overflowX-hidden scroll-m-[105px]"
        >
          <React.Suspense fallback={<CommunityMainLoading />}>
            {mode === "Feed" ? (
              <CommunityFeedView community={community} />
            ) : (
              <CommunityListView
                community={community}
                businesses={filteredBusinesses}
              />
            )}
          </React.Suspense>
        </div>
      </Stack>
      <div className="fixed w-full bottom-12 z-50 pointer-events-none">
        <div className="flex justify-end max-w-[650px] pr-5">
          <RecommendFab community={community} />
        </div>
      </div>
    </>
  );
};

const CommunityMain = ({
  communityId,
  pinned,
  setPinned,
}: {
  communityId: string;
  pinned: boolean;
  setPinned: (pinned: boolean) => void;
}) => {
  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  if (community.isPrivate && !community.joined) {
    return <CommunityMainUnauth community={community} />;
  }

  return (
    <CommunityMainAuth
      community={community}
      pinned={pinned}
      setPinned={setPinned}
    />
  );
};

export default CommunityMain;
