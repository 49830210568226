import React from "react";

import { Button, Typography } from "@mui/material";

import CommentCardHelpfulMembers from "./CommentCardHelpfulMembers";
import { customEvent } from "utils/AnalyticsEvent";
import { Review } from "data/models/review";
import {
  useHelpfulReview,
  useUnhelpfulReview,
} from "data/hooks/reviewReactions";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const CommentCardHelpfulAction = ({
  community,
  business,
  review,
}: {
  community: Community;
  business: Business;
  review: Review;
}) => {
  const helpful = useHelpfulReview(community, business, review);
  const unhelpful = useUnhelpfulReview(community, business, review);

  const communityId = community.urlName;
  const businessId = business.id;

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    if (review.helpful) {
      customEvent("bus_dp_button_unhelpful", { communityId, businessId });
      unhelpful.mutate();
    } else {
      customEvent("bus_dp_button_helpful", { communityId, businessId });
      helpful.mutate();
    }
  };

  const handleListMembers = () => {
    customEvent("bus_dp_button_helpful_member", { communityId, businessId });
    setOpen(review.helpfulCount > 0);
  };

  const getLabel = () => {
    if (review.helpfulCount <= 0) return "";
    if (review.helpfulCount === 1) return "1 member finds this review helpful";
    return `${review.helpfulCount} members find this review helpful`;
  };

  return (
    <>
      <div className="flex items-center py-2">
        <Button
          variant="outlined"
          color={review.helpful ? "primary" : "lightGreen"}
          size="small"
          className="py-0 px-2 mr-4"
          onClick={handleClick}
        >
          <Typography variant="body3Bold">Helpful</Typography>
        </Button>
        <Typography
          variant="body3"
          color="darkGreen.main"
          onClick={handleListMembers}
        >
          {getLabel()}
        </Typography>
      </div>
      <CommentCardHelpfulMembers
        review={review}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default CommentCardHelpfulAction;
