import { Skeleton, Stack, Typography } from "@mui/material";

export const HeaderLoading = () => {
  return (
    <>
      <div className="w-full">
        <Skeleton variant="rounded" className="w-full h-full aspect-3/2" />
      </div>
      <div className="p-4">
        <Typography variant="h4">
          <Skeleton />
        </Typography>
        <Typography variant="body2">
          <Skeleton />
        </Typography>
        <Skeleton variant="rectangular" className="w-full h-12 mt-4" />
      </div>
    </>
  );
};

export const HeaderLikesLoading = () => {
  return (
    <Stack spacing={1}>
      <Skeleton variant="rectangular" className="w-full h-[40px]" />
      <Skeleton variant="rectangular" className="w-full h-[22px]" />
    </Stack>
  );
};

export const CommentsLoading = () => {
  return (
    <Stack spacing={2} className="p-4">
      <Skeleton variant="rectangular" width="100%" height="150px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
      <Skeleton variant="rectangular" width="100%" height="150px" />
    </Stack>
  );
};

export const BusinessDetailLoading = () => {
  return (
    <Stack spacing={1}>
      <Skeleton width="90%" />
      <Skeleton width="90%" />
      <Skeleton width="90%" />
      <Skeleton width="90%" />
      <Skeleton width="90%" />
    </Stack>
  );
};
