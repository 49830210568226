import { Skeleton, Stack } from "@mui/material";

export const BusinessSearchResultsLoading = () => {
  return (
    <Stack spacing={1} className="bg-white p-4 rounded-xl">
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
      <Skeleton variant="rounded" width="100%" height="50px" />
    </Stack>
  );
};
