import React from "react";
import { useParams } from "react-router-dom";

import { Dialog } from "@mui/material";

import BusinessSearchBox from "./BusinessSearchBox";
import BusinessSearchResults from "./BusinessSearchResults";
import { BusinessSearchResultsLoading } from "./loading";
import { useQueryState } from "utils/QueryState";
import { customEvent } from "utils/AnalyticsEvent";

const BusinessSearchPage = () => {
  const { communityId } = useParams();

  const [query, setQuery] = useQueryState("q");

  React.useEffect(() => {
    customEvent("search_page_view", { communityId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!communityId) throw new Error("Community not found");

  return (
    <Dialog fullScreen open={true} className="bg-white">
      <div className="h-full bg-line-grey p-3 overflow-scroll">
        <BusinessSearchBox
          communityId={communityId}
          query={query}
          setQuery={setQuery}
        />
        <React.Suspense fallback={<BusinessSearchResultsLoading />}>
          <BusinessSearchResults communityId={communityId} query={query} />
        </React.Suspense>
      </div>
    </Dialog>
  );
};

export default BusinessSearchPage;
