import React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, Form, FormikHelpers } from "formik";
import * as Yup from "yup";

import ChallengeContainer from "./ChallengeContainer";
import Constants from "configs/Constants";
import ProfileSelector from "./ProfileSelector";
import { customEvent } from "utils/AnalyticsEvent";
import { useCommunity } from "data/hooks/communities";
import { useData } from "providers/AuthProvider";
import { getCommunityUrl } from "configs/Paths";
import { User } from "@supabase/supabase-js";
import { useJoinCommunity } from "data/hooks/members";

const CommunityJoinSchema = (user: User, communityId: string) =>
  Yup.object().shape({
    profileName: Yup.string()
      .required("Required")
      .min(3, "Must be at least 3 characters")
      .max(20, "Must be 20 characters or less")
      .test(
        "blacklistedChar",
        "Brackets are not allowed in a username",
        (value) => !Constants.PROFILE_BLACKLISTED_CHARS.test(value)
      ),
  });

const CommunityJoinForm = ({
  communityId,
  setCommunityJoined,
}: {
  communityId: string;
  setCommunityJoined: (value: boolean) => void;
}) => {
  const { user } = useData();
  const navigate = useNavigate();

  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  const join = useJoinCommunity(community);

  const [onChallenge, setOnChallenge] = React.useState(true);

  React.useEffect(() => {
    if (community.joined) {
      navigate(getCommunityUrl(communityId));
    }
  }, [communityId, community.joined, navigate]);

  const handleSubmit = async (
    values: ICommunityJoinForm,
    actions: FormikHelpers<ICommunityJoinForm>
  ) => {
    customEvent("challenge_profile_add_cta", { communityId });
    const response = await join.mutateAsync({
      profileName: values.profileName,
      profileImage: values.profileImage?.length
        ? values.profileImage[0]
        : undefined,
      passcode: values.passcode,
      likes: values.likes,
    });

    if (response === 409) {
      actions.setErrors({
        profileName: "Sorry. That unique name is already taken.",
      });
    } else {
      setCommunityJoined(true);
    }
  };

  return (
    <Formik
      initialValues={{ profileName: "" }}
      validationSchema={CommunityJoinSchema(user!, communityId)}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      <Form className="flex-1">
        {onChallenge ? (
          <ChallengeContainer
            community={community}
            handleComplete={() => setOnChallenge(false)}
          />
        ) : (
          <ProfileSelector communityId={community.urlName} />
        )}
      </Form>
    </Formik>
  );
};

export default CommunityJoinForm;
