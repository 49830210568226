import React from "react";

import Constants from "configs/Constants";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

type LocationStatus = "ready" | "loading" | "disabled" | "complete";

const useGeolocation = ({ community }: { community: Community }) => {
  const [status, setStatus] = React.useState<LocationStatus>("disabled");

  const communityId = community.urlName;

  React.useEffect(() => {
    navigator.permissions
      .query({ name: "geolocation" })
      .then((permissionStatus) => {
        if (permissionStatus.state !== "denied") setStatus("ready");
      });
  }, []);

  const getUserLocation = async () => {
    setStatus("loading");

    return new Promise<LatLngLocation>((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setStatus("complete");
          const { latitude, longitude } = position.coords;
          resolve({ lat: latitude, lng: longitude });
        },
        async () => {
          try {
            customEvent("com_dp_map_near_me_fallback", { communityId });
            const response = await window.fetch(Constants.LOCATION_SERVICE_URL);
            const location = await response.json();
            setStatus("complete");
            resolve({ lat: location.latitude, lng: location.longitude });
          } catch (error) {
            setStatus("complete");
            resolve({ lat: 37.3861, lng: -122.0839 });
          }
        },
        {
          enableHighAccuracy: false,
          timeout: 5000,
          maximumAge: 300000,
        }
      );
    });
  };

  return { status, getUserLocation };
};

export default useGeolocation;
