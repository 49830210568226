import React from "react";
import { useNavigate } from "react-router-dom";

import { Fab } from "@mui/material";
import { InfoOutlined, SearchOutlined } from "@mui/icons-material";

import BottomSheet from "components/BottomSheet";
import { getCommunitySearchUrl } from "configs/Paths";
import { useData } from "providers/AuthProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const StarCommunityFab = ({
  community,
  starName,
}: {
  community: Community;
  starName: string;
}) => {
  const [open, setOpen] = React.useState(false);
  const communityId = community.urlName;

  const handleClick = () => {
    customEvent("com_dp_icon_star_info", { communityId });
    setOpen(true);
  };

  return (
    <>
      <Fab
        variant="circular"
        color="white"
        onClick={handleClick}
        className="pointer-events-auto"
      >
        <InfoOutlined />
      </Fab>
      <BottomSheet
        variant="text"
        open={open}
        close={() => setOpen(false)}
        label={`${starName}'s Recommended list`}
        sublabel={`This list is created by ${starName} and only ${starName} can add or delete from the list. Community members can still recommend from the existing list!`}
      />
    </>
  );
};

const RegularCommunityFab = ({ community }: { community: Community }) => {
  const navigate = useNavigate();
  const communityId = community.urlName;

  const handleClick = () => {
    customEvent("com_dp_button_search", { communityId });
    navigate(getCommunitySearchUrl(communityId));
  };

  return (
    <Fab
      variant="circular"
      color="white"
      onClick={handleClick}
      className="pointer-events-auto"
    >
      <SearchOutlined />
    </Fab>
  );
};

const RecommendFab = ({ community }: { community: Community }) => {
  const { user } = useData();

  const isModerator = community?.isModerator;

  if (user == null || !community.joined) return null;

  return !!community?.starName && !isModerator ? (
    <StarCommunityFab community={community} starName={community.starName} />
  ) : (
    <RegularCommunityFab community={community} />
  );
};

export default RecommendFab;
