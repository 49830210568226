import React from "react";
import clsx from "clsx";

import CommunityMap from "./CommunityMap";
import CommunityMapActions from "./CommunityMapActions";
import CommunityMapCards from "./CommunityMapCards";
import { useCommunityState } from "providers/CommunityStateProvider";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const getMapCenter = (
  businesses: Business[],
  activeIndex?: number
): LatLngLocation => {
  const defaultLatLng = { lat: 37.3861, lng: -122.0839 };
  if (businesses.length === 0 || !businesses[0]) return defaultLatLng;

  if (!!activeIndex) return businesses[activeIndex].latlng || defaultLatLng;

  let totalLat = 0;
  let totalLng = 0;
  let counter = 0;

  for (const business of businesses) {
    const latlng = business.latlng;
    totalLat += latlng?.lat || 0;
    totalLng += latlng?.lng || 0;
    counter += latlng ? 1 : 0;
  }

  return { lat: totalLat / counter, lng: totalLng / counter };
};

const CommunityMapView = ({
  community,
  businesses,
}: {
  community: Community;
  businesses: Business[];
}) => {
  const { filters } = useCommunityState();

  const [activeIndex, setActiveIndex] = React.useState<number>();
  const [mapCenter, setMapCenter] = React.useState<LatLngLocation>(
    getMapCenter(businesses, activeIndex)
  );

  React.useEffect(() => {
    setActiveIndex(undefined);
  }, [filters]);

  return (
    <div>
      <div className="fixed max-w-[650px] mx-auto inset-0 overflow-hidden">
        <CommunityMap
          community={community}
          businesses={businesses}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          mapCenter={mapCenter}
          setMapCenter={setMapCenter}
        />
      </div>
      <div
        className={clsx("fixed w-full pointer-events-none", {
          "bottom-0": activeIndex !== undefined,
          "bottom-12": activeIndex === undefined,
        })}
      >
        <CommunityMapActions
          community={community}
          setMapCenter={setMapCenter}
        />
        <CommunityMapCards
          community={community}
          businesses={businesses}
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
        />
      </div>
    </div>
  );
};

export default CommunityMapView;
