import { Community } from "data/models/community";
import LikeChallenge from "./LikeChallenge";
import PasscodeChallenge from "./PasscodeChallenge";

const ChallengeContainer = ({
  community,
  handleComplete,
}: {
  community: Community;
  handleComplete: () => void;
}) => {
  if (community.challenge === "LIKES") {
    return <LikeChallenge {...{ community, handleComplete }} />;
  } else if (community.challenge === "PASSCODE") {
    return <PasscodeChallenge {...{ community, handleComplete }} />;
  }

  handleComplete();
  return <></>;
};

export default ChallengeContainer;
