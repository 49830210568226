import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";

import { Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import OlivAppBar from "components/OlivAppBar";
import { Paths } from "configs/Paths";
import { forgotPassword } from "services/SupabaseAuth";
import { customEvent } from "utils/AnalyticsEvent";
import { useData } from "providers/AuthProvider";
import { supabase } from "configs/Supabase";

const PasswordRecoverySchema = Yup.object().shape({
  repassword: Yup.string()
    .required()
    .min(8, "Password must be at least 8 characters")
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { user } = useData();

  const initialValues = { email: "", password: "", repassword: "" };

  const [error, setError] = React.useState(false);
  const [expired, setExpired] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const verifyToken = async () => {
      const token_hash = searchParams.get("token_hash") || "";
      const { error } = await supabase.auth.verifyOtp({
        token_hash,
        type: "magiclink",
      });

      if (error) {
        if (error.status === 403) {
          setExpired(true);
        } else {
          setError(true);
        }
      }
    };

    verifyToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: PasswordRecoveryForm) => {
    customEvent("oliv_auth_email_login_link_forgot_reset", {});
    try {
      setLoading(true);
      await forgotPassword(values.password);
      navigate(Paths.main);
    } catch (e: any) {
      setLoading(false);
      setError(true);
    }
  };

  if (expired) {
    return (
      <>
        <OlivAppBar />
        <Stack spacing={2} className="py-12 px-10">
          <Typography variant="h4Small">
            Sorry. Your password reset link has expired.
          </Typography>
          <Typography variant="body1">
            Password reset links are valid for a limited time for security
            reasons.
          </Typography>
          <Typography variant="body1" className="py-6">
            To reset your password, please request a new reset link by clicking
            the "Forgot Password" link on the login page.
          </Typography>
        </Stack>
      </>
    );
  }

  if (error) {
    return (
      <>
        <OlivAppBar />
        <Stack spacing={2} className="py-12 px-10">
          <Typography variant="h4Small">
            Oops, something went wrong. Please try again, or email
            support@oliv.org
          </Typography>
        </Stack>
      </>
    );
  }

  if (user === undefined || user === null) {
    return <></>;
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={PasswordRecoverySchema}
      validateOnBlur={false}
      validateOnChange={false}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, errors }) => (
        <Form>
          <OlivAppBar />
          <Stack spacing={4} className="p-12">
            <Typography variant="h4">New password</Typography>
            <Stack spacing={2}>
              <div>
                <Typography
                  variant="body1ExtraBold"
                  color={errors.repassword ? "error" : "darkGreen.main"}
                >
                  Enter new password
                </Typography>
                <TextField
                  fullWidth
                  type="password"
                  id="password"
                  name="password"
                  value={values.password}
                  error={!!errors.repassword}
                  onChange={handleChange}
                  variant="outlined"
                  color="darkGrey"
                  placeholder="At least 8 characters"
                  className="mt-1"
                  InputProps={{ className: "rounded-xl" }}
                />
              </div>
              <div>
                <Typography
                  variant="body1ExtraBold"
                  color={errors.repassword ? "error" : "darkGreen.main"}
                >
                  Confirm password
                </Typography>
                <TextField
                  fullWidth
                  type="password"
                  id="repassword"
                  name="repassword"
                  value={values.repassword}
                  error={!!errors.repassword}
                  onChange={handleChange}
                  variant="outlined"
                  color="darkGrey"
                  placeholder="At least 8 characters"
                  className="mt-1"
                  InputProps={{ className: "rounded-xl" }}
                />
              </div>
              {errors.repassword && (
                <Typography color="error">{errors.repassword}</Typography>
              )}
              {error && (
                <Typography color="error">
                  Oops, something went wrong. Please try again, or email
                  support@oliv.org
                </Typography>
              )}
            </Stack>
            <LoadingButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              loading={loading}
              disabled={values.password === "" || values.repassword === ""}
              loadingPosition="start"
              startIcon={<></>}
              className="rounded-[20px] mb-2"
            >
              <Typography variant="body1ExtraBold">Reset password</Typography>
            </LoadingButton>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPassword;
