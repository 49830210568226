import React from "react";
import { useParams } from "react-router-dom";

import Dialog from "@mui/material/Dialog";

import CommunityJoinConfirmation from "./CommunityJoinConfirmation";
import CommunityJoinForm from "./CommunityJoinForm";
import OlivAppBar from "components/OlivAppBar";

const CommunityJoin = () => {
  const { communityId } = useParams();

  const [communityJoined, setCommunityJoined] = React.useState<boolean>(false);

  if (!communityId) throw new Error("Community not found");

  return communityJoined ? (
    <CommunityJoinConfirmation communityId={communityId} />
  ) : (
    <Dialog fullScreen open={true}>
      <OlivAppBar showClose="challenge_flow_close" communityId={communityId} />
      <React.Suspense fallback={<></>}>
        <CommunityJoinForm
          communityId={communityId}
          setCommunityJoined={setCommunityJoined}
        />
      </React.Suspense>
    </Dialog>
  );
};

export default CommunityJoin;
