import { useNavigate } from "react-router-dom";

import { Grid, IconButton, Stack, Typography } from "@mui/material";
import { Favorite, FavoriteBorderRounded } from "@mui/icons-material";

import { getCommunityBusinessUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";
import { Business } from "data/models/business";
import { Community } from "data/models/community";
import { useRecommend, useUnrecommend } from "data/hooks/business";

const BusinessImage = ({ business }: { business: Business }) => {
  return (
    <img
      src={business.image}
      alt={business.name}
      className="aspect-square w-full object-cover rounded-lg border border-solid border-light-grey"
    />
  );
};

const BusinessMetadata = ({ business }: { business: Business }) => {
  return (
    <Stack sx={{ pr: 1 }}>
      <Typography variant="body1Bold">{business.name}</Typography>
      <Typography variant="body3" color="lightGreen.main">
        {business.city}
      </Typography>
    </Stack>
  );
};

const BusinessLikeAction = ({ business }: { business: Business }) => {
  return (
    <Stack className="items-center">
      <IconButton className="p-0">
        {business.recommended ? (
          <Favorite color="primary" />
        ) : (
          <FavoriteBorderRounded />
        )}
      </IconButton>
      <Typography
        variant="body1"
        color={business.recommended ? "primary" : "lightGreen.main"}
        sx={{ textAlign: "center" }}
      >
        {!!business.recommendationsCount && business.recommendationsCount}
      </Typography>
    </Stack>
  );
};

const BusinessSearchResultsCard = ({
  community,
  business,
  handleRecommend,
}: {
  community: Community;
  business: Business;
  handleRecommend: (businessId: string, isRecommended: boolean) => void;
}) => {
  const navigate = useNavigate();

  const recommend = useRecommend(community, business);
  const unrecommend = useUnrecommend(community, business);

  const communityId = community.urlName;

  const handleClick = () => {
    customEvent("search_box_business_row", { communityId });
    navigate(getCommunityBusinessUrl(communityId, business.id), {
      state: { back: true },
    });
  };

  const handleLike = (evt: React.MouseEvent) => {
    evt.stopPropagation();

    if (business.recommended) {
      customEvent("icon_unrecommend", { communityId, location: "searchIn" });
      handleRecommend(business.id, false);
      unrecommend.mutate();
    } else {
      customEvent("icon_recommend", {
        communityId,
        location: business.recommendationsCount > 0 ? "searchIn" : "searchOut",
      });
      handleRecommend(business.id, true);
      recommend.mutate();
    }
  };

  return (
    <div>
      <Grid container spacing={2} onClick={() => handleClick()}>
        <Grid item xs={2}>
          <BusinessImage business={business} />
        </Grid>
        <Grid item xs={9} className="flex items-center">
          <BusinessMetadata business={business} />
        </Grid>
        <Grid
          item
          xs={1}
          className="flex items-center justify-end"
          onClick={handleLike}
        >
          <BusinessLikeAction business={business} />
        </Grid>
      </Grid>
    </div>
  );
};

const BusinessSearchResultsCards = ({
  label,
  community,
  businesses,
  handleRecommend,
}: {
  label: string;
  community: Community;
  businesses: Business[];
  handleRecommend: (businessId: string, isRecommended: boolean) => void;
}) => {
  if (businesses.length === 0) return <></>;

  return (
    <Stack spacing={2} className="bg-white p-4 rounded-xl">
      <Typography variant="body1ExtraBold">{label}</Typography>
      {businesses.map((business) => (
        <BusinessSearchResultsCard
          key={business.id}
          community={community}
          business={business}
          handleRecommend={handleRecommend}
        />
      ))}
    </Stack>
  );
};

export default BusinessSearchResultsCards;
