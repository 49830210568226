const COMMUNITY_ID = ":communityId";
const BUSINESS_ID = ":businessId";

export const Paths = {
  main: "/",
  discover: "/c",
  community: `/c/${COMMUNITY_ID}`,
  community_detail: `/c/${COMMUNITY_ID}`,
  community_map: `/c/${COMMUNITY_ID}/map`,
  community_join: `/c/${COMMUNITY_ID}/join`,
  community_search: `/c/${COMMUNITY_ID}/b`,
  community_business: `/c/${COMMUNITY_ID}/b/${BUSINESS_ID}`,
  community_profile: `/c/${COMMUNITY_ID}/profile`,
  review: `/c/${COMMUNITY_ID}/b/${BUSINESS_ID}/review`,

  terms_of_use: "/terms-of-use",
  privacy_policy: "/privacy-policy",
  error404: "/errors/404",
  error500: "/errors/500",

  verify_email: "/verify",
  reset_password: "/reset-password",

  app_terms_of_use: "/app/terms-of-use",
  app_privacy_policy: "/app/privacy-policy",
};

export const getCommunityUrl = (communityId: string) =>
  Paths.community_detail.replace(COMMUNITY_ID, communityId);

export const getCommunityMapUrl = (communityId: string) =>
  Paths.community_map.replace(COMMUNITY_ID, communityId);

export const getCommunityJoinUrl = (communityId: string) =>
  Paths.community_join.replace(COMMUNITY_ID, communityId);

export const getCommunitySearchUrl = (communityId: string) =>
  Paths.community_search.replace(COMMUNITY_ID, communityId);

export const getCommunityProfileUrl = (communityId: string) =>
  Paths.community_profile.replace(COMMUNITY_ID, communityId);

export const getCommunityBusinessUrl = (
  communityId: string,
  businessId: string
) =>
  Paths.community_business
    .replace(COMMUNITY_ID, communityId)
    .replace(BUSINESS_ID, businessId);

export const getReviewUrl = (communityId: string, businessId: string) =>
  Paths.review
    .replace(COMMUNITY_ID, communityId)
    .replace(BUSINESS_ID, businessId);
