import * as CommunitiesApi from "data/apis/communities";
import { Community } from "data/models/community";
import { useQuery } from "@tanstack/react-query";

export const useCommunity = (urlName?: string) => {
  const urlNameMatcher = (c: Community) => {
    return c.urlName.toLowerCase() === urlName?.toLowerCase();
  };

  const { data: community } = useQuery({
    queryKey: ["communities"],
    queryFn: CommunitiesApi.fetch,
    select: (communities) => communities.find(urlNameMatcher),
  });

  return community;
};

export const useCommunities = () => {
  const { data: communities } = useQuery({
    queryKey: ["communities"],
    queryFn: CommunitiesApi.fetch,
  });

  return communities || [];
};
