import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { Typography } from "@mui/material";

import ProfileAvatar from "components/ProfileAvatar";
import { Review } from "data/models/review";
import { Community } from "data/models/community";
import { Business } from "data/models/business";
import { useStarBusinessReviews } from "data/hooks/businessReviews";

const CommunityCardStarComment = ({
  community,
  business,
  handleClick,
}: {
  community: Community;
  business: Business;
  handleClick: (evt: React.MouseEvent) => void;
}) => {
  const reviews = useStarBusinessReviews(community, business);

  if (reviews.length === 0) {
    return null;
  }

  const review = reviews[0];
  const profile = review.member;

  const combinedText = `**${profile.profileName}**&nbsp;&nbsp;${review.comment}`;

  return (
    <div className="pb-2">
      <div
        className="flex items-start rounded-xl border border-solid border-filter-grey p-4"
        onClick={handleClick}
      >
        <>
          <ProfileAvatar profile={profile} size={28} />
          <Typography
            variant="body2"
            className="ml-3 line-clamp-3"
            component="div"
          >
            <ReactMarkdown>{combinedText}</ReactMarkdown>
          </Typography>
        </>
      </div>
    </div>
  );
};

export default CommunityCardStarComment;
