import React from "react";
import { Button } from "@mui/material";

import { ArrowDropDownOutlined } from "@mui/icons-material";

import BottomSheet from "components/BottomSheet";
import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityFiltersMultiSelect = ({
  community,
  filter,
  label,
  menuItems,
  eventName,
  eventParams,
}: {
  community: Community;
  filter: string;
  label: string;
  menuItems: IBottomSheetMenuItem[];
  eventName: string;
  eventParams?: { [key: string]: string };
}) => {
  const { mode, filters, setFilters } = useCommunityState();

  const [open, setOpen] = React.useState(false);

  const filterValues = filters.multi[filter];
  const selections = Array.isArray(filterValues) ? filterValues : [];
  const communityId = community.urlName;

  const handleClick = () => {
    customEvent(eventName, { communityId, mode, ...eventParams });
    setOpen(true);
  };

  const handleApply = (selections: string[]) => {
    customEvent(eventName + "_m_apply", {
      communityId,
      mode,
      selections,
      ...eventParams,
    });
    const multiFilters = { ...filters.multi, [filter]: selections };
    setFilters({ ...filters, multi: multiFilters });
  };

  const handleClose = () => {
    customEvent(eventName + "_m_close", { communityId, mode, ...eventParams });
    setOpen(false);
  };

  return (
    <>
      <Button
        variant="outlined"
        color={selections.length > 0 ? "primary" : "lightGreen"}
        className="rounded-xl h-10 px-2 py-1.5 btn-selected [&>.MuiButton-endIcon]:ml-0"
        endIcon={<ArrowDropDownOutlined />}
        onClick={handleClick}
      >
        {selections.length === 1 ? selections[0] : label}
      </Button>
      <BottomSheet
        variant="multiSelect"
        open={open}
        close={handleClose}
        label={label}
        menuItems={menuItems.map((item) => ({
          ...item,
          active: selections.includes(item.label),
        }))}
        handleApply={handleApply}
      />
    </>
  );
};

export default CommunityFiltersMultiSelect;
