import React from "react";

import dayjs from "dayjs";
import { Typography } from "@mui/material";
import { CheckCircleOutlined } from "@mui/icons-material";

import { Business } from "data/models/business";

const BusinessHeaderVerified = ({ business }: { business: Business }) => {
  if (!business.verified) return null;

  return (
    <Typography
      color="brightGreen.main"
      variant="body3Bold"
      className="px-4 pt-2 flex items-center"
    >
      <CheckCircleOutlined fontSize="small" className="mr-1" />
      Verified with staff on {dayjs(business.verified).format("YYYY.MM")}
    </Typography>
  );
};

export default BusinessHeaderVerified;
