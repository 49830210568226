import { Expose, Type } from "class-transformer";

class BusinessHour {
  day!: string;
  @Expose({ name: "range_1" }) range1!: string;
  @Expose({ name: "range_2" }) range2?: string;
}

export class BusinessHours {
  @Type(() => BusinessHour) hours: BusinessHour[] = [];
  @Expose({ name: "is_open" }) isOpen?: boolean;
}
