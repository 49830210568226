import React from "react";

interface ICommunityStateContext {
  mode?: CommunityMode;
  filters: CommunityFilters;
  mapSorting: CommunitySorting;
  listSorting: CommunitySorting;
  setMode: (mode: CommunityMode) => void;
  setFilters: (filters: CommunityFilters) => void;
  setMapSorting: (sorting: CommunitySorting) => void;
  setListSorting: (sorting: CommunitySorting) => void;
}

const CommunityStateContext = React.createContext<
  ICommunityStateContext | undefined
>(undefined);

export const CommunityStateProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [mode, setMode] = React.useState<CommunityMode>();
  const [filters, setFilters] = React.useState<CommunityFilters>({
    toggle: {},
    single: {},
    multi: {},
  });
  const [mapSorting, setMapSorting] = React.useState<CommunitySorting>();
  const [listSorting, setListSorting] =
    React.useState<CommunitySorting>("Popular");

  return (
    <CommunityStateContext.Provider
      value={{
        filters,
        mode,
        mapSorting,
        listSorting,
        setFilters,
        setMode,
        setMapSorting,
        setListSorting,
      }}
    >
      {children}
    </CommunityStateContext.Provider>
  );
};

export const useCommunityState = () => {
  return React.useContext(CommunityStateContext) as ICommunityStateContext;
};
