import React from "react";

import { Typography } from "@mui/material";

import CommunityCards from "./CommunityCards";
import OlivAppBar from "components/OlivAppBar";
import { HomeLoading } from "./loading";
import { customEvent } from "utils/AnalyticsEvent";

const Home = () => {
  React.useEffect(() => {
    customEvent("dash_page_view", {});
  }, []);

  return (
    <>
      <OlivAppBar />
      <div className="px-4 py-6">
        <Typography variant="h5" className="pb-4">
          Your communities
        </Typography>
        <React.Suspense fallback={<HomeLoading />}>
          <CommunityCards />
        </React.Suspense>
      </div>
    </>
  );
};

export default Home;
