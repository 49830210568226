import { Stack } from "@mui/material";

import {
  BookmarkBorderOutlined,
  ChatBubbleOutlineOutlined,
  FavoriteBorderOutlined,
} from "@mui/icons-material";

import CommunityFiltersCustom from "./CommunityFiltersCustom";
import CommunityFiltersMultiSelect from "./CommunityFiltersMultiSelect";
import CommunityFiltersSingleSelect from "./CommunityFiltersSingleSelect";
import CommunityFiltersToggle from "./CommunityFiltersToggle";
import CommunityFiltersSort from "./CommunityFiltersSort";
import CommunityModeSelector from "./CommunityModeSelector";
import * as BusinessHelper from "utils/BusinessHelper";
import { useData } from "providers/AuthProvider";
import { useBusinesses } from "data/hooks/businesses";
import { Community } from "data/models/community";

const CommunityFilters = ({
  community,
  collapsed,
  mode,
}: {
  community: Community;
  collapsed: boolean;
  mode: CommunityMode;
}) => {
  const { user } = useData();

  const businesses = useBusinesses(community);

  if (!community) throw new Error("Community not found");

  const allCuisines = BusinessHelper.getAllCuisines(businesses);
  const allPricesList = ["$", "$$", "$$$", "$$$$"];

  return (
    <Stack spacing={1} direction="row" className="w-max">
      {collapsed && (
        <CommunityModeSelector community={community} collapsed={true} />
      )}
      {mode === "List" && <CommunityFiltersSort community={community} />}
      {mode !== "Feed" && (
        <>
          {(community.tags || []).map((tag: string, i: number) => (
            <CommunityFiltersCustom
              key={tag}
              community={community}
              tag={tag}
              index={i}
            />
          ))}
          <CommunityFiltersToggle
            community={community}
            filter="openNow"
            label="Open Now"
            eventName="com_dp_filter_open"
          />
          <CommunityFiltersMultiSelect
            community={community}
            filter="cuisines"
            label="Cuisine"
            menuItems={allCuisines.map(([cuisine, count]) => ({
              label: cuisine,
              sublabel: count.toString(),
            }))}
            eventName="com_dp_filter_cuisine"
          />
          <CommunityFiltersMultiSelect
            community={community}
            filter="prices"
            label="Price"
            menuItems={allPricesList.map((price) => ({
              label: price,
            }))}
            eventName="com_dp_filter_price"
          />
        </>
      )}
      {user != null && community.joined && mode !== "Feed" && (
        <CommunityFiltersSingleSelect
          community={community}
          filter="activities"
          label="Your activities"
          menuItems={[
            { label: "Your Recommendations", icon: <FavoriteBorderOutlined /> },
            { label: "Your Reviews", icon: <ChatBubbleOutlineOutlined /> },
            {
              label: "Your Saved Places",
              icon: <BookmarkBorderOutlined />,
            },
          ]}
          eventName="com_dp_filter_your_activities"
        />
      )}
    </Stack>
  );
};

export default CommunityFilters;
