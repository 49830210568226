import * as MembersApi from "data/apis/members";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Community } from "data/models/community";

export const useCommunityMembers = (community: Community) => {
  const { data } = useQuery({
    queryKey: ["members", community.id],
    queryFn: async () => await MembersApi.fetch(community),
  });

  return data || [];
};

export const useJoinCommunity = (community: Community) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      profileName,
      profileImage,
      passcode,
      likes,
    }: {
      profileName: string;
      profileImage?: File;
      passcode?: string;
      likes?: string[];
    }) =>
      await MembersApi.addMember(
        community,
        profileName,
        profileImage,
        passcode,
        likes
      ),
    onSuccess: async () => {
      queryClient.invalidateQueries({
        queryKey: ["communities"],
      });
      queryClient.invalidateQueries({
        queryKey: ["feed", community.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["members", community.id],
      });
      queryClient.invalidateQueries({
        queryKey: ["businesses", community.id],
      });
    },
  });
};

export const useLeaveCommunity = (community: Community) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => await MembersApi.removeMember(community.memberId!),
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: ["communities"],
      });
      queryClient.refetchQueries({
        queryKey: ["members", community.id],
      });
    },
  });
};

export const useUpdateMember = (community: Community) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      profileName,
      profileImage,
    }: {
      profileName: string;
      profileImage?: File;
    }) => await MembersApi.updateMember(community, profileName, profileImage),
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: ["communities"],
      });
      queryClient.refetchQueries({
        queryKey: ["feed", community.id],
      });
      queryClient.refetchQueries({
        queryKey: ["members", community.id],
      });
    },
  });
};
