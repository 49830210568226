import React from "react";
import { useNavigate } from "react-router-dom";

import { Backdrop, IconButton, Stack, Typography } from "@mui/material";
import { Clear } from "@mui/icons-material";

import { useCommunity } from "data/hooks/communities";
import { getCommunityUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";

const CommunityJoinConfirmation = ({
  communityId,
}: {
  communityId: string;
}) => {
  const navigate = useNavigate();

  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  React.useEffect(() => {
    customEvent("challenge_success_page_view", { communityId });
    const timer = setTimeout(handleClose, 3000);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    navigate(getCommunityUrl(community.urlName));
  };

  return (
    <Backdrop
      open={true}
      onClick={handleClose}
      transitionDuration={0}
      sx={{ backgroundColor: "rgba(255, 255, 255, 0.9)", zIndex: 10000, p: 4 }}
    >
      <Stack spacing={6} className="items-center text-center w-full">
        <img
          src={community.coverImgUrl}
          alt={community.name}
          className="w-48 h-48 rounded-xl object-cover"
        />
        <Typography variant="h5">
          Congrats! You're now a member of {community.name}
        </Typography>
      </Stack>
      <IconButton sx={{ position: "absolute", top: "15px", left: "16px" }}>
        <Clear sx={{ color: "black.main" }} />
      </IconButton>
    </Backdrop>
  );
};

export default CommunityJoinConfirmation;
