import React from "react";
import { useParams, Outlet } from "react-router-dom";

import CommunityHeader from "./CommunityHeader";
import CommunityMain from "./CommunityMain";
import CommunityPromotion from "./CommunityPromotion";
import CommunitySwitcher from "./CommunitySwitcher";
import ErrorBoundary from "./error";
import OlivAppBar from "components/OlivAppBar";
import { CommunityStateProvider } from "providers/CommunityStateProvider";
import { CommunityMainLoading, HeaderLoading } from "./loading";
import { customEvent } from "utils/AnalyticsEvent";

const CommunityDetail = () => {
  const { communityId } = useParams();

  const [pinned, setPinned] = React.useState<boolean>(false);

  React.useEffect(() => {
    customEvent("com_dp_page_view", { communityId });
    window.scroll(0, 0);
  }, [communityId]);

  if (!communityId) return <div>Community not found</div>;

  return (
    <ErrorBoundary>
      <OlivAppBar communityId={communityId} showLogo={!pinned} />
      <CommunityStateProvider>
        <React.Suspense>
          <CommunitySwitcher communityId={communityId} setPinned={setPinned} />
        </React.Suspense>
        <React.Suspense fallback={<HeaderLoading />}>
          <CommunityHeader communityId={communityId} />
        </React.Suspense>
        <React.Suspense fallback={<CommunityMainLoading />}>
          <CommunityMain
            communityId={communityId}
            pinned={pinned}
            setPinned={setPinned}
          />
        </React.Suspense>
        <React.Suspense>
          <CommunityPromotion communityId={communityId} />
        </React.Suspense>
        <Outlet />
      </CommunityStateProvider>
    </ErrorBoundary>
  );
};

export default CommunityDetail;
