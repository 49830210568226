import { Typography, TypographyProps } from "@mui/material";
import React from "react";

import { customEvent } from "utils/AnalyticsEvent";

interface IClampedTypographyProps extends TypographyProps {
  communityId: string;
  eventName: string;
}

const ClampedTypography = (
  props: React.PropsWithChildren<IClampedTypographyProps>
) => {
  const ref = React.useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = React.useState(false);
  const [isClamped, setIsClamped] = React.useState(false);

  const { communityId, eventName, ...typographyProps } = props;

  React.useEffect(() => {
    const handleResize = () => {
      if (!ref.current) return;
      setIsClamped(ref.current.scrollHeight > ref.current.clientHeight);
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const clampedTypographyProps = {
    ...typographyProps,
    className: `${typographyProps.className} ${
      isExpanded ? "line-clamp-0" : "line-clamp-5"
    }`,
  };

  const handleClick = () => {
    if (!isClamped) return;

    if (isExpanded) {
      customEvent(`${eventName}_collapse_text`, { communityId });
      setIsExpanded(false);
    } else {
      customEvent(`${eventName}_expand_text`, { communityId });
      setIsExpanded(true);
    }
  };

  return (
    <div>
      <Typography ref={ref} {...clampedTypographyProps} onClick={handleClick}>
        {props.children}
      </Typography>
    </div>
  );
};

export default ClampedTypography;
