import React from "react";

import { CircularProgress, Fab, Stack } from "@mui/material";
import { NearMeOutlined } from "@mui/icons-material";

import RecommendFab from "./RecommendFab";
import useGeolocation from "data/hooks/location";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityMapActions = ({
  community,
  setMapCenter,
}: {
  community: Community;
  setMapCenter: (location: LatLngLocation) => void;
}) => {
  const { status, getUserLocation } = useGeolocation({ community });

  const communityId = community.urlName;

  const handleNearMe = async () => {
    customEvent("com_dp_map_near_me", { communityId });
    const location = await getUserLocation();
    location.isUserLocation = true;
    setMapCenter(location);
  };

  return (
    <Stack
      spacing={2}
      className="items-end max-w-[650px] pr-5 pointer-events-none"
    >
      <Fab color="white" onClick={handleNearMe} className="pointer-events-auto">
        {status === "loading" ? (
          <CircularProgress color="darkGrey" size="20px" />
        ) : (
          <NearMeOutlined />
        )}
      </Fab>
      <RecommendFab community={community} />
    </Stack>
  );
};

export default CommunityMapActions;
