import * as BusinessesApi from "data/apis/businesses";
import {
  QueryClient,
  useMutation,
  useQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { Community } from "data/models/community";
import { Business } from "data/models/business";
import { Recommendation } from "data/models/recommendation";

export const useBusiness = (community: Community, businessId: string) => {
  const { data } = useQuery({
    queryKey: ["businesses", community.id, businessId],
    queryFn: async () => await BusinessesApi.fetch(community, businessId),
  });

  return data;
};

export const useRecommend = (community: Community, business: Business) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await BusinessesApi.addRecommendation(community, business),
    onMutate: async () => {
      await updateRecommendedBusiness(queryClient, community, business, true);
      await updateAddRecommendation(queryClient, community, business);
    },
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: ["feed", community.id],
      });
    },
  });
};

export const useUnrecommend = (community: Community, business: Business) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await BusinessesApi.removeRecommendation(community, business),
    onMutate: async () => {
      await updateRecommendedBusiness(queryClient, community, business, false);
      await updateRemoveRecommendation(queryClient, community, business);
    },
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: ["feed", community.id],
      });
    },
  });
};

export const useBookmark = (community: Community, business: Business) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await BusinessesApi.addBookmark(community, business),
    onMutate: async () => {
      await updateBookmarkedBusiness(queryClient, community, business, true);
    },
  });
};

export const useUnbookmark = (community: Community, business: Business) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () =>
      await BusinessesApi.removeBookmark(community, business),
    onMutate: async () => {
      await updateBookmarkedBusiness(queryClient, community, business, false);
    },
  });
};

const updateRecommendedBusiness = async (
  queryClient: QueryClient,
  community: Community,
  business: Business,
  isRecommended: boolean
) => {
  const businessQueryKey = ["businesses", community.id, business.id];
  await queryClient.cancelQueries(businessQueryKey);
  queryClient.setQueryData(businessQueryKey, (old?: Business) => {
    if (!old) return old;
    old.recommended = isRecommended;
    old.recommendationsCount += isRecommended ? 1 : -1;
    return old;
  });

  const businessesQueryKey = ["businesses", community.id];
  await queryClient.cancelQueries(businessesQueryKey);
  queryClient.setQueryData(businessesQueryKey, (old?: Business[]) => {
    if (!old) return old;
    return old.map((b) => {
      if (b.id === business.id) {
        b.recommended = isRecommended;
        b.recommendationsCount += isRecommended ? 1 : -1;
      }
      return b;
    });
  });
};

const updateBookmarkedBusiness = async (
  queryClient: QueryClient,
  community: Community,
  business: Business,
  isBookmarked: boolean
) => {
  const reviewsQueryKey = ["businesses", community.id, business.id];
  await queryClient.cancelQueries(reviewsQueryKey);
  queryClient.setQueryData(reviewsQueryKey, (old?: Business) => {
    if (!old) return old;
    old.bookmarked = isBookmarked;
    return old;
  });

  const businessesQueryKey = ["businesses", community.id];
  await queryClient.cancelQueries(businessesQueryKey);
  queryClient.setQueryData(businessesQueryKey, (old?: Business[]) => {
    if (!old) return old;
    return old.map((b) => {
      if (b.id === business.id) {
        b.bookmarked = isBookmarked;
      }
      return b;
    });
  });
};

const updateAddRecommendation = async (
  queryClient: QueryClient,
  community: Community,
  business: Business
) => {
  const reviewsQueryKey = ["recommendations", community.id, business.id];
  await queryClient.cancelQueries(reviewsQueryKey);
  queryClient.setQueryData(reviewsQueryKey, (old?: Recommendation[]) => {
    return [
      ...(old || []),
      new Recommendation(
        0,
        new Date(),
        community.profileName!,
        community.profileColor!,
        community.isModerator!,
        community.badges!,
        community.profileImgKey
      ),
    ];
  });
};

const updateRemoveRecommendation = async (
  queryClient: QueryClient,
  community: Community,
  business: Business
) => {
  const reviewsQueryKey = ["recommendations", community.id, business.id];
  await queryClient.cancelQueries(reviewsQueryKey);
  queryClient.setQueryData(reviewsQueryKey, (old?: Recommendation[]) => {
    if (!old) return old;
    return old.filter((r) => r.profileName !== community.profileName);
  });
};
