import React from "react";
import { useNavigate } from "react-router-dom";

import { Grid } from "@mui/material";

import CommunityAddCard from "./CommunityAddCard";
import CommunityCard from "./CommunityCard";
import { Paths } from "configs/Paths";
import { useCommunities } from "data/hooks/communities";
import { Community } from "data/models/community";

const CommunityCards = () => {
  const navigate = useNavigate();
  const communities = useCommunities();
  const memberCommunities = communities.filter((c) => c.joined);

  React.useEffect(() => {
    if (memberCommunities.length === 0) navigate(Paths.discover);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Grid container spacing={{ xs: 2, sm: 3, md: 4 }}>
      {memberCommunities.map((community: Community, i: number) => (
        <Grid item key={community.urlName} xs={6} className="aspect-square">
          <CommunityCard community={community} index={i} />
        </Grid>
      ))}
      <Grid item xs={6} className="aspect-square">
        <CommunityAddCard />
      </Grid>
    </Grid>
  );
};

export default CommunityCards;
