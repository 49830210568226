import React from "react";
import { Form, Formik, yupToFormErrors } from "formik";
import * as Yup from "yup";

import { Stack, TextField, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import Disclaimer from "./Disclaimer";
import { emailSignUp } from "services/SupabaseAuth";
import { customEvent } from "utils/AnalyticsEvent";

const EmailSignUpSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email address").required(),
  repassword: Yup.string()
    .min(8, "Password needs to be at least 8 characters")
    .required()
    .oneOf([Yup.ref("password")], "Passwords do not match"),
});

const EmailSignUp = ({
  handleSignIn,
  handleConfirm,
  setEmail,
}: {
  handleSignIn: () => void;
  handleConfirm: () => void;
  setEmail: (email: string) => void;
}) => {
  const initialValues = { email: "", password: "", repassword: "" };

  const [loading, setLoading] = React.useState(false);
  const [failed, setFailed] = React.useState(false);

  React.useEffect(() => {
    customEvent("oliv_auth_email_signup_page_view", {});
  }, []);

  const handleValidate = async (values: EmailSignUpForm) => {
    try {
      await EmailSignUpSchema.validate(values);
    } catch (err) {
      const errors = yupToFormErrors<EmailSignUpForm>(err);
      if (errors.repassword === "Passwords do not match") {
        customEvent("oliv_auth_email_signup_error_passwords_match", {});
      } else if (
        errors.repassword === "Password needs to be at least 8 characters"
      ) {
        customEvent("oliv_auth_email_signup_error_charcter_length", {});
      }
      return errors;
    }
  };

  const handleSubmit = async (values: EmailSignUpForm) => {
    customEvent("oliv_auth_email_signup_button_cta", {});
    setLoading(true);
    try {
      await emailSignUp(values.email, values.password);
      setEmail(values.email);
      handleConfirm();
    } catch (e: any) {
      customEvent("oliv_auth_email_signup_error_existing_account", {});
      if (e.message === "User already registered") setFailed(true);
    }
    setLoading(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validateOnBlur={false}
      validateOnChange={false}
      validate={handleValidate}
      onSubmit={handleSubmit}
    >
      {({ values, handleChange, errors }) => {
        return (
          <Form>
            <Stack spacing={2}>
              <Typography variant="h4" className="pb-3">
                Create an Oliv account
              </Typography>
              <div>
                <Typography
                  variant="body1ExtraBold"
                  color={failed || errors.email ? "error" : "darkGreen.main"}
                >
                  Email
                </Typography>
                <TextField
                  fullWidth
                  type="email"
                  id="email"
                  name="email"
                  error={failed || !!errors.email}
                  value={values.email}
                  onChange={handleChange}
                  variant="outlined"
                  color="darkGrey"
                  placeholder="Enter your email"
                  className="mt-1"
                  InputProps={{ className: "rounded-xl" }}
                />
                {failed && (
                  <Typography variant="body2" color="error" className="mt-1">
                    An account with that email already exists.
                  </Typography>
                )}
                {errors.email && (
                  <Typography variant="body2" color="error" className="mt-1">
                    {errors.email}
                  </Typography>
                )}
              </div>
              <div>
                <Typography
                  variant="body1ExtraBold"
                  color={errors.repassword ? "error" : "darkGreen.main"}
                >
                  Password
                </Typography>
                <TextField
                  fullWidth
                  type="password"
                  id="password"
                  name="password"
                  value={values.password}
                  error={!!errors.repassword}
                  onChange={handleChange}
                  variant="outlined"
                  color="darkGrey"
                  placeholder="At least 8 characters"
                  className="mt-1"
                  InputProps={{ className: "rounded-xl" }}
                />
              </div>
              <div>
                <Typography
                  variant="body1ExtraBold"
                  color={errors.repassword ? "error" : "darkGreen.main"}
                >
                  Confirm Password
                </Typography>
                <TextField
                  fullWidth
                  type="password"
                  id="repassword"
                  name="repassword"
                  value={values.repassword}
                  error={!!errors.repassword}
                  onChange={handleChange}
                  variant="outlined"
                  color="darkGrey"
                  placeholder="At least 8 characters"
                  className="mt-1"
                  InputProps={{ className: "rounded-xl" }}
                />
                {errors.repassword && (
                  <Typography variant="body2" color="error" className="mt-1">
                    {errors.repassword}
                  </Typography>
                )}
              </div>
              <div className="pb-6">
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={loading}
                  loadingPosition="start"
                  startIcon={<></>}
                  disabled={
                    values.email === "" ||
                    values.password === "" ||
                    values.repassword === ""
                  }
                  className="rounded-[20px] mt-4 mb-2"
                >
                  <Typography variant="body1ExtraBold">Sign Up</Typography>
                </LoadingButton>
                <div className="text-center" onClick={handleSignIn}>
                  <Typography variant="body2Bold" color="lightGreen.main">
                    Already have an account?&nbsp;
                  </Typography>
                  <Typography variant="body2ExtraBold" color="primary">
                    Log In
                  </Typography>
                </div>
              </div>
              <Disclaimer />
            </Stack>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EmailSignUp;
