import React from "react";

import { Drawer } from "@mui/material";

import EmailConfirm from "./EmailConfirm";
import EmailRecovery from "./EmailRecovery";
import EmailRecoveryConfirm from "./EmailRecoveryConfirm";
import EmailSignIn from "./EmailSignIn";
import EmailSignUp from "./EmailSignUp";
import SocialSignIn from "./SocialSignIn";
import { customEvent } from "utils/AnalyticsEvent";
import { useData } from "providers/AuthProvider";

type LoginStep =
  | "social"
  | "emailSignUp"
  | "emailSignIn"
  | "emailRecovery"
  | "emailConfirm"
  | "emailRecoveryConfirm";

const LoginBottomSheet = ({
  label,
  open,
  initialStep,
  setOpen,
}: {
  label: string;
  open: boolean;
  initialStep?: LoginStep;
  setOpen: (open: boolean) => void;
}) => {
  const [step, setStep] = React.useState<LoginStep>(initialStep || "social");
  const [email, setEmail] = React.useState<string>("");

  const { setScreen } = useData();

  const handleClose = () => {
    if (step === "emailConfirm") setScreen("verification_handle");

    setOpen(false);
    setStep("social");
  };

  const content = {
    social: (
      <SocialSignIn
        handleClose={handleClose}
        handleEmail={() => setStep("emailSignUp")}
        label={label}
      />
    ),
    emailSignUp: (
      <EmailSignUp
        handleSignIn={() => {
          customEvent("oliv_auth_email_signup_link_existing", {});
          setStep("emailSignIn");
        }}
        handleConfirm={() => setStep("emailConfirm")}
        setEmail={setEmail}
      />
    ),
    emailSignIn: (
      <EmailSignIn
        handleClose={handleClose}
        handleSignUp={() => {
          customEvent("oliv_auth_email_login_signup", {});
          setStep("emailSignUp");
        }}
        handleConfirm={() => setStep("emailConfirm")}
        handleRecover={() => {
          customEvent("oliv_auth_email_login_link_forgot", {});
          setStep("emailRecovery");
        }}
        setEmail={setEmail}
      />
    ),
    emailRecovery: (
      <EmailRecovery
        handleRecoveryConfirm={() => setStep("emailRecoveryConfirm")}
        setEmail={setEmail}
      />
    ),
    emailConfirm: <EmailConfirm email={email} />,
    emailRecoveryConfirm: <EmailRecoveryConfirm email={email} />,
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={handleClose}
      PaperProps={{
        className: "rounded-t",
      }}
      sx={{ zIndex: 2000 }}
    >
      <div className="max-w-[650px] w-full mx-auto p-8">{content[step]}</div>
    </Drawer>
  );
};

export default LoginBottomSheet;
