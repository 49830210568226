import React from "react";
import { useLocation } from "react-router-dom";

import { Button, Snackbar, Stack, Typography } from "@mui/material";
import {
  GroupOutlined,
  InfoOutlined,
  LogoutOutlined,
  ShareOutlined,
} from "@mui/icons-material";

import { customEvent } from "utils/AnalyticsEvent";

const CommunityHeaderOverflowMain = ({
  communityId,
  setStep,
}: {
  communityId: string;
  setStep: (step?: DetailOverflowMenu) => void;
}) => {
  const location = useLocation();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleShareClick = () => {
    customEvent("com_dp_overflow_bs_button_share", { communityId });
    if (!navigator.share) {
      navigator.clipboard.writeText(window.location.href);
      setSnackbarOpen(true);
    } else {
      setStep();
      navigator.share({ url: location.pathname });
    }
  };

  const handleInfoClick = () => {
    customEvent("com_dp_overflow_bs_button_com_info", { communityId });
    setStep("info");
  };

  const handleMembersClick = () => {
    customEvent("com_dp_overflow_bs_button_members", { communityId });
    setStep("members");
  };

  const handleLeaveClick = () => {
    customEvent("com_dp_overflow_bs_button_leave", { communityId });
    setStep("leave");
  };

  const menuItems = [
    {
      label: "Share community",
      icon: <ShareOutlined />,
      handleClick: handleShareClick,
    },
    {
      label: "Community info",
      icon: <InfoOutlined />,
      handleClick: handleInfoClick,
    },
    {
      label: "View community members",
      icon: <GroupOutlined />,
      handleClick: handleMembersClick,
    },
    {
      label: "Leave community",
      icon: <LogoutOutlined />,
      handleClick: handleLeaveClick,
    },
  ];

  return (
    <>
      <Stack spacing={1} className="p-2">
        {menuItems.map((item, i) => (
          <Button
            key={i}
            variant="text"
            size="small"
            color="black"
            onClick={item.handleClick}
            className="justify-start rounded-none h-14"
            startIcon={item.icon}
          >
            <Typography variant="body1Bold" className="ml-3">
              {item.label}
            </Typography>
          </Button>
        ))}
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="URL copied to clipboard"
      />
    </>
  );
};

export default CommunityHeaderOverflowMain;
