import { Alert, Typography } from "@mui/material";
import { Business } from "data/models/business";

const BusinessHeaderClosed = ({ business }: { business: Business }) => {
  if (Boolean(business.closedPermanently)) {
    return (
      <div className="px-4 pt-2">
        <Alert variant="outlined" severity="error" className="rounded-[4px]">
          <Typography variant="body2Bold" color="error">
            Permanently Closed
          </Typography>
        </Alert>
      </div>
    );
  }

  if (Boolean(business.closedTemporarily)) {
    return (
      <div className="px-4 pt-2">
        <Alert variant="outlined" severity="error" className="rounded-[4px]">
          <Typography variant="body2Bold" color="error">
            Temporarily Closed
          </Typography>
        </Alert>
      </div>
    );
  }

  return null;
};

export default BusinessHeaderClosed;
