import { useFormikContext } from "formik";

import { Stack, TextField, Typography } from "@mui/material";

const CommentTextbox = () => {
  const { handleChange, values } = useFormikContext<BusinessCommentForm>();

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Write a review</Typography>
      <TextField
        multiline
        fullWidth
        variant="outlined"
        rows={5}
        id="comment"
        value={values.comment}
        onChange={handleChange}
        InputProps={{ className: "p-4" }}
        placeholder="What would you like other community members to know about this place?"
      />
    </Stack>
  );
};

export default CommentTextbox;
