import React from "react";

import OnboardingPage from "./OnboardingPage";
import WebviewPage from "./WebviewPage";
import WelcomePage from "./WelcomePage";
import { User } from "@supabase/supabase-js";

interface IWrapperPagesProps {
  user: User | null;
  wrapperScreen: OlivWrapperScreen;
}

const WrapperPages = ({
  user,
  wrapperScreen,
  children,
}: React.PropsWithChildren<IWrapperPagesProps>) => {
  const [screen, setScreen] = React.useState<OlivWrapperScreen>(wrapperScreen);

  React.useEffect(() => {
    setScreen(wrapperScreen);
  }, [wrapperScreen]);

  if (screen === "welcome")
    return <WelcomePage handleComplete={() => setScreen("main")} />;
  if (screen === "onboarding" && user != null)
    return (
      <OnboardingPage user={user} handleComplete={() => setScreen("main")} />
    );
  if (wrapperScreen === "webview") return <WebviewPage />;

  return <>{children}</>;
};

export default WrapperPages;
