import React from "react";

import { useCommunityState } from "providers/CommunityStateProvider";
import { useCommunity } from "data/hooks/communities";

const CommunitySwitcher = ({
  communityId,
  setPinned,
}: {
  communityId: string;
  setPinned: (pinned: boolean) => void;
}) => {
  const { setFilters, setMode, setListSorting, setMapSorting } =
    useCommunityState();
  const community = useCommunity(communityId);

  React.useEffect(() => {
    setFilters({ toggle: {}, single: {}, multi: {} });
    setMode(!community?.joined || community?.starName! ? "List" : "Feed");
    setListSorting("Popular");
    setMapSorting(undefined);
    setPinned(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communityId]);

  return <></>;
};

export default CommunitySwitcher;
