import { Stack } from "@mui/material";

import CommentCard from "./CommentCard";
import { Community } from "data/models/community";
import { Business } from "data/models/business";
import { useStarBusinessReviews } from "data/hooks/businessReviews";
import { Review } from "data/models/review";

const CommentCards = ({
  community,
  business,
}: {
  community: Community;
  business: Business;
}) => {
  const reviews = useStarBusinessReviews(community, business);

  if (reviews.length === 0) return null;

  return (
    <Stack spacing={2} className="px-4 pb-4">
      {reviews.map((review: Review, i: number) => (
        <div
          key={review.id}
          className="rounded-xl border border-solid border-filter-grey"
        >
          <div className="px-4 py-2">
            <CommentCard
              community={community}
              business={business}
              review={review}
            />
          </div>
        </div>
      ))}
    </Stack>
  );
};

export default CommentCards;
