import React from "react";
import clsx from "clsx";

import { useFormikContext } from "formik";

import { Badge, Box, IconButton, Stack, Typography } from "@mui/material";
import { AddAPhotoRounded, CancelRounded } from "@mui/icons-material";

import { customEvent } from "utils/AnalyticsEvent";

const MediaUploader = ({
  communityId,
  businessId,
}: {
  communityId: string;
  businessId: string;
}) => {
  const { setFieldValue, values } = useFormikContext<BusinessCommentForm>();

  const imageUploaderRef = React.useRef<HTMLInputElement>(null);

  const images = values.images || [];

  const handleClick = () => {
    customEvent("review_add_photo", { communityId, businessId });
    imageUploaderRef.current?.click();
  };

  const handleImageUpload = (files: FileList | null) => {
    if (!files) return;

    const images = values.images || [];
    const filesArray = Array.from(files);
    setFieldValue("images", [...images, ...filesArray]);
  };

  const handleRemove = (index: number) => {
    customEvent("review_delete_photo", { communityId, businessId });
    images.splice(index, 1);
    setFieldValue("images", images);
  };

  return (
    <Stack spacing={2}>
      <Typography variant="h6">Add a photo/video</Typography>
      <Stack spacing={2} direction="row" className="overflow-scroll h-[110px]">
        <Box
          onClick={handleClick}
          className={clsx(
            "flex items-center justify-center h-[100px] rounded-lg border border-dashed border-dark-grey",
            {
              "w-full": images.length === 0,
              "aspect-square": images.length > 0,
            }
          )}
        >
          <Typography color="lightGreen.main">
            <AddAPhotoRounded className="text-4xl" />
          </Typography>
        </Box>
        {images.map((image, i) => (
          <Badge
            key={image.name}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <IconButton color="lightGreen" onClick={() => handleRemove(i)}>
                <CancelRounded className="bg-white rounded-full" />
              </IconButton>
            }
            className="h-[100px] overflow-visible"
          >
            <img
              src={URL.createObjectURL(image)}
              alt={`comment-images-${i}`}
              className="h-full aspect-square object-cover rounded-lg"
            />
          </Badge>
        ))}
      </Stack>
      <input
        hidden
        multiple
        ref={imageUploaderRef}
        type="file"
        accept="image/*"
        id="images"
        onChange={(e) => handleImageUpload(e.target.files)}
      />
    </Stack>
  );
};

export default MediaUploader;
