import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Divider, Typography } from "@mui/material";

import CommunityFeedRecommendation from "./CommunityFeedRecommendation";
import CommunityFeedRecommendations from "./CommunityFeedRecommendations";
import CommunityFeedReview from "./CommunityFeedReview";
import Constants from "configs/Constants";
import { useData } from "providers/AuthProvider";
import { Community } from "data/models/community";
import { useCommunityFeed } from "data/hooks/feed";
import { Feed, FeedType } from "data/models/feed";

const CommunityEmptyFeedView = () => {
  return (
    <div className="flex items-center justify-center pt-10 px-4 text-center">
      <Typography variant="body2Bold">
        No places recommended for this community yet
      </Typography>
    </div>
  );
};

const CommunityFeedViewUnauth = () => {
  return (
    <div className="px-5 py-0 text-center">
      <Typography variant="body2Medium" color="darkGrey.main">
        Feed is exclusive to community members. Join this community to discover
        and engage with the ongoing discussions among our members.
      </Typography>
    </div>
  );
};

const CommunityFeedViewAuth = ({ community }: { community: Community }) => {
  const feed = useCommunityFeed(community);

  const [page, setPage] = React.useState(1);

  const aggregateRecommendations = (arr: Feed[], item: Feed) => {
    if (arr.length === 0) return [item];

    const prev = arr[arr.length - 1];

    if (
      item.type === FeedType.recommendation &&
      item.memberId === prev.memberId
    ) {
      if (prev.type === FeedType.recommendation) {
        arr.pop();

        arr.push(
          new Feed(
            prev.communityId,
            prev.businessId,
            prev.businessName,
            prev.businessImages,
            FeedType.recommendations,
            prev.createdAt,
            prev.memberId,
            prev.profileName,
            prev.profileColor,
            prev.profileImgKey,
            undefined,
            undefined,
            [prev, item]
          )
        );
        return arr;
      }

      if (prev.type === FeedType.recommendations) {
        prev.businesses!.push(item);
        return arr;
      }
    }

    arr.push(item);
    return arr;
  };

  const paginatedItems = feed
    .reduce(aggregateRecommendations, [])
    .slice(0, page * Constants.PAGE_SIZE);

  if (paginatedItems.length === 0) return <CommunityEmptyFeedView />;

  return (
    <InfiniteScroll
      dataLength={paginatedItems.length}
      next={() => setPage(page + 1)}
      hasMore={true}
      loader={<></>}
    >
      {paginatedItems.map((item, i) => (
        <CommunityFeedCard
          key={i}
          community={community}
          item={item}
          index={i}
        />
      ))}
    </InfiniteScroll>
  );
};

const CommunityFeedCardDivider = () => (
  <div className="py-8">
    <Divider />
  </div>
);

const CommunityFeedCard = ({
  community,
  item,
  index,
}: {
  community: Community;
  item: Feed;
  index: number;
}) => {
  return (
    <>
      {index > 0 && <CommunityFeedCardDivider />}
      {item.type === FeedType.recommendations && (
        <CommunityFeedRecommendations
          community={community}
          feed={item}
          index={index}
        />
      )}
      {item.type === FeedType.recommendation && (
        <CommunityFeedRecommendation
          community={community}
          feed={item}
          index={index}
        />
      )}
      {item.type === FeedType.review && (
        <CommunityFeedReview community={community} feed={item} index={index} />
      )}
    </>
  );
};

const CommunityFeedView = ({ community }: { community: Community }) => {
  const { user } = useData();

  return user != null && community.joined ? (
    <CommunityFeedViewAuth community={community} />
  ) : (
    <CommunityFeedViewUnauth />
  );
};

export default CommunityFeedView;
