import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@mui/material";

import LoginBottomSheet from "components/LoginBottomSheet";
import { getCommunityJoinUrl } from "configs/Paths";
import { useData } from "providers/AuthProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityJoinAction = ({
  community,
  eventName,
}: {
  community: Community;
  eventName: string;
}) => {
  const navigate = useNavigate();
  const { user } = useData();

  const [bottomSheetOpen, setBottomSheetOpen] = React.useState(false);

  const communityId = community.urlName;

  const handleClick = () => {
    if (user != null) {
      customEvent(eventName, { communityId, userType: "nonMember" });
      navigate(getCommunityJoinUrl(communityId));
    } else {
      customEvent(eventName, { communityId, userType: "unauth" });
      setBottomSheetOpen(true);
    }
  };

  return (
    <>
      <Button
        fullWidth
        disableFocusRipple
        variant="contained"
        onClick={handleClick}
      >
        <Typography variant="body1ExtraBold">Join Community</Typography>
      </Button>
      <LoginBottomSheet
        open={bottomSheetOpen}
        setOpen={setBottomSheetOpen}
        label="To join this community, sign up or log In."
      />
    </>
  );
};

export default CommunityJoinAction;
