import React from "react";
import debounce from "lodash.debounce";

import { Button, Stack, Typography } from "@mui/material";
import { AddBusinessOutlined } from "@mui/icons-material";

import BusinessSearchResultsCards from "./BusinessSearchResultsCards";
import Constants from "configs/Constants";
import { search } from "data/apis/businesses";
import { customEvent } from "utils/AnalyticsEvent";
import { Business } from "data/models/business";
import { useCommunity } from "data/hooks/communities";
import { useData } from "providers/AuthProvider";

const BusinessSearchResults = ({
  communityId,
  query,
}: {
  communityId: string;
  query: string;
}) => {
  const { user } = useData();

  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  const [results, setResults] = React.useState<Business[] | undefined>();

  const getResults = React.useRef(
    debounce(async (value: string) => {
      if (!value) {
        setResults(undefined);
      } else {
        const businesses = await search(community, value, user?.location);
        setResults(businesses);
      }
    }, 0)
  );

  React.useEffect(() => {
    if (!query) setResults(undefined);
    getResults.current(query);
  }, [query]);

  const handleFeedback = () => {
    customEvent("search_button_feedback", { communityId });
    window.open(Constants.MISSING_BUSINESS_FORM, "_blank");
  };

  const handleRecommend = (businessId: string, isRecommended: boolean) => {
    setResults(
      (results || []).map((r) => {
        if (r.id === businessId) {
          r.recommended = isRecommended;
          r.recommendationsCount += isRecommended ? 1 : -1;
        }
        return r;
      })
    );
  };

  if (!results) return <></>;

  const recommended = results.filter((r) => r.recommendationsCount > 0);
  const notRecommended = results.filter((r) => r.recommendationsCount === 0);

  return (
    <Stack spacing={1}>
      {results.length > 0 ? (
        <>
          <BusinessSearchResultsCards
            label="Recommended by community"
            community={community}
            businesses={recommended}
            handleRecommend={handleRecommend}
          />
          <BusinessSearchResultsCards
            label="Not yet recommended by community"
            community={community}
            businesses={notRecommended}
            handleRecommend={handleRecommend}
          />
        </>
      ) : (
        <Typography variant="body2" className="text-center pt-4">
          Your search, <b>{query}</b>, did not match any business.
        </Typography>
      )}
      {query !== "" && (
        <Stack spacing={3} sx={{ p: "16px", alignItems: "center" }}>
          <Typography variant="body2" className="text-center">
            Can’t find what you’re looking for? Please let us know what is
            missing.
          </Typography>
          <Button
            variant="contained"
            color="lightGrey"
            size="medium"
            startIcon={<AddBusinessOutlined />}
            onClick={handleFeedback}
            className="w-fit"
          >
            <Typography variant="body2ExtraBold">
              Report a missing business
            </Typography>
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default BusinessSearchResults;
