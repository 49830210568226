import { plainToInstance } from "class-transformer";
import { supabase } from "configs/Supabase";
import { Business } from "data/models/business";
import { BusinessHours } from "data/models/businessHours";
import { Community } from "data/models/community";

export const fetch = async (
  community: Community,
  businessId: string
): Promise<Business | undefined> => {
  try {
    const params = { community_id: community.id, business_id: businessId };
    const { data, error } = await supabase.rpc("get_business", params);

    if (error) {
      throw error;
    }

    return plainToInstance(Business, data as any);
  } catch (error) {
    return;
  }
};

export const fetchList = async (community: Community): Promise<Business[]> => {
  try {
    const params = { community_id: community.id, start_index: 0, count: null };
    const { data, error } = await supabase.rpc("list_businesses", params);

    if (error) {
      throw error;
    }

    return plainToInstance(Business, data as any[]);
  } catch (error) {
    return [];
  }
};

export const search = async (
  community: Community,
  query: string,
  location?: LatLngLocation
): Promise<Business[]> => {
  try {
    if (query.length === 0) return [];

    let latitude = 37.3861;
    let longitude = -122.0839;
    if (location) {
      latitude = location.lat;
      longitude = location.lng;
    }

    const params = {
      community_id: community.id,
      query: `${query}:*`.replaceAll(" ", "+"),
      latitude,
      longitude,
    };
    const { data, error } = await supabase.rpc("search_businesses", params);

    if (error) {
      throw error;
    }

    return plainToInstance(Business, data as any[]);
  } catch (error) {
    return [];
  }
};

export const fetchBusinessHours = async (
  business: Business
): Promise<BusinessHours> => {
  try {
    const params = { business_id: business.id };
    const { data, error } = await supabase.rpc("get_business_hours", params);

    if (error) {
      throw error;
    }

    return plainToInstance(BusinessHours, data as any);
  } catch (error) {
    return new BusinessHours();
  }
};

export const addRecommendation = async (
  community: Community,
  business: Business
) => {
  try {
    if (!community.memberId) return;
    const input = {
      community_id: community.id,
      business_id: business.id,
      member_id: community.memberId,
    };

    const { error } = await supabase.from("recommendation").insert(input);

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const removeRecommendation = async (
  community: Community,
  business: Business
) => {
  try {
    if (!community.memberId) return;

    const { error } = await supabase.from("recommendation").delete().match({
      community_id: community.id,
      business_id: business.id,
      member_id: community.memberId,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const addBookmark = async (community: Community, business: Business) => {
  try {
    if (!community.memberId) return;
    const input = {
      community_id: community.id,
      business_id: business.id,
      member_id: community.memberId,
    };
    const { error } = await supabase.from("bookmark").insert(input);

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const removeBookmark = async (
  community: Community,
  business: Business
) => {
  try {
    if (!community.memberId) return;

    const { error } = await supabase.from("bookmark").delete().match({
      community_id: community.id,
      business_id: business.id,
      member_id: community.memberId,
    });

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};
