import { Paths } from "configs/Paths";
import { supabase } from "configs/Supabase";

export const socialSignIn = async (provider: string, redirect: string) => {
  const redirectUrl = redirect === Paths.discover ? Paths.main : redirect;
  localStorage.setItem("redirect_url", redirectUrl);

  if (provider === "google") {
    supabase.auth.signInWithOAuth({
      provider: "google",
    });
  }
};

export const emailSignUp = async (email: string, password: string) => {
  try {
    const { error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        emailRedirectTo: `${process.env.REACT_APP_SITE_URL}/verify`,
      },
    });

    if (error) throw error;
  } catch (e) {
    throw e;
  }
};

export const emailSignIn = async (email: string, password: string) => {
  try {
    const { error } = await supabase.auth.signInWithPassword({
      email,
      password,
    });

    if (error) throw error;
  } catch (e) {
    throw e;
  }
};

export const signOutUser = async () => {
  await supabase.auth.signOut();
};

export const emailConfirm = async (email: string) => {
  await supabase.auth.resend({
    type: "signup",
    email,
    options: {
      emailRedirectTo: `${process.env.REACT_APP_SITE_URL}/verify`,
    },
  });
};

export const emailRecovery = async (email: string) => {
  try {
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${process.env.REACT_APP_SITE_URL}/reset-password`,
    });

    if (error) throw error;
  } catch (e) {
    throw e;
  }
};

export const forgotPassword = async (newPassword: string) => {
  try {
    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) throw error;
  } catch (e) {
    console.log(e);
    throw e;
  }
};
