import { useNavigate } from "react-router-dom";

import AddIcon from "@mui/icons-material/AddCircleOutlined";
import { Paths } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";

const CommunityAddCard = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    customEvent("dash_box_add", {});
    navigate(Paths.discover);
  };

  return (
    <div
      onClick={handleClick}
      className="bg-button-grey flex items-center justify-center aspect-square border border-solid border-light-grey rounded-xl"
    >
      <AddIcon className="text-dark-grey text-[48px]" />
    </div>
  );
};

export default CommunityAddCard;
