import { useNavigate } from "react-router-dom";

import { Typography } from "@mui/material";

import { Paths } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";

const Disclaimer = () => {
  const navigate = useNavigate();

  const handleClick = (path: string, event: string) => {
    customEvent(event, {});
    navigate(path, { state: { back: true } });
  };

  return (
    <Typography
      variant="body4"
      color="lightGreen.main"
      className="pt-2 text-center"
    >
      By clicking Continue with Google, Facebook, or Email, you agree to
      our&nbsp;
      <span
        className="underline"
        onClick={() =>
          handleClick(Paths.terms_of_use, "oliv_auth_email_signup_link_tou")
        }
      >
        Terms of Use
      </span>
      &nbsp;and&nbsp;
      <span
        className="underline"
        onClick={() =>
          handleClick(Paths.privacy_policy, "oliv_auth_email_signup_link_pp")
        }
      >
        Privacy Policy
      </span>
    </Typography>
  );
};

export default Disclaimer;
