import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

const BottomSheetText = (props: IBottomSheetProps) => {
  const { label, sublabel } = props;

  return (
    <Stack spacing={2} sx={{ p: 2 }}>
      <Typography variant="h5">{label}</Typography>
      <Typography variant="body2">{sublabel}</Typography>
    </Stack>
  );
};

export default BottomSheetText;
