import React from "react";
import { useLocation } from "react-router-dom";

import {
  Button,
  IconButton,
  InputAdornment,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  CancelRounded,
  CloseOutlined,
  SearchOutlined,
  ShareOutlined,
} from "@mui/icons-material";

import ProfileAvatar from "components/ProfileAvatar";
import { customEvent } from "utils/AnalyticsEvent";
import { Member } from "data/models/member";
import { Community } from "data/models/community";
import { useCommunityMembers } from "data/hooks/members";

const InputStartIcon = () => (
  <InputAdornment position="start">
    <SearchOutlined sx={{ fontSize: "16px" }} />
  </InputAdornment>
);

const MemberCards = ({
  community,
  members,
  query,
}: {
  community: Community;
  members: Member[];
  query: string;
}) => {
  return (
    <Stack spacing={2} className="pb-6 overflow-y-scroll h-[70dvh]">
      {members.map((member) => (
        <MemberCard key={member.profileName} member={member} />
      ))}
      {members.length === 0 && (
        <MemberCardsEmpty community={community} query={query} />
      )}
    </Stack>
  );
};

const MemberCardsEmpty = ({
  community,
  query,
}: {
  community: Community;
  query: string;
}) => {
  const location = useLocation();
  const communityId = community.urlName;

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleClick = () => {
    customEvent("com_dp_com_members_bs_button_share", { communityId });
    if (!navigator.share) {
      navigator.clipboard.writeText(window.location.href);
      setSnackbarOpen(true);
    } else {
      navigator.share({ url: location.pathname });
    }
  };

  return (
    <>
      <Stack spacing={2} className="items-center text-center p-4">
        <Typography variant="body2">
          Your search, <b>{query}</b> did not match any community members.
        </Typography>
        <Typography variant="body2" className="pt-6">
          Invite your friends to this community!
        </Typography>
        <Button
          variant="contained"
          color="lightGrey"
          size="small"
          startIcon={<ShareOutlined />}
          className="p-3 w-fit"
          onClick={handleClick}
        >
          <Typography variant="body3Bold">Share community</Typography>
        </Button>
      </Stack>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="URL copied to clipboard"
      />
    </>
  );
};

const MemberCard = ({ member }: { member: Member }) => {
  return (
    <Stack spacing={2} direction="row" className="items-center">
      <ProfileAvatar profile={member} size={64} />
      <Typography variant="body1">{member.profileName}</Typography>
    </Stack>
  );
};

const CommunityHeaderOverflowMember = ({
  community,
  close,
}: {
  community: Community;
  close: () => void;
}) => {
  const [query, setQuery] = React.useState("");

  const communityId = community.urlName;

  const members = useCommunityMembers(community);
  const count = members.length;

  React.useEffect(() => {
    customEvent("com_dp_com_members", { communityId, count });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.toLowerCase());
  };

  const handleClear = () => {
    setQuery("");
  };

  const filteredMembers = members.filter((m) =>
    m.profileName.toLowerCase().includes(query)
  );

  return (
    <Stack spacing={2} className="p-6 pb-0 overflow-hidden">
      <div className="flex justify-between items-center">
        <Typography variant="h5">Community members ({count})</Typography>
        <IconButton onClick={close} className="-mr-[12px]">
          <CloseOutlined />
        </IconButton>
      </div>
      <TextField
        fullWidth
        value={query}
        size="small"
        placeholder="Search members"
        InputProps={{
          startAdornment: <InputStartIcon />,
          className: "rounded-3xl text-[13px]",
          endAdornment: query ? (
            <IconButton onClick={handleClear} className="p-0">
              <CancelRounded className="text-dark-grey text-xl" />
            </IconButton>
          ) : null,
        }}
        onChange={handleSearch}
        className="mt-4 mb-6"
      />
      <MemberCards
        community={community}
        members={filteredMembers}
        query={query}
      />
    </Stack>
  );
};

export default CommunityHeaderOverflowMember;
