import * as ReviewsApi from "data/apis/reviews";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Business } from "data/models/business";
import { Community } from "data/models/community";
import { Review } from "data/models/review";

export const useBusinessReviews = (
  community: Community,
  business: Business
) => {
  const { data } = useQuery({
    queryKey: ["reviews", community.id, business.id],
    queryFn: async () => await ReviewsApi.fetch(community, business),
  });

  return data || [];
};

export const useStarBusinessReviews = (
  community: Community,
  business: Business
) => {
  const { data } = useQuery({
    queryKey: ["starReviews", community.id, business.id],
    queryFn: async () => {
      if (!community.starName) return [];

      const response = await ReviewsApi.fetch(community, business);
      return (response || []).filter((r) => r.isModerator);
    },
  });

  return data || [];
};

export const useAddReview = (community: Community, business: Business) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      comment,
      images,
    }: {
      comment?: string;
      images?: File[];
    }) =>
      await ReviewsApi.addReview(community, business, images || [], comment),
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: ["reviews", community.id, business.id],
      });
      queryClient.refetchQueries({
        queryKey: ["feed", community.id],
      });
    },
  });
};

export const useRemoveReview = (
  community: Community,
  business: Business,
  review: Review
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async () => await ReviewsApi.removeReview(review),
    onSuccess: async () => {
      queryClient.refetchQueries({
        queryKey: ["reviews", community.id, business.id],
      });
      queryClient.refetchQueries({
        queryKey: ["feed", community.id],
      });
    },
  });
};
