import * as FeedApi from "data/apis/feed";
import { useQuery } from "@tanstack/react-query";
import { Community } from "data/models/community";

export const useCommunityFeed = (community: Community) => {
  const { data: feed } = useQuery({
    queryKey: ["feed", community.id],
    queryFn: async () => await FeedApi.fetch(community),
  });

  return feed || [];
};
