import React from "react";

import landingPageBackground from "assets/LandingPage/landing_background.jpg";

const PageWrapper = ({ children }: React.PropsWithChildren) => (
  <div
    className="h-[100dvh] flex justify-center items-center bg-primary bg-cover bg-center"
    style={{ backgroundImage: `url(${landingPageBackground})` }}
  >
    {children}
  </div>
);

export default PageWrapper;
