import React from "react";
import { useLocation } from "react-router-dom";

import { Paths } from "configs/Paths";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    if (pathname === Paths.main || pathname === Paths.discover) {
      window.scrollTo(0, 0);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
