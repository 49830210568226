import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { IconButton } from "@mui/material";
import { CloseRounded, MenuRounded } from "@mui/icons-material";

import NavDrawer from "components/OlivAppBar/NavDrawer";
import { Paths, getCommunityUrl, getCommunityBusinessUrl } from "configs/Paths";
import { customEvent } from "utils/AnalyticsEvent";

const AppbarLeftAction = ({
  communityId,
  businessId,
  showClose,
  hideCta,
}: {
  communityId?: string;
  businessId?: string;
  showClose?: string;
  hideCta?: boolean;
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [navOpen, setNavOpen] = React.useState(false);

  const handleMenuClick = () => {
    customEvent("top_nav_icon_drawer", {});
    setNavOpen(true);
  };

  const handleClose = () => {
    if (showClose) customEvent(showClose, { communityId });
    if (location.state?.back) {
      navigate(-1);
    } else {
      if (communityId && businessId) {
        navigate(getCommunityBusinessUrl(communityId, businessId), {
          replace: true,
        });
      } else if (communityId) {
        navigate(getCommunityUrl(communityId), { replace: true });
      } else {
        navigate(Paths.main, { replace: true });
      }
    }
  };

  if (hideCta) return null;

  return showClose ? (
    <IconButton onClick={handleClose} className="-ml-4" color="black">
      <CloseRounded color="inherit" />
    </IconButton>
  ) : (
    <>
      <IconButton onClick={handleMenuClick} className="-ml-3">
        <MenuRounded color="primary" sx={{ fontSize: "24px" }} />
      </IconButton>
      <NavDrawer open={navOpen} setOpen={setNavOpen} />
    </>
  );
};

export default AppbarLeftAction;
