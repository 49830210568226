import React from "react";
import { motion } from "framer-motion";

import { Button, Stack, Typography } from "@mui/material";

import PageWrapper from "./PageWrapper";
import logo from "assets/logo_white.png";

const WelcomePage = ({ handleComplete }: { handleComplete: () => void }) => {
  return (
    <PageWrapper>
      <Stack sx={{ p: "85px 32px" }}>
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          <div className="flex justify-start pb-12">
            <img src={logo} alt="Oliv" height="60px" />
          </div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          <Typography
            variant="h3"
            color="white.main"
            className="text-shadow-sm mb-5"
          >
            Get recommendations from communities you trust
          </Typography>
          <Typography
            variant="h6"
            color="white.main"
            className="text-shadow-sm mb-20 font-bold"
          >
            Say goodbye to irrelevant recommendations and fake reviews
          </Typography>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.5, duration: 0.5 }}
        >
          <Button
            fullWidth
            variant="contained"
            color="white"
            onClick={handleComplete}
            className="h-12"
          >
            <Typography variant="body1ExtraBold" color="lightGreen.main">
              Let's get started
            </Typography>
          </Button>
        </motion.div>
      </Stack>
    </PageWrapper>
  );
};

export default WelcomePage;
