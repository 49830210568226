import React from "react";

import { Button, Modal, Stack, Typography } from "@mui/material";
import { useLeaveCommunity } from "data/hooks/members";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";
import { Paths } from "configs/Paths";
import { useNavigate } from "react-router-dom";

const CommunityHeaderOverflowLeave = ({
  community,
  setStep,
}: {
  community: Community;
  setStep(step?: DetailOverflowMenu): void;
}) => {
  const navigate = useNavigate();
  const leave = useLeaveCommunity(community);

  const communityId = community.urlName;

  const handleCancelLeave = () => {
    customEvent("com_dp_leave_m_button_cancel", { communityId });
    setStep();
  };

  const handleConfirmLeave = () => {
    customEvent("com_dp_leave_m_button_confirm", { communityId });
    leave.mutate();
    navigate(Paths.main);
    setStep();
  };

  return (
    <>
      <Modal
        open={true}
        onClose={() => setStep()}
        className="flex items-center justify-center"
      >
        <Stack spacing={1} className=" w-10/12 p-6 bg-white rounded-xl">
          <Typography variant="h5" className="pb-4">
            Are you sure you want to leave this community?
          </Typography>
          <Button
            fullWidth
            disableFocusRipple
            variant="contained"
            onClick={handleConfirmLeave}
          >
            <Typography variant="body1ExtraBold">Confirm</Typography>
          </Button>
          <Button variant="text" color="black" onClick={handleCancelLeave}>
            <Typography variant="body1ExtraBold">Cancel</Typography>
          </Button>
        </Stack>
      </Modal>
    </>
  );
};

export default CommunityHeaderOverflowLeave;
