import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, IconButton, Stack, Typography } from "@mui/material";
import { Close, ErrorOutlined } from "@mui/icons-material";

import { Paths } from "configs/Paths";

import logo from "assets/logo_w_bg.png";
import WrapperPages from "pages/WrapperPages";
import { supabase } from "configs/Supabase";
import { User } from "data/models/user";
import * as UserLocationService from "services/UserLocationService";
import { useQueryClient } from "@tanstack/react-query";

interface IDataContext {
  user?: User | null;
  setScreen: (screen: OlivWrapperScreen) => void;
}

const DataContext = React.createContext<IDataContext | undefined>(undefined);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [user, setUser] = React.useState<User | null | undefined>();
  const [screen, setScreen] = React.useState<OlivWrapperScreen>("main");
  const [showDownload, setShowDownload] = React.useState(true);

  React.useEffect(() => {
    const listener = supabase.auth.onAuthStateChange((event, session) => {
      if (event === "INITIAL_SESSION") {
        getUser();
      } else if (event === "SIGNED_IN") {
        getUser(true);
      } else if (event === "PASSWORD_RECOVERY") {
        getUser();
      } else if (event === "SIGNED_OUT") {
        setUser(null);
        queryClient.removeQueries();
        navigate(Paths.discover);
      }
    });

    const getUser = async (showOnboaring: boolean = false) => {
      const [auth, location] = await Promise.all([
        supabase.auth.getSession(),
        UserLocationService.fetch(),
      ]);

      if (!auth.data.session) {
        setUser(auth.data.session);
        return;
      }

      const signedInUser = auth.data.session.user as User;
      signedInUser.location = location;

      setUser(signedInUser);

      if (showOnboaring && !signedInUser.user_metadata.onboarding_complete) {
        setScreen("onboarding");
      }
    };

    return () => {
      listener.data.subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user === undefined) return null;

  return (
    <WrapperPages wrapperScreen={screen} user={user}>
      <DataContext.Provider value={{ user, setScreen }}>
        {children}
      </DataContext.Provider>
      {screen === "verification_handle" ? (
        <div className="fixed z-[3000] bg-primary w-full left-0 bottom-0 p-4 rounded-none flex items-center justify-center">
          <ErrorOutlined fontSize="small" className="text-white mr-2" />
          <Typography variant="body2Bold" color="white.main">
            Please verify your account via email we've sent
          </Typography>
        </div>
      ) : (
        showDownload && (
          <Stack
            onClick={() => {
              window.open(`https://download.oliv.org`, "_blank");
            }}
            direction="row"
            spacing={2}
            className="fixed z-[2000] bg-dark-green w-full left-0 bottom-0 py-4 pl-4 pr-2 rounded-none flex items-center justify-center"
          >
            <img
              src={logo}
              alt="Download Oliv"
              className="rounded-xl h-[60px] mr-2 aspect-square"
            />
            <Typography variant="body2Bold" color="white.main">
              Get exclusive deals and real-time updates. Download our app now!
            </Typography>
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                setShowDownload(false);
              }}
            >
              <Close fontSize="medium" className="text-white" />
            </IconButton>
          </Stack>
        )
      )}
    </WrapperPages>
  );
};

export const useData = () => {
  return React.useContext(DataContext) as IDataContext;
};
