import React from "react";

import { AvatarGroup, Typography } from "@mui/material";

import BusinessHeaderLikesMembers from "./BusinessHeaderLikesMembers";
import ProfileAvatar from "components/ProfileAvatar";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";
import { Business } from "data/models/business";
import { useBusinessRecommendations } from "data/hooks/businessRecommendations";

const BusinessHeaderLikes = ({
  community,
  business,
}: {
  community: Community;
  business: Business;
}) => {
  const recommendations = useBusinessRecommendations(community, business);

  const [open, setOpen] = React.useState(false);

  if (recommendations.length === 0) return <></>;

  const handleClick = () => {
    customEvent("bus_dp_recommended_by", {
      communityId: community.id,
      businessId: business.id,
    });
    setOpen(true);
  };

  const profileNames = () => {
    const leader = recommendations[0].member.profileName;
    if (recommendations.length === 1) return leader;
    if (recommendations.length === 2) return `${leader} and 1 other`;
    return `${leader} and ${recommendations.length - 1} others`;
  };

  return (
    <>
      <div onClick={handleClick}>
        <AvatarGroup
          max={7}
          sx={{
            justifyContent: "start",
            mb: 0.5,
            "& .MuiAvatar-root": { width: 35, height: 35 },
          }}
        >
          {recommendations.map((r) => (
            <ProfileAvatar key={r.id} profile={r.member} size={35} />
          ))}
        </AvatarGroup>
        <Typography variant="body3Bold">{profileNames()}</Typography>
        <Typography variant="body3">
          &nbsp;recommended {business.name}
        </Typography>
      </div>
      <BusinessHeaderLikesMembers
        community={community}
        business={business}
        open={open}
        setOpen={setOpen}
      />
    </>
  );
};

export default BusinessHeaderLikes;
