import React from "react";
import { Outlet, useParams } from "react-router-dom";

import { Dialog, Divider } from "@mui/material";

import BusinessComments from "./BusinessComments";
import BusinessDetailInfo from "./BusinessDetailInfo";
import BusinessHeader from "./BusinessHeader";
import OlivAppBar from "components/OlivAppBar";
import {
  BusinessDetailLoading,
  CommentsLoading,
  HeaderLoading,
} from "./loading";
import { customEvent } from "utils/AnalyticsEvent";

const BusinessDetail = () => {
  const { communityId, businessId } = useParams();

  const infoRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    customEvent("bus_dp_page_view", { communityId, businessId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!communityId || !businessId) return null;

  const scrollToInfoRef = () => {
    infoRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Dialog open={true} fullScreen className="bg-white">
      <OlivAppBar showClose="bus_dp_close" communityId={communityId} />
      <React.Suspense fallback={<HeaderLoading />}>
        <BusinessHeader
          communityId={communityId}
          businessId={businessId}
          scrollToInfoRef={scrollToInfoRef}
        />
      </React.Suspense>
      <Divider />
      <React.Suspense fallback={<CommentsLoading />}>
        <BusinessComments communityId={communityId} businessId={businessId} />
      </React.Suspense>
      <React.Suspense fallback={<BusinessDetailLoading />}>
        <div ref={infoRef}>
          <BusinessDetailInfo
            communityId={communityId}
            businessId={businessId}
          />
        </div>
      </React.Suspense>
      <Outlet />
    </Dialog>
  );
};

export default BusinessDetail;
