import React from "react";

import { Dialog } from "@mui/material";

import LikeChallengeSearchBox from "./LikeChallengeSearchBox";
import LikeChallengeSearchResults from "./LikeChallengeSearchResults";
import { Business } from "data/models/business";
import { Community } from "data/models/community";

const LikeChallengeSearch = ({
  community,
  likedBusinesses,
  handleLike,
}: {
  community: Community;
  likedBusinesses: Business[];
  handleLike: (business?: Business) => void;
}) => {
  const [query, setQuery] = React.useState("");

  return (
    <Dialog fullScreen open={true} className="bg-white">
      <div className="min-h-full bg-line-grey p-3">
        <LikeChallengeSearchBox
          query={query}
          setQuery={setQuery}
          handleLike={handleLike}
        />
        <LikeChallengeSearchResults
          community={community}
          likedBusinesses={likedBusinesses}
          query={query}
          handleLike={handleLike}
        />
      </div>
    </Dialog>
  );
};

export default LikeChallengeSearch;
