import { Expose, Type } from "class-transformer";
import { Member } from "./member";

export class Recommendation {
  id!: number;
  @Expose({ name: "created_at" }) @Type(() => Date) createdAt!: Date;
  @Expose({ name: "profile_name" }) profileName!: string;
  @Expose({ name: "profile_color" }) profileColor!: string;
  @Expose({ name: "is_moderator" }) isModerator!: boolean;
  badges!: string[];
  @Expose({ name: "profile_img_key" }) profileImgKey?: string;

  constructor(
    id: number,
    createdAt: Date,
    profileName: string,
    profileColor: string,
    isModerator: boolean,
    badges: string[],
    profileImgKey?: string
  ) {
    this.id = id;
    this.createdAt = createdAt;
    this.profileName = profileName;
    this.profileColor = profileColor;
    this.isModerator = isModerator;
    this.badges = badges;
    this.profileImgKey = profileImgKey;
  }

  get member(): Member {
    return new Member(
      this.profileName!,
      this.profileColor!,
      false,
      [],
      this.profileImgKey
    );
  }
}
