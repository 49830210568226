import React from "react";
import { useFormikContext } from "formik";

import { Grid, Stack, Typography } from "@mui/material";

import { Business } from "data/models/business";
import { useBusiness } from "data/hooks/business";
import { useCommunity } from "data/hooks/communities";

const BusinessImage = ({ business }: { business: Business }) => {
  return (
    <img
      src={business.image}
      alt={business.name}
      className="aspect-square w-full rounded-lg border border-solid border-light-grey"
    />
  );
};

const BusinessMetadata = ({ business }: { business: Business }) => {
  return (
    <Stack sx={{ pr: 1 }}>
      <Typography variant="body1Bold">{business.name}</Typography>
      <Typography variant="body3" color="lightGreen.main">
        {business.city}
      </Typography>
    </Stack>
  );
};

const BusinessHeader = ({
  communityId,
  businessId,
}: {
  communityId: string;
  businessId: string;
}) => {
  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  const business = useBusiness(community, businessId);
  if (!business) throw new Error("Business not found");

  const { setFieldValue } = useFormikContext<BusinessCommentForm>();

  React.useEffect(() => {
    setFieldValue("profile", community.member);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <BusinessImage business={business} />
        </Grid>
        <Grid item xs={10} className="flex items-center">
          <BusinessMetadata business={business} />
        </Grid>
      </Grid>
    </div>
  );
};

export default BusinessHeader;
