import React from "react";

import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CancelRounded, SearchOutlined } from "@mui/icons-material";

import CommunityCard from "./CommunityCard";
import { useCommunities } from "data/hooks/communities";
import { Community } from "data/models/community";

const InputStartIcon = () => (
  <InputAdornment position="start">
    <SearchOutlined sx={{ fontSize: "16px" }} />
  </InputAdornment>
);

const CommunityCards = () => {
  const [query, setQuery] = React.useState("");

  const communities = useCommunities();
  const nonMemberCommunities = communities.filter(
    (c) => !c.joined && c.name.toLowerCase().includes(query.toLowerCase())
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const handleClear = () => {
    setQuery("");
  };

  return (
    <Stack spacing={2}>
      <TextField
        value={query}
        size="small"
        placeholder="Search community name, topic, or location"
        InputProps={{
          startAdornment: <InputStartIcon />,
          className: "rounded-3xl text-[13px]",
          endAdornment: query ? (
            <IconButton onClick={handleClear} className="p-0">
              <CancelRounded className="text-dark-grey text-xl" />
            </IconButton>
          ) : null,
        }}
        onChange={handleSearch}
      />
      {nonMemberCommunities.length > 0 ? (
        nonMemberCommunities.map((community: Community, i: number) => (
          <CommunityCard
            key={community.urlName}
            community={community}
            index={i}
          />
        ))
      ) : (
        <Typography variant="body2" className="py-6 text-center">
          Your search, <b>{query}</b>, did not match any Community.
        </Typography>
      )}
    </Stack>
  );
};

export default CommunityCards;
