import InternalError from "pages/InternalError";
import NotFound from "pages/NotFound";
import React, { ErrorInfo, ReactNode } from "react";

interface Props {
  children?: ReactNode;
}

interface State {
  is404: boolean;
  is500: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: React.PropsWithChildren) {
    super(props);
    this.state = { is404: false, is500: false };
  }

  public static getDerivedStateFromError(error: Error): State {
    if (error.message?.includes("not found")) {
      return { is404: true, is500: false };
    } else {
      console.log(error);
      return { is404: false, is500: true };
    }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error("Uncaught error:", error, errorInfo);
  }

  public render() {
    if (this.state.is500) {
      return <InternalError />;
    }
    if (this.state.is404) {
      return <NotFound />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
