import * as BusinessesApi from "data/apis/businesses";
import { useQuery } from "@tanstack/react-query";
import { Business } from "data/models/business";

export const useBusinessHours = (business: Business) => {
  const { data } = useQuery({
    queryKey: ["businessesHours", business.id],
    queryFn: async () => await BusinessesApi.fetchBusinessHours(business),
  });

  return data;
};
