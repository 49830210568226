import {
  ThemeProvider,
  SimplePaletteColorOptions,
  TypographyStyle,
  createTheme,
} from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

declare module "@mui/material/styles" {
  interface CustomPalette {
    darkGreen: SimplePaletteColorOptions;
    lightGreen: SimplePaletteColorOptions;
    black: SimplePaletteColorOptions;
    darkGrey: SimplePaletteColorOptions;
    buttonGrey: SimplePaletteColorOptions;
    lightGrey: SimplePaletteColorOptions;
    white: SimplePaletteColorOptions;
  }
  interface Palette extends CustomPalette {}
  interface PaletteOptions extends CustomPalette {}

  interface TypographyVariants {
    h4Small: TypographyStyle;
    body1Medium: TypographyStyle;
    body1Bold: TypographyStyle;
    body1ExtraBold: TypographyStyle;
    body2Bold: TypographyStyle;
    body2Medium: TypographyStyle;
    body2ExtraBold: TypographyStyle;
    body3: TypographyStyle;
    body3Bold: TypographyStyle;
    body3ExtraBold: TypographyStyle;
    body4: TypographyStyle;
  }

  interface TypographyVariantsOptions {
    h4Small: TypographyStyle;
    body1Medium: TypographyStyle;
    body1Bold: TypographyStyle;
    body1ExtraBold: TypographyStyle;
    body2Medium: TypographyStyle;
    body2Bold: TypographyStyle;
    body2ExtraBold: TypographyStyle;
    body3: TypographyStyle;
    body3Bold: TypographyStyle;
    body3ExtraBold: TypographyStyle;
    body4: TypographyStyle;
  }
}

declare module "@mui/material/AppBar" {
  interface AppBarPropsColorOverrides {
    white: true;
  }
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides {
    darkGreen: true;
    lightGreen: true;
    black: true;
    darkGrey: true;
    lightGrey: true;
  }
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    darkGreen: true;
    lightGreen: true;
    black: true;
    buttonGrey: true;
    darkGrey: true;
    filterGrey: true;
    lightGrey: true;
    white: true;
    googleBlue: true;
  }
}

declare module "@mui/material/Fab" {
  interface FabPropsColorOverrides {
    darkGreen: true;
    lightGreen: true;
    black: true;
    darkGrey: true;
    lightGrey: true;
    white: true;
  }
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides {
    darkGreen: true;
    lightGreen: true;
    black: true;
    buttonGrey: true;
    darkGrey: true;
    lightGrey: true;
    white: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides {
    darkGrey: true;
  }
}

declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h4Small: true;
    body1Medium: true;
    body1Bold: true;
    body1ExtraBold: true;
    body2Medium: true;
    body2Bold: true;
    body2ExtraBold: true;
    body3: true;
    body3Bold: true;
    body3ExtraBold: true;
    body4: true;
  }
}

const { palette } = createTheme();
const createColor = (color: string) =>
  palette.augmentColor({ color: { main: color } });

const customPalette = {
  primary: createColor("#ff5815"),
  error: createColor("#EF5350"),
  lightGreen: createColor("#536260"),
  darkGreen: createColor("#3d4544"),
  brightGreen: createColor("#4CAF50"),
  buttonGrey: createColor("#f2f2f2"),
  darkGrey: createColor("#979797"),
  lightGrey: createColor("#d9d9d9"),
  filterGrey: createColor("#BDBDBD"),
  white: createColor("#ffffff"),
  black: createColor("#141515"),
  googleBlue: createColor("#1A73E8"),
};

const OlivTheme = createTheme({
  palette: customPalette,
  components: {
    MuiLink: {
      defaultProps: {
        underline: "none",
        color: "inherit",
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: "20px",
        },
        sizeLarge: {
          height: "48px",
        },
        sizeMedium: {
          height: "42px",
          padding: "0 24px",
        },
        sizeSmall: {
          height: "32px",
          padding: "20px 12px",
        },
        text: {
          borderRadius: "0px",

          "&.MuiButton-sizeMedium": {
            padding: "10px",
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          maxWidth: "650px",
          margin: "0 auto",
        },
      },
      defaultProps: {
        PaperProps: {
          sx: { boxShadow: "none" },
        },
        BackdropProps: {
          invisible: true,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          borderRadius: "0px",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "12px",
          borderRadius: "50%",
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: "12px",
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          maxWidth: "650px",
          margin: "0 auto",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            "& .MuiInputAdornment-root": {
              color: customPalette.darkGrey.main,
            },
          },

          "& .MuiOutlinedInput-root": {
            fieldset: {
              border: "1px solid " + customPalette.darkGrey.main,
            },
            "&.Mui-focused fieldset": {
              border: "1px solid " + customPalette.darkGrey.main,
            },
          },

          "& .MuiInput-underline::after": {
            borderBottom: "1px solid " + customPalette.darkGrey.main,

            "&.Mui-focused": {
              borderBottom: "1px solid " + customPalette.darkGrey.main,
            },
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          maxWidth: "650px",
          minHeight: "0px !important",
          height: "56px",
        },
      },
    },
  },
  typography: {
    fontFamily: "Karla",
    allVariants: {
      color: customPalette.black.main,
    },
    h1: {
      fontSize: "40px",
      lineHeight: "48px",
      fontWeight: "800",
    },
    h2: {
      fontSize: "32px",
      lineHeight: "38px",
      fontWeight: "800",
    },
    h3: {
      fontSize: "28px",
      lineHeight: "32px",
      fontWeight: "800",
    },
    h4: {
      fontSize: "24px",
      lineHeight: "28px",
      fontWeight: "800",
    },
    h4Small: {
      fontSize: "22px",
      lineHeight: "28px",
      fontWeight: "800",
    },
    h5: {
      fontSize: "20px",
      lineHeight: "28px",
      fontWeight: "800",
    },
    h6: {
      fontSize: "18px",
      lineHeight: "22px",
      fontWeight: "800",
    },
    body1: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "400",
    },
    body1Medium: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "500",
    },
    body1Bold: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "700",
    },
    body1ExtraBold: {
      fontSize: "16px",
      lineHeight: "24px",
      fontWeight: "800",
    },
    body2: {
      fontSize: "15px",
      lineHeight: "22px",
      fontWeight: "400",
    },
    body2Medium: {
      fontSize: "15px",
      lineHeight: "22px",
      fontWeight: "500",
    },
    body2Bold: {
      fontSize: "15px",
      lineHeight: "18px",
      fontWeight: "700",
    },
    body2ExtraBold: {
      fontSize: "15px",
      lineHeight: "18px",
      fontWeight: "800",
    },
    body3: {
      fontSize: "13px",
      lineHeight: "16px",
      fontWeight: "400",
    },
    body3Bold: {
      fontSize: "13px",
      lineHeight: "16px",
      fontWeight: "700",
    },
    body3ExtraBold: {
      fontSize: "13px",
      lineHeight: "16px",
      fontWeight: "800",
    },
    body4: {
      fontSize: "12px",
      lineHeight: "14px",
      fontWeight: "400",
    },
    button: {
      textTransform: "none",
    },
  },
  transitions: {
    duration: {
      enteringScreen: 300,
      leavingScreen: 200,
    },
  },
});

export default function MuiProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <ThemeProvider theme={OlivTheme}>
      <StyledEngineProvider injectFirst>
        <CssBaseline />
        {children}
      </StyledEngineProvider>
    </ThemeProvider>
  );
}
