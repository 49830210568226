import * as BusinessesApi from "data/apis/businesses";
import { useQuery } from "@tanstack/react-query";
import { Community } from "data/models/community";

export const useBusinesses = (community: Community) => {
  const { data: businesses } = useQuery({
    queryKey: ["businesses", community.id],
    queryFn: async () => await BusinessesApi.fetchList(community),
  });

  return businesses || [];
};
