import { useNavigate } from "react-router-dom";

import { Stack, Typography } from "@mui/material";
import {
  ArrowForwardIosRounded,
  BookmarkBorderOutlined,
  ChatBubbleOutlineOutlined,
  ThumbUpAltOutlined,
} from "@mui/icons-material";

import { getCommunityUrl } from "configs/Paths";
import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";

const YourActivities = ({ communityId }: { communityId: string }) => {
  const navigate = useNavigate();
  const { setMode, setFilters } = useCommunityState();

  const options = [
    {
      label: "Your Recommendations",
      icon: <ThumbUpAltOutlined />,
      eventName: "profile_button_rec",
    },
    {
      label: "Your Reviews",
      icon: <ChatBubbleOutlineOutlined />,
      eventName: "profile_button_reviews",
    },
    {
      label: "Your Saved Places",
      icon: <BookmarkBorderOutlined />,
      eventName: "profile_button_saves",
    },
  ];

  const handleClick = (label: string, eventName: string) => {
    customEvent(eventName, { communityId });
    setFilters({ toggle: {}, multi: {}, single: { activities: label } });
    setMode("List");
    window.scrollTo(0, 0);
    navigate(getCommunityUrl(communityId), {
      state: { scrollTo: "Your activities" },
    });
  };

  return (
    <div className="px-4">
      <Typography variant="h5" className="mb-6">
        Your activities
      </Typography>
      <Stack spacing={4.5}>
        {options.map((option) => (
          <div
            key={option.label}
            className="flex justify-between"
            onClick={() => handleClick(option.label, option.eventName)}
          >
            <div className="flex items-center">
              {option.icon}
              <Typography variant="body1" className="ml-4">
                {option.label}
              </Typography>
            </div>
            <ArrowForwardIosRounded />
          </div>
        ))}
      </Stack>
    </div>
  );
};

export default YourActivities;
