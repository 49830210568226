import React from "react";
import clsx from "clsx";

import {
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import {
  ListOutlined,
  MapOutlined,
  TimelineOutlined,
} from "@mui/icons-material";

import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const CommunityModeSelector = ({
  community,
  collapsed,
}: {
  community: Community;
  collapsed: boolean;
}) => {
  const { mode, setMode } = useCommunityState();

  const options: { label: CommunityMode; icon: React.ReactNode }[] = [];

  const communityId = community.urlName;

  if (!community.joined || community.starName!) {
    options.push({ label: "List", icon: <ListOutlined /> });
  }
  options.push({
    label: "Feed",
    icon: <TimelineOutlined />,
  });
  if (community.joined && !community.starName) {
    options.push({ label: "List", icon: <ListOutlined /> });
  }
  options.push({ label: "Map", icon: <MapOutlined /> });

  const handleClick = (communityMode: CommunityMode, isGrouped: boolean) => {
    setMode(communityMode);
    const newMode = communityMode.toLowerCase();
    if (isGrouped) {
      customEvent("com_dp_sort_button_group_" + newMode, { communityId, mode });
    } else {
      customEvent("com_dp_sort_tile_" + newMode, { communityId, mode });
    }
  };

  return (
    <ToggleButtonGroup
      exclusive
      value={mode}
      color="primary"
      className={clsx(
        "rounded-xl text-light-green",
        { "h-10": collapsed },
        { "px-4": !collapsed }
      )}
    >
      {options.map((option) => (
        <ToggleButton
          color="primary"
          key={option.label}
          value={option.label}
          selected={mode === option.label}
          onClick={() => handleClick(option.label, collapsed)}
          className={clsx(
            "border border-solid border-dark-grey rounded-xl text-inherit [&.Mui-selected]:!border-primary",
            { "py-0": collapsed },
            { "w-full": !collapsed }
          )}
        >
          <Stack
            spacing={0.5}
            className={clsx("items-center", { "p-1": !collapsed })}
          >
            {option.icon}
            {!collapsed && (
              <Typography
                variant="body3"
                className={clsx({ "font-bold": mode === option.label })}
              >
                {option.label}
              </Typography>
            )}
          </Stack>
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

export default CommunityModeSelector;
