import React from "react";
import moment from "moment";

import {
  Badge,
  CircularProgress,
  IconButton,
  InputBase,
  Stack,
  Typography,
} from "@mui/material";
import {
  CancelOutlined,
  CheckCircleOutlineOutlined,
  EditOutlined,
  PhotoCameraRounded,
} from "@mui/icons-material";
import ProfileAvatar from "components/ProfileAvatar";
import { customEvent } from "utils/AnalyticsEvent";
import { useUpdateMember } from "data/hooks/members";
import { useCommunity } from "data/hooks/communities";
import { Community } from "data/models/community";

const ProfileImageSelector = ({ community }: { community: Community }) => {
  const updateProfile = useUpdateMember(community);

  const imageUploaderRef = React.useRef<HTMLInputElement>(null);

  const communityId = community.urlName;

  const handleClick = () => {
    customEvent("profile_button_profile", { communityId });
    imageUploaderRef.current?.click();
  };

  const handleImageUpload = async (files: FileList | null) => {
    if (!files) return;

    updateProfile.mutate({
      profileName: community.profileName!,
      profileImage: files[0],
    });
  };

  return (
    <>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        badgeContent={
          <div className="rounded-full bg-white p-1">
            <IconButton disableRipple className="bg-light-grey">
              <PhotoCameraRounded />
            </IconButton>
          </div>
        }
        onClick={handleClick}
      >
        <ProfileAvatar profile={community.member!} size={120} />
      </Badge>
      <input
        hidden
        ref={imageUploaderRef}
        type="file"
        accept="image/*"
        id="images"
        onChange={(e) => handleImageUpload(e.target.files)}
      />
    </>
  );
};

const ProfileNameSelector = ({ community }: { community: Community }) => {
  const [status, setStatus] = React.useState("ready");
  const [profileName, setProfileName] = React.useState("");

  const updateProfile = useUpdateMember(community);

  const communityId = community.urlName;

  const handleEdit = () => {
    customEvent("profile_button_username_edit", { communityId });
    setStatus("editing");
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setProfileName(event.target.value);
    setStatus("editing");
  };

  const handleConfirm = async () => {
    if (status !== "editing") return;
    customEvent("profile_button_username_confirm", { communityId });
    const newProfileName = profileName.substring(0, 20);

    const result = await updateProfile.mutateAsync({
      profileName: newProfileName,
    });

    if (result === 409) {
      setStatus("errorExistingName");
    } else if (result === 400) {
      setStatus("errorBlacklistedChars");
    } else {
      setStatus("ready");
    }
  };

  const handleCancel = () => {
    setProfileName("");
    setStatus("ready");
  };

  return status === "ready" ? (
    <div>
      <div className="flex items-center">
        <Typography variant="h3" className="break-all">
          {community.profileName}
          <Typography
            component="span"
            color="darkGrey.main"
            onClick={handleEdit}
          >
            <EditOutlined className="ml-4" />
          </Typography>
        </Typography>
      </div>
      <Typography variant="body3Bold" color="darkGrey.main">
        Joined {moment(community.joinedAt).fromNow()}
      </Typography>
    </div>
  ) : (
    <Stack className="flex-1 h-[56px]">
      <div className="w-full flex items-center">
        <InputBase
          autoFocus
          onChange={handleChange}
          placeholder={community.profileName}
          value={profileName.substring(0, 20)}
          inputProps={{
            autoCapitalize: "none",
            className: "text-center",
          }}
          className="font-extrabold text-[24px] leading-[28px] border-0 border-b-2 border-solid border-light-grey"
        />
        <IconButton
          disabled={profileName.length < 3}
          onClick={handleConfirm}
          className="p-1"
        >
          {status === "checking" ? (
            <CircularProgress color="lightGrey" size="24px" />
          ) : (
            <CheckCircleOutlineOutlined className="text-4xl" />
          )}
        </IconButton>
        <IconButton onClick={handleCancel} className="p-1">
          <CancelOutlined className="text-4xl" />
        </IconButton>
      </div>
      <div>
        {status === "errorExistingName" && (
          <Typography variant="body2" color="error" className="py-2 px-1">
            Sorry. That unique name is already taken.
          </Typography>
        )}
        {status === "errorBlacklistedChars" && (
          <Typography variant="body2" color="error" className="py-2 px-1">
            Brackets are not allowed in a username
          </Typography>
        )}
      </div>
    </Stack>
  );
};

const ProfileEditor = ({ communityId }: { communityId: string }) => {
  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");
  if (!community.member) throw new Error("Member not found");

  return (
    <Stack
      spacing={2}
      direction="row"
      className="items-center px-4 pt-10 pb-16"
    >
      <ProfileImageSelector community={community} />
      <ProfileNameSelector community={community} />
    </Stack>
  );
};

export default ProfileEditor;
