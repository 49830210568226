import { plainToInstance } from "class-transformer";
import { supabase } from "configs/Supabase";
import { Business } from "data/models/business";
import { Community } from "data/models/community";
import { Recommendation } from "data/models/recommendation";

export const fetch = async (
  community: Community,
  business: Business
): Promise<Recommendation[]> => {
  try {
    const params = { community_id: community.id, business_id: business.id };
    const { data, error } = await supabase.rpc("list_recommendations", params);

    if (error) {
      throw error;
    }

    return plainToInstance(Recommendation, data as any[]);
  } catch (error) {
    return [];
  }
};
