import React from "react";
import dayjs from "dayjs";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { Backdrop, Button, Stack, Typography } from "@mui/material";

import { useCommunity } from "data/hooks/communities";

const CommunityPromotion = ({ communityId }: { communityId: string }) => {
  return <></>;
  /*
  const community = useGetCommunity(communityId);
  const membership = useGetMembership(communityId);

  const [open, setOpen] = React.useState(membership === undefined);
  const [imageUrl, setImageUrl] = React.useState("");

  React.useEffect(() => {
    const getImageUrl = async () => {
      if (promotion && promotion.image) {
        const signedURL = await S3StorageService.get(promotion.image);
        setImageUrl(signedURL);
      } else {
        setImageUrl("na");
      }
    };

    getImageUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!community) throw new Error("Community not found");

  const promotion = community.promotion;
  const isPromotionActive =
    promotion && dayjs().isAfter(dayjs(promotion.expiresAt));

  if (!isPromotionActive || membership) return null;

  return (
    <Backdrop open={open} onClick={() => setOpen(false)} className="z-[2000]">
      <Stack
        spacing={4}
        className="p-6 bg-white rounded-md w-11/12 max-w-[600px]"
      >
        <div className="w-full aspect-16/9">
          <img src={imageUrl} alt="promotion" className="w-full" />
        </div>
        <Typography component="div" variant="body1">
          <ReactMarkdown>{promotion.text}</ReactMarkdown>
        </Typography>
        <Button variant="contained" color="lightGrey">
          <Typography variant="body1ExtraBold">Got it</Typography>
        </Button>
      </Stack>
    </Backdrop>
  );
  */
};

export default CommunityPromotion;
