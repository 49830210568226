import React from "react";

import { Stack, Typography } from "@mui/material";

import BusinessHeaderActions from "./BusinessHeaderActions";
import BusinessHeaderClosed from "./BusinessHeaderClosed";
import BusinessHeaderFilters from "./BusinessHeaderFilters";
import BusinessHeaderImages from "./BusinessHeaderImages";
import BusinessHeaderLikes from "./BusinessHeaderLikes";
import BusinessHeaderStarCards from "./BusinessHeaderStarCards";
import BusinessHeaderVerified from "./BusinessHeaderVerified";
import { HeaderLikesLoading } from "./loading";
import { useCommunity } from "data/hooks/communities";
import { useBusiness } from "data/hooks/business";

const BusinessHeader = ({
  communityId,
  businessId,
  scrollToInfoRef,
}: {
  communityId: string;
  businessId: string;
  scrollToInfoRef: () => void;
}) => {
  const community = useCommunity(communityId);
  if (!community) throw new Error("Community not found");

  const business = useBusiness(community, businessId);
  if (!business) throw new Error("Business not found");

  return (
    <Stack spacing={1}>
      <BusinessHeaderImages community={community} business={business} />
      <Stack spacing={1} className="pt-1 px-4">
        <Typography variant="h4">{business.name}</Typography>
        <Typography variant="body2" color="lightGreen.main">
          {business.city}
          {business.cuisine && (
            <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;{business.cuisine}</>
          )}
          {business.price && (
            <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;{business.price}</>
          )}
        </Typography>
      </Stack>
      <BusinessHeaderClosed business={business} />
      <BusinessHeaderFilters community={community} business={business} />
      <BusinessHeaderVerified business={business} />
      <div className="px-4 py-4">
        <BusinessHeaderActions
          community={community}
          business={business}
          scrollToInfoRef={scrollToInfoRef}
        />
      </div>
      {community.joined && (
        <BusinessHeaderStarCards community={community} business={business} />
      )}
      <div className="px-4 pb-4">
        {community.joined && (
          <React.Suspense fallback={<HeaderLikesLoading />}>
            <BusinessHeaderLikes community={community} business={business} />
          </React.Suspense>
        )}
      </div>
    </Stack>
  );
};

export default BusinessHeader;
