import React from "react";
import { useLocation } from "react-router-dom";

import { IconButton, Snackbar } from "@mui/material";
import { ShareOutlined } from "@mui/icons-material";
import { customEvent } from "utils/AnalyticsEvent";
import { Community } from "data/models/community";

const ShareButton = ({ community }: { community: Community }) => {
  const location = useLocation();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);

  const handleClick = () => {
    customEvent("com_dp_icon_share", {
      communityId: community.urlName,
      isMember: community.joined,
    });
    if (!navigator.share) {
      navigator.clipboard.writeText(window.location.href);
      setSnackbarOpen(true);
    } else {
      navigator.share({ url: location.pathname });
    }
  };

  return (
    <>
      <IconButton onClick={handleClick}>
        <ShareOutlined />
      </IconButton>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message="URL copied to clipboard"
      />
    </>
  );
};

export default ShareButton;
