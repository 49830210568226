import { plainToInstance } from "class-transformer";
import { supabase } from "configs/Supabase";
import { Community } from "data/models/community";
import { Review } from "data/models/review";
import { ReviewReaction } from "data/models/review_reaction";

export const fetch = async (review: Review): Promise<ReviewReaction[]> => {
  try {
    const params = { review_id: review.id };
    const { data, error } = await supabase.rpc("list_review_reaction", params);

    if (error) {
      throw error;
    }

    return plainToInstance(ReviewReaction, data as any[]);
  } catch (error) {
    return [];
  }
};

export const addHelpful = async (community: Community, review: Review) => {
  try {
    if (!community.memberId) return;
    const input = {
      community_id: community.id,
      review_id: review.id,
      member_id: community.memberId,
    };
    const { error } = await supabase.from("review_reaction").insert(input);

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};

export const removeHelpful = async (community: Community, review: Review) => {
  try {
    if (!community.memberId) return;
    const { error } = await supabase
      .from("review_reaction")
      .delete()
      .match({
        community_id: community.id,
        review_id: review.id,
        member_id: community.memberId,
      });

    if (error) {
      throw error;
    }
  } catch (error) {
    throw error;
  }
};
