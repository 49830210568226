import React from "react";

import { IconButton } from "@mui/material";
import { DeleteOutlineOutlined } from "@mui/icons-material";

import ModalBox from "components/ModalBox";
import { customEvent } from "utils/AnalyticsEvent";
import { useRemoveReview } from "data/hooks/businessReviews";
import { Review } from "data/models/review";
import { Community } from "data/models/community";
import { Business } from "data/models/business";

const CommentCardDeleteAction = ({
  community,
  business,
  review,
}: {
  community: Community;
  business: Business;
  review: Review;
}) => {
  const removeComment = useRemoveReview(community, business, review);

  const [modalOpen, setModalOpen] = React.useState(false);

  const communityId = community.urlName;
  const businessId = business.id;

  const handleDeleteClick = () => {
    customEvent("bus_dp_icon_delete_review", { communityId, businessId });
    setModalOpen(true);
  };

  const handleCancelDelete = () => {
    customEvent("bus_dp_delete_m_button_close", { communityId, businessId });
    setModalOpen(false);
  };

  const handleConfirmDelete = () => {
    customEvent("bus_dp_delete_m_button_confirm", { communityId, businessId });
    removeComment.mutate();
    setModalOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleDeleteClick} sx={{ mr: "-12px" }}>
        <DeleteOutlineOutlined />
      </IconButton>
      <ModalBox
        variant="confirmation"
        open={modalOpen}
        label="Are you sure you want to delete your review?"
        handleCancel={handleCancelDelete}
        handleConfirm={handleConfirmDelete}
      />
    </>
  );
};

export default CommentCardDeleteAction;
