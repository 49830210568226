import { Button } from "@mui/material";
import { Community } from "data/models/community";

import { useCommunityState } from "providers/CommunityStateProvider";
import { customEvent } from "utils/AnalyticsEvent";

const CommunityFiltersToggle = ({
  community,
  filter,
  label,
  eventName,
  eventParams,
}: {
  community: Community;
  filter: string;
  label: string;
  eventName: string;
  eventParams?: { [key: string]: string };
}) => {
  const { mode, filters, setFilters } = useCommunityState();

  const selected = filters.toggle[filter] === true;
  const communityId = community.urlName;

  const handleClick = () => {
    customEvent(eventName, { communityId, mode, ...eventParams });
    const toggleFilters = { ...filters.toggle, [filter]: !selected };
    setFilters({ ...filters, toggle: toggleFilters });
  };

  return (
    <Button
      variant="outlined"
      color={selected ? "primary" : "lightGreen"}
      className="rounded-xl h-10 px-2 py-1.5 btn-selected"
      onClick={handleClick}
    >
      {label}
    </Button>
  );
};

export default CommunityFiltersToggle;
