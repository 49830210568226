import { Expose, Type } from "class-transformer";
import { supabase } from "configs/Supabase";
import { Member } from "./member";

export class Community {
  id!: number;
  @Expose({ name: "url_name" }) urlName!: string;
  name!: string;
  description!: string;
  @Expose({ name: "cover_img_key" }) coverImgKey!: string;
  @Expose({ name: "recommendations_count" }) recommendationsCount!: number;
  @Expose({ name: "businesses_count" }) businessesCount!: number;
  @Expose({ name: "members_count" }) membersCount!: number;
  @Expose({ name: "is_private" }) isPrivate!: boolean;
  @Expose({ name: "created_at" }) @Type(() => Date) createdAt!: Date;
  tags!: string[];
  @Expose({ name: "bg_img_key" }) bgImgKey?: string;
  @Expose({ name: "star_name" }) starName?: string;
  challenge?: string;
  @Expose({ name: "member_id" }) memberId?: number;
  @Expose({ name: "profile_name" }) profileName?: string;
  @Expose({ name: "profile_img_key" }) profileImgKey?: string;
  @Expose({ name: "profile_color" }) profileColor?: string;
  @Expose({ name: "is_moderator" }) isModerator: boolean = false;
  badges: string[] = [];
  @Expose({ name: "joined_at" }) @Type(() => Date) joinedAt?: Date;

  get joined() {
    return this.memberId != null;
  }

  get member(): Member | undefined {
    return this.joined
      ? new Member(
          this.profileName!,
          this.profileColor!,
          false,
          [],
          this.profileImgKey
        )
      : undefined;
  }

  get coverImgUrl() {
    const file = supabase.storage
      .from("communities")
      .getPublicUrl(this.coverImgKey);
    return file.data.publicUrl;
  }

  get bgImgUrl() {
    if (this.bgImgKey == null) return this.coverImgUrl;

    const file = supabase.storage
      .from("communities")
      .getPublicUrl(this.bgImgKey);
    return file.data.publicUrl;
  }
}
