import { Expose, Transform } from "class-transformer";
import { supabase } from "configs/Supabase";

export enum ImageType {
  member,
  instagram,
  food,
  vibe,
  featured,
  inside,
  fallback,
}

export class OlivImage {
  @Transform(({ value }) => ImageType[value]) type!: ImageType;
  url?: string;
  @Expose({ name: "image_key" }) imageKey?: string;

  constructor(
    type: ImageType,
    url: string | undefined,
    imageKey: string | undefined
  ) {
    this.url = url;
    this.imageKey = imageKey;
    this.type = type;
  }

  get imageUrl() {
    if (this.url) return this.url;
    return supabase.storage.from("reviews").getPublicUrl(this.imageKey!).data
      .publicUrl;
  }
}
