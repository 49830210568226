import { Skeleton, Stack } from "@mui/material";

export const ProfileEditorLoading = () => {
  return (
    <Stack
      spacing={2}
      direction="row"
      className="items-center px-4 pt-10 pb-16"
    >
      <Skeleton variant="circular" className="w-36 h-36" />
      <div className="flex flex-1 flex-col justify-between h-14">
        <Skeleton variant="rectangular" width="100%" />
        <Skeleton variant="rectangular" width="100%" />
      </div>
    </Stack>
  );
};
