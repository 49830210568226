import React from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import { IconButton, Typography } from "@mui/material";
import { MoreVert } from "@mui/icons-material";

import ClampedTypography from "components/ClampedTypography";
import CommunityHeaderOverflow from "./CommunityHeaderOverflow";
import ShareButton from "pages/CommunityDetail/CommunityHeaderShareButton";
import { useCommunityState } from "providers/CommunityStateProvider";
import { useCommunity } from "data/hooks/communities";
import { customEvent } from "utils/AnalyticsEvent";

const CommunityMetadata = ({
  label,
  value,
  isLast,
}: {
  label: string;
  value: number;
  isLast?: boolean;
}) => (
  <Typography variant="body2" className="float-left text-light-green">
    <b>{value}</b>&nbsp;
    {label}
    {!isLast && <>&nbsp;&nbsp;&bull;&nbsp;&nbsp;</>}
  </Typography>
);

const CommunityHeader = ({ communityId }: { communityId: string }) => {
  const community = useCommunity(communityId);
  const { mode } = useCommunityState();

  const [overflowStep, setOverflowStep] = React.useState<DetailOverflowMenu>();

  if (!community) return <></>;
  if (mode === "Map") return <></>;

  const handleOverflowClick = () => {
    customEvent("com_dp_icon_overflow", { communityId });
    setOverflowStep("main");
  };

  const handleMemberClick = () => {
    if (community.joined) {
      customEvent("com_dp_com_number", { communityId });
      setOverflowStep("members");
    }
  };

  return (
    <>
      <img
        src={community.bgImgUrl}
        alt={community.name}
        className="aspect-4 object-cover opacity-80 -z-10"
      />
      <div className="px-4">
        <img
          src={community.coverImgUrl}
          alt={community.name}
          className="float-left mt-[-55px] w-[105px] aspect-square rounded-full object-cover shadow-[0_4px_4px_0px_rgba(0,0,0,0.25)]"
        />
        <div className="float-right -mx-3">
          <ShareButton community={community} />
          {community.joined && (
            <IconButton onClick={handleOverflowClick}>
              <MoreVert sx={{ height: "24px", width: "24px" }} />
            </IconButton>
          )}
        </div>
        <div className="clear-both" />
        <Typography variant="h4Small" className="block mt-4">
          {community.name}
        </Typography>
        <div className="pt-1" onClick={handleMemberClick}>
          <CommunityMetadata value={community.membersCount} label="members" />
          <CommunityMetadata value={community.businessesCount} label="places" />
          <CommunityMetadata
            value={community.recommendationsCount}
            label="recommendations"
            isLast
          />
        </div>
        <div className="clear-both" />
        {!community.joined && (
          <ClampedTypography
            component="div"
            variant="body2"
            className="text-dark-green pt-5"
            communityId={communityId}
            eventName="com_dp_link"
          >
            <ReactMarkdown>{community.description}</ReactMarkdown>
          </ClampedTypography>
        )}
      </div>
      <CommunityHeaderOverflow
        community={community}
        step={overflowStep}
        setStep={setOverflowStep}
      />
    </>
  );
};

export default CommunityHeader;
